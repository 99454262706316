// Function to calculate dynamic scores
export const calculateDynamicScores = (storesData, kpiRankings, totalStores, areaAveragesState) => {
  console.log(`Cutoffs - Top 10%: ${Math.ceil(0.1 * totalStores)}, Next 20%: ${Math.ceil(0.3 * totalStores)}, Next 40%: ${Math.ceil(0.7 * totalStores)}, Bottom 50%: ${totalStores}`);
  console.log("storesData:", storesData);
  console.log("AAVERAGES:", areaAveragesState);
  
  storesData.forEach(store => {
    let dynamicScore = 0;

    // Replace 'N/A' SalesChange with area average SalesChange
    if (store.SalesChange === 'N/A') {
      const areaAverage = areaAveragesState.find(area => area.area === store.storeArea);
      if (areaAverage) {
        store.SalesChange = areaAverage.averageSalesChange;
      }
    }

    console.log(`Calculating scores for Store ID: ${store.storeId}`);

    Object.entries(kpiRankings).forEach(([kpiKey, rankings]) => {
      const rank = rankings[store.storeId];
      let score = 0;

      // Calculate base score
      if (rank <= Math.ceil(0.1 * totalStores)) {
        score = 5 - ((rank - 1) / Math.max(Math.ceil(0.1 * totalStores) - 1, 1)) * 1;
      } else if (rank <= Math.ceil(0.3 * totalStores)) {
        score = 4 - ((rank - Math.ceil(0.1 * totalStores)) / Math.max(Math.ceil(0.3 * totalStores) - Math.ceil(0.1 * totalStores), 1)) * 1;
      } else if (rank <= Math.ceil(0.7 * totalStores)) {
        score = 3 - ((rank - Math.ceil(0.3 * totalStores)) / Math.max(Math.ceil(0.7 * totalStores) - Math.ceil(0.3 * totalStores), 1)) * 1;
      } else {
        score = 2 - ((rank - Math.ceil(0.7 * totalStores)) / Math.max(totalStores - Math.ceil(0.7 * totalStores), 1)) * 1;
      }

      // Apply bonus or penalty based on the total number of stores and rank
      if (totalStores > 30) {
        if (rank === 1) score += 3;
        else if (rank === 2) score += 2;
        else if (rank === 3) score += 1;
        else if (rank === totalStores) score -= 3;
        else if (rank === totalStores - 1) score -= 2;
        else if (rank === totalStores - 2) score -= 1;
      } else if (totalStores >= 15 && totalStores <= 29) {
        if (rank === 1) score += 2;
        else if (rank === 2) score += 1;
        else if (rank === totalStores) score -= 2;
        else if (rank === totalStores - 1) score -= 1;
      } else if (totalStores < 15) {
        if (rank === 1) score += 2;
        else if (rank === totalStores) score -= 2;
      }

      dynamicScore += score;
      console.log(`KPI: ${kpiKey}, Rank: ${rank}, Score: ${score.toFixed(2)}`);
    });

    store.dynamicScore = dynamicScore.toFixed(2);
    console.log(`Total Dynamic Score for Store ID: ${store.storeId} is ${dynamicScore.toFixed(2)}`);
  });

  return storesData;
};