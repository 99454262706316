import React, { useState, useEffect } from 'react';
import './BackUpCashCount.css'; // Make sure to create and link this CSS file for styling
import { calculateTotal } from './calculateTotal';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Snackbar } from '@mui/material';
import axios from 'axios';

const BackUpCashCount = ({ storeID }) => {
  const [visibleSection, setVisibleSection] = useState('bills');
  const [carhopAndChangers, setCarhopAndChangers] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalInputs, setModalInputs] = useState({
    changersInUse: '',
    changersOutOfService: '',
    correct: '',
    short: '',
  });

  const [bills, setBills] = useState({
    hundreds: '', fifties: '', twenties: '', tens: '', tensBundles: '',
    fives: '', fivesBundles: '', ones: '', onesBundles: '',
  });

  const [coins, setCoins] = useState({
    dollarCoins: '', quarters: '', quartersRolls: '', dimes: '', dimesRolls: '',
    nickles: '', nicklesRolls: '', pennies: '', penniesRolls: '',
  });

  const [additionalCash, setAdditionalCash] = useState(0);
  const [showCodeVerificationModal, setShowCodeVerificationModal] = useState(false);
  const [codeDigits, setCodeDigits] = useState(['', '', '', '']);
  const [showShortageConfirmationModal, setShowShortageConfirmationModal] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState({ success: null, message: '' });
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    if (showCodeVerificationModal) {
      setTimeout(() => {
        setCodeDigits(['', '', '', '']);
        const firstInput = document.getElementById(`codeDigit-0`);
        if (firstInput) {
          firstInput.focus();
        }
      }, 100);
    }
  }, [showCodeVerificationModal]);

  const getBaseUrl = () => {
    return process.env.NODE_ENV === 'development' ? 'http://72.167.34.236:5000' : 'https://prolifi.app';
  };

  const fullChangerValue = 42.23; // The value of a full changer
  const expectedChangers = 6; // The expected number of changers
  const expectedTotal = 710.00; // The expected total amount

  const numericBills = Object.fromEntries(Object.entries(bills).map(([key, value]) => [key, parseFloat(value) || 0]));
  const numericCoins = Object.fromEntries(Object.entries(coins).map(([key, value]) => [key, parseFloat(value) || 0]));

  const total = calculateTotal(numericBills, numericCoins, carhopAndChangers === 'yes', fullChangerValue, expectedChangers, expectedTotal, additionalCash);

  const handleInputChange = (event, category) => {
    const { name, value } = event.target;
    if (category === 'bills') {
      setBills(prev => ({ ...prev, [name]: value }));
    } else if (category === 'coins') {
      setCoins(prev => ({ ...prev, [name]: value }));
    }
  };

  const renderGroupedInput = (id, label, category) => {
    let secondaryId = null;
    let placeholderPrimary = "Singles";
    let placeholderSecondary = category === bills ? "Bundles" : "Rolls";

    if (category === bills && (id === 'tens' || id === 'fives' || id === 'ones')) {
      secondaryId = id + 'Bundles';
    } else if (category === coins) {
      secondaryId = id + 'Rolls';
    }

    return (
      <div className="grouped-input-container" key={id}>
        <div className="denomination-label">{label}</div>
        <div className="input-group">
          <div className="primary-input">
            <input
              type="number"
              id={id}
              name={id}
              value={category[id]}
              placeholder={placeholderPrimary}
              onChange={(e) => handleInputChange(e, category === bills ? 'bills' : 'coins')}
            />
          </div>
          {secondaryId && category[secondaryId] !== undefined && (
            <div className="secondary-input">
              <input
                type="number"
                id={secondaryId}
                name={secondaryId}
                value={category[secondaryId]}
                placeholder={placeholderSecondary}
                onChange={(e) => handleInputChange(e, category === bills ? 'bills' : 'coins')}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const handleNoClick = () => {
    setShowModal(true);
  };

  const handleModalInputChange = (e) => {
    const { name, value } = e.target;
    setModalInputs(prev => ({ ...prev, [name]: value }));
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmitModal = () => {
    const changersInUse = parseInt(modalInputs.changersInUse) || 0;
    const changersOutOfService = parseInt(modalInputs.changersOutOfService) || 0;
    const correctChangers = parseInt(modalInputs.correct) || 0;
    const shortChangers = parseInt(modalInputs.short) || 0;

    if (changersInUse + changersOutOfService !== expectedChangers) {
      alert(`The total number of changers (In Use + Out of Service) must equal ${expectedChangers}. Please correct your input.`);
      return;
    }

    if (correctChangers + shortChangers !== changersOutOfService) {
      alert(`The sum of Correct and Short changers must equal the number of changers Out of Service. Please correct your input.`);
      return;
    }

    const totalStartingCash = (correctChangers + changersInUse) * fullChangerValue;
    setAdditionalCash(totalStartingCash);
    console.log(`Total starting cash: ${totalStartingCash.toFixed(2)}`);
    setShowModal(false);
  };

const handleSubmit = async (bypassShortfallCheck = false, digits) => {
  console.log('codeDigits:', digits);

  // Replace any empty string in codeDigits with "0"
  const sanitizedCodeDigits = digits.map(digit => digit === "" ? "0" : digit);
  const userCode = sanitizedCodeDigits.join('');

  const shortfall = expectedTotal - total;

  if (total < expectedTotal && !bypassShortfallCheck) {
    setShowShortageConfirmationModal(true);
    return;
  }

  const data = {
    storeID,
    code: userCode,
    dateTime: new Date().toISOString(),
    totalCount: total,
  };

  try {
    const baseUrl = getBaseUrl();
    const response = await axios.post(`${baseUrl}/api/submit-cash-count`, data, { withCredentials: true });
    console.log('Submission success:', response.data);
    setSubmissionStatus({ success: true, message: 'Cash count submitted successfully!' });
    setShowSnackbar(true);
    setShowShortageConfirmationModal(false);
  } catch (error) {
    console.error('Submission error:', error.response ? error.response.data : error.message);
    setSubmissionStatus({ success: false, message: 'Failed to submit cash count. Please try again.' });
    setShowSnackbar(true);
  }
};

const handleCodeChange = (digit, index) => {
  const newCodeDigits = [...codeDigits];
  newCodeDigits[index] = digit.slice(0, 1) || '0'; // Ensure '0' is retained

  setCodeDigits(newCodeDigits);

  if (digit && index < 3) {
    const nextInput = document.getElementById(`codeDigit-${index + 1}`);
    if (nextInput) {
      nextInput.focus();
    }
  }
  
  // Logging the new code digits to debug
  console.log('New Code Digits:', newCodeDigits);

  // Trigger verification only after setting the code digit
  if (index === 3) {
    setTimeout(() => {
      handleCodeVerification(newCodeDigits);
    }, 100); // Slight delay to ensure state update
  }
};

const handleCodeVerification = async (digits) => {
  const baseUrl = getBaseUrl();
  try {
    const response = await axios.post(`${baseUrl}/api/verify-code`, { code: digits.join('') }, { withCredentials: true });
    console.log('Verification success:', response.data);
    setShowCodeVerificationModal(false);
    handleSubmit(false, digits);
  } catch (error) {
    console.error('Verification error:', error.response ? error.response.data : error.message);
  }
};


  return (
    <div className="cash-count-container">
      <h1 style={{ textAlign: 'center' }}>Back-Up Cash Count - Store {storeID}</h1>

      <div className="toggle-container" onClick={() => setVisibleSection(visibleSection === 'bills' ? 'coins' : 'bills')}>
        <div className={`toggle-btn ${visibleSection === 'bills' ? '' : 'toggle-right'}`}>
          <div className="toggle-btn-circle"></div>
          <span className="toggle-label">{visibleSection === 'bills' ? 'Bills' : 'Coins'}</span>
        </div>
      </div>

      <form>
        {visibleSection === 'bills' && (
          <div className="section">
            <h3>Bills Count:</h3>
            <div className="currency-group">
              {Object.entries(bills).filter(([key]) => !key.includes('Bundles')).map(([key, value]) =>
                renderGroupedInput(key, key.toUpperCase(), bills)
              )}
            </div>
          </div>
        )}

        {visibleSection === 'coins' && (
          <div className="section">
            <h3>Coins Count:</h3>
            <div className="currency-group">
              {Object.entries(coins).filter(([key]) => !key.includes('Rolls')).map(([key, value]) =>
                renderGroupedInput(key, key.toUpperCase(), coins)
              )}
            </div>
          </div>
        )}

        <div className="section verification">
          <h3>Verification:</h3>
          <div className="verification-question">
            Carhop Cash and Changers Present:
            <div className="button-group">
              <Button variant="outlined" color={carhopAndChangers === 'yes' ? "primary" : undefined} onClick={() => setCarhopAndChangers('yes')}>Yes</Button>
              <Button variant="outlined" color={carhopAndChangers === 'no' ? "secondary" : undefined} onClick={() => {
                setCarhopAndChangers('no');
                setShowModal(true);
              }}>No</Button>
            </div>
          </div>
          <div className="total-counted">Total Counted: ${total.toFixed(2)}</div>
          <Button type="button" className="submit-btn" variant="contained" color="primary" onClick={() => setShowCodeVerificationModal(true)}>Submit</Button>
        </div>
      </form>

      <Dialog open={showModal} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Additional Information Required</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="changersInUse"
            label="Changers & Cash in Use"
            type="text"
            fullWidth
            value={modalInputs.changersInUse}
            onChange={handleModalInputChange}
            name="changersInUse"
          />
          <TextField
            margin="dense"
            id="changersOutOfService"
            label="Changers & Cash out of Service"
            type="text"
            fullWidth
            value={modalInputs.changersOutOfService}
            onChange={handleModalInputChange}
            name="changersOutOfService"
          />
          <TextField
            margin="dense"
            id="correct"
            label="Correct"
            type="text"
            fullWidth
            value={modalInputs.correct}
            onChange={handleModalInputChange}
            name="correct"
          />
          <TextField
            margin="dense"
            id="short"
            label="Short"
            type="text"
            fullWidth
            value={modalInputs.short}
            onChange={handleModalInputChange}
            name="short"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">Cancel</Button>
          <Button onClick={handleSubmitModal} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showCodeVerificationModal} onClose={() => setShowCodeVerificationModal(false)} aria-labelledby="code-verification-title">
        <DialogTitle id="code-verification-title">Enter Your Security Code</DialogTitle>
        <DialogContent>
          <div className="codeInputContainer">
            {codeDigits.map((digit, index) => (
              <input
                key={index}
                className="codeInput"
                id={`codeDigit-${index}`}
                type="password"
                value={digit}
                onChange={(e) => handleCodeChange(e.target.value, index)}
                maxLength="1"
                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '').slice(0, 1)}
                onFocus={(e) => e.target.select()}
                autoComplete="off"
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowCodeVerificationModal(false)} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showShortageConfirmationModal} onClose={() => setShowShortageConfirmationModal(false)} aria-labelledby="shortage-confirmation-title">
        <DialogTitle id="shortage-confirmation-title">Cash Count Shortage</DialogTitle>
        <DialogContent>
          <p>Your counted amount of ${total.toFixed(2)} is less than the expected amount by ${Math.abs(expectedTotal - total).toFixed(2)}. Do you want to submit the backup cash as short this amount?</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowShortageConfirmationModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => {
            setShowShortageConfirmationModal(false);
            handleSubmit(true);
          }} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={submissionStatus.message}
        action={
          <Button color="secondary" size="small" onClick={() => setShowSnackbar(false)}>
            Dismiss
          </Button>
        }
      />
    </div>
  );
};

export default BackUpCashCount;
