import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Layout, Typography, Table, Input, Button, message } from 'antd';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import './PLReview.css';

const { Header, Content } = Layout;
const { Title } = Typography;

function PLReview() {
  const [stores, setStores] = useState([]);
  const [error, setError] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [monthlyData, setMonthlyData] = useState({});
  const [formData, setFormData] = useState({});

  const getBaseUrl = () => {
    const hostname = window.location.hostname;

    if (hostname === '72.167.34.236') {
      return 'http://72.167.34.236:5000';
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return 'https://prolifi.app';
    } else {
      console.error('Unknown host');
      return ''; // Return an empty string or a default URL if preferred
    }
  };

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const baseUrl = getBaseUrl();
        const response = await axios.get(`${baseUrl}/api/supervised-stores`, { withCredentials: true });
        setStores(response.data);
      } catch (error) {
        setError('Error fetching stores');
        message.error('Error fetching stores');
        console.error('Error fetching stores', error);
      }
    };

    fetchStores();
  }, []);

  const handleMonthChange = async (date) => {
    if (date) {
      setSelectedMonth(date);
      checkFormDisable(date);
      await fetchMonthlyData(date);
    } else {
      setSelectedMonth(moment().toDate());
      setIsFormDisabled(false);
    }
  };

  const checkFormDisable = (date) => {
    const now = moment();
    const selected = moment(date);
    const firstFiveDaysNextMonth = selected.clone().add(1, 'month').startOf('month').add(5, 'days');

    if (selected.isAfter(now, 'month')) {
      setIsFormDisabled(true);
    } else if (selected.isSame(now, 'month') || now.isBefore(firstFiveDaysNextMonth)) {
      setIsFormDisabled(false);
    } else {
      setIsFormDisabled(true);
    }
  };

  const fetchMonthlyData = async (date) => {
    const baseUrl = getBaseUrl();
    const formattedDate = moment(date).format('YYYY-MM');
    try {
      const response = await axios.get(`${baseUrl}/api/data-for-month`, {
        params: { month: formattedDate },
        withCredentials: true,
      });
      const data = response.data.reduce((acc, item) => {
        acc[item.store] = item;
        return acc;
      }, {});
      setMonthlyData(data);
      setFormData(data); // Initialize form data with fetched monthly data
      console.log('Fetched monthly data:', response.data);
    } catch (error) {
      console.error('Error fetching monthly data:', error);
      message.error('Error fetching monthly data');
    }
  };

const handleInputChange = (store, field, value) => {
  setFormData(prevState => {
    const storeData = prevState[store] || {};
    const updatedStoreData = {
      ...storeData,
      [field]: value,  // Keep the value as a string initially
    };
    return {
      ...prevState,
      [store]: {
        ...updatedStoreData,
        ieVariance: calculateIEVariance(parseFloat(updatedStoreData.actualFnP), parseFloat(updatedStoreData.idealFoodAndPaper)),
        differenceFoodPaper: calculateDifferenceFoodPaper(parseFloat(updatedStoreData.actualVariance), parseFloat(updatedStoreData.ieVariance)),
        differenceCrewLabor: calculateDifferenceCrewLabor(parseFloat(updatedStoreData.plCrew), parseFloat(updatedStoreData.plOt), parseFloat(updatedStoreData.laborPercent)),
        differenceCash: calculateDifferenceCash(parseFloat(updatedStoreData.plCash), parseFloat(updatedStoreData.overshort)),
        differenceNetSales: calculateDifferenceNetSales(parseFloat(updatedStoreData.plSales), parseFloat(updatedStoreData.netsales)),
        bonus: calculateBonus(parseFloat(updatedStoreData.plCp), parseFloat(updatedStoreData.managerCp)),
      },
    };
  });
};



  const calculateIEVariance = (actualFnP, idealFoodAndPaper) => {
    return actualFnP && idealFoodAndPaper ? actualFnP - idealFoodAndPaper : '';
  };

  const calculateDifferenceFoodPaper = (actualVariance, ieVariance) => {
    return actualVariance && ieVariance ? actualVariance - ieVariance : '';
  };

  const calculateDifferenceCrewLabor = (plCrew, plOt, laborPercent) => {
    return plCrew && plOt && laborPercent ? plCrew + plOt - laborPercent : '';
  };

  const calculateDifferenceCash = (plCash, microsCash) => {
    return plCash && microsCash ? plCash - microsCash : '';
  };

  const calculateDifferenceNetSales = (plSales, dsrNetSales) => {
    return plSales && dsrNetSales ? plSales - dsrNetSales : '';
  };

  const calculateBonus = (plCp, managerCp) => {
    return plCp && managerCp ? (plCp * managerCp / 100).toFixed(2) : '';
  };

const renderTable = (title, columns, dataCallback) => {
  const dataSource = stores.map(store => {
    const storeData = formData[store] || {};
    const data = { key: store, store: `Store ${store}`, ...dataCallback(storeData) };
    return data;
  });

    return (
      <div className="table-section">
        <Title level={4}>{title}</Title>
        <Table
          dataSource={dataSource}
          columns={columns.map(col => {
            if (col.dataIndex !== 'store') {
              return {
                ...col,
                render: (text, record) => (
                  <Input
                    type="number"
                    placeholder={`Enter ${col.dataIndex}`}
                    className="no-arrows"
                    disabled={isFormDisabled}
                    value={record[col.dataIndex] || ''}
                    onChange={(e) => handleInputChange(record.store, col.dataIndex, e.target.value)}
                  />
                ),
              };
            }
            return col;
          })}
          pagination={false}
          bordered
          scroll={{ x: 'max-content' }}
        />
      </div>
    );
  };

  return (
    <Layout className="layout">
      <Header className="header">
        <Title level={2} style={{ color: 'white' }}>P&L Review</Title>
        <div className="date-picker-wrapper">
          <DatePicker
            selected={selectedMonth}
            onChange={handleMonthChange}
            dateFormat="MMMM yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            className="date-picker"
          />
        </div>
      </Header>
      <Content className="content">
        {error && <Typography.Text type="danger">{error}</Typography.Text>}
        
        {renderTable("Food and Paper Costs", [
          { title: 'Store', dataIndex: 'store', width: 105 },
          { title: 'P&L Food', dataIndex: 'plFood', width: 150 },
          { title: 'P&L Paper', dataIndex: 'plPaper', width: 150 },
          { title: '', dataIndex: 'spacer1', render: () => null, width: 0 },
          { title: 'IE AFC %', dataIndex: 'actualFnP', width: 150 },
          { title: 'IE IFC %', dataIndex: 'idealFoodAndPaper', width: 150 },
          { title: 'IE Variance', dataIndex: 'ieVariance', width: 150 },
          { title: '', dataIndex: 'spacer2', render: () => null, width: 0 },
          { title: 'Actual Variance', dataIndex: 'actualVariance', width: 150 },
          { title: 'Difference', dataIndex: 'differenceFoodPaper', width: 150 }
        ], (storeData) => ({
          plFood: '',
          plPaper: '',
          actualFnP: storeData.actualFnP || '',
          idealFoodAndPaper: storeData.idealFoodAndPaper || '',
          ieVariance: calculateIEVariance(storeData.actualFnP, storeData.idealFoodAndPaper),
          actualVariance: '',
          differenceFoodPaper: calculateDifferenceFoodPaper('', calculateIEVariance(storeData.actualFnP, storeData.idealFoodAndPaper))
        }))}

        {renderTable("Crew Labor Costs", [
          { title: 'Store', dataIndex: 'store', width: 105 },
          { title: 'P&L Crew %', dataIndex: 'plCrew', width: 100 },
          { title: 'P&L OT %', dataIndex: 'plOt', width: 100 },
          { title: 'Micros Labor %', dataIndex: 'laborPercent', width: 100 },
          { title: 'Difference', dataIndex: 'differenceCrewLabor', width: 100 }
        ], (storeData) => ({
          plCrew: '',
          plOt: '',
          laborPercent: storeData.laborPercent || '',
          differenceCrewLabor: calculateDifferenceCrewLabor('', '', storeData.laborPercent)
        }))}

        {renderTable("Cash +/-", [
          { title: 'Store', dataIndex: 'store', width: 105 },
          { title: 'P&L Cash +/-', dataIndex: 'plCash', width: 100 },
          { title: 'Micros Cash +/-', dataIndex: 'overshort', width: 100 },
          { title: 'Difference', dataIndex: 'differenceCash', width: 100 }
        ], (storeData) => ({
          plCash: '',
          overshort: storeData.overshort || '',
          differenceCash: calculateDifferenceCash('', storeData.overshort)
        }))}

        {renderTable("Net Sales", [
          { title: 'Store', dataIndex: 'store', width: 105 },
          { title: 'P&L Sales', dataIndex: 'plSales', width: 100 },
          { title: 'DSR Net Sales', dataIndex: 'netsales', width: 100 },
          { title: 'Difference', dataIndex: 'differenceNetSales', width: 100 }
        ], (storeData) => ({
          plSales: '',
          netsales: storeData.netsales || '',
          differenceNetSales: calculateDifferenceNetSales('', storeData.netsales)
        }))}

        {renderTable("Manager Bonus", [
          { title: 'Store', dataIndex: 'store', width: 105 },
          { title: 'P&L CP $', dataIndex: 'plCp', width: 100 },
          { title: 'Manager CP %', dataIndex: 'managerCp', width: 100 },
          { title: 'Bonus', dataIndex: 'bonus', width: 100 }
        ], (storeData) => ({
          plCp: '',
          managerCp: '',
          bonus: calculateBonus('', '')
        }))}

        <Button type="primary" block style={{ marginTop: '20px' }} disabled={isFormDisabled}>
          Submit
        </Button>
      </Content>
    </Layout>
  );
}

export default PLReview;
