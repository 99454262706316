import React, { useState } from 'react';
import axios from 'axios';
import MultiStoreModal from './MultiStoreModal';
import { saveAs } from 'file-saver';
import generateExcelFile from './excelVDGenerator';
import { CircularProgress, Box, Button, Typography, TextField } from '@mui/material'; // Import Material-UI components

function VisionDental() {
  const [file, setFile] = useState(null);
  const [processedData, setProcessedData] = useState(null);
  const [storeTotals, setStoreTotals] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [needsAssignment, setNeedsAssignment] = useState(null);
  const [allStores, setAllStores] = useState([]);
  const [assignments, setAssignments] = useState({});
  const [storeInfo, setStoreInfo] = useState({});
  const [secondaryData, setSecondaryData] = useState([]); // New state for secondaryData
  const [assignedStoreData, setAssignedStoreData] = useState([]); // New state for assignedStoreData
  const [loading, setLoading] = useState(false); // New state for loading

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

const handleUpload = async () => {
  if (!file) {
    console.error('No file selected');
    return;
  }

  setLoading(true); // Set loading to true when upload starts

  try {
    const formData = new FormData();
    formData.append('file', file);

    const baseUrl = window.location.hostname === '72.167.34.236'
      ? 'http://72.167.34.236:5000'
      : 'https://prolifi.app';

    const response = await axios.post(`${baseUrl}/api/vision-dental/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    });

    //console.log('Response:', response.data);

    // Process the response data
    const { sortedData, storeInfo, needsAssignment, secondaryData } = response.data;

    // Utility function to remove duplicates in the retroactivity array
    const removeDuplicateRetroactivity = (retroactivityArray) => {
      const uniqueRetroactivity = [];
      const seen = new Set(); // Create a set to track unique combinations

      retroactivityArray.forEach(retro => {
        const uniqueKey = `${retro.identifier}-${retro.changeCode}-${retro.adjEff}-${retro.product}-${retro.contractID}-${retro.adjAmnt}`;
        if (!seen.has(uniqueKey)) {
          uniqueRetroactivity.push(retro);
          seen.add(uniqueKey);
        }
      });

      return uniqueRetroactivity;
    };

    // Function to process retroactivity for both sortedData and needsAssignment
    const processRetroactivity = (data) => {
      for (const entry of data) {
        if (entry.retroactivity && Array.isArray(entry.retroactivity)) {
          entry.retroactivity = removeDuplicateRetroactivity(entry.retroactivity);
        }
      }
    };

    // Function to process secondaryData and create or update entries in needsAssignment
    const processSecondaryData = (secondaryData, sortedData, needsAssignment) => {
      secondaryData.forEach(entry => {
        // Check if entry is valid
        if (!entry.FN || !entry.LN || entry.adjAmnt === null) {
          return; // Skip invalid entry
        }

        // Check if the entry already exists in sortedData
        let entryExistsInSortedData = false;
        for (const category in sortedData) {
          if (sortedData[category].some(item => item.identifier === entry.identifier)) {
            entryExistsInSortedData = true;
            break;
          }
        }

        // If entry exists in sortedData, move to the next entry
        if (entryExistsInSortedData) {
          return;
        }

        // Check if the entry already exists in needsAssignment
        let existingEntry = needsAssignment.find(item => item.identifier === entry.identifier);

        // If entry exists in needsAssignment, update it
        if (existingEntry) {
          // Check if the product (VISION/DENTAL) is already added, if not, add it
          const productExists = existingEntry.products.some(product => product.product === entry.product);
          if (!productExists) {
            existingEntry.products.push({
              product: entry.product || 'VISION',
              contractType: entry.prevCT || 'EMPLOYEE ONLY',
              totDue: 0.00, // Ensure totDue for products is always 0
              homeStore: 'NA'
            });
          }

          // Check if retroactivity already exists for this product and entry
          const retroactivityExists = existingEntry.retroactivity.some(
            retro => retro.changeCode === entry.changeCode && retro.contractID === entry.contractID && retro.adjAmnt === entry.adjAmnt
          );
          if (!retroactivityExists) {
            existingEntry.retroactivity.push({
              changeCode: entry.changeCode || 'ADD NEW CONTRACT',
              contractID: entry.contractID || null,
              identifier: entry.identifier,
              FN: entry.FN,
              LN: entry.LN,
              product: entry.product || 'VISION',
              prevCT: entry.prevCT || 'EMPLOYEE ONLY',
              adjEff: entry.adjEff || null,
              adjEnd: entry.adjEnd || null,
              adjAmnt: entry.adjAmnt || 0 // Retroactivity amount goes here
            });
          }

        } else {
          // If entry doesn't exist in needsAssignment, create a new entry
          needsAssignment.push({
            identifier: entry.identifier,
            FN: entry.FN,
            LN: entry.LN,
            MI: entry.MI || null, // Add middle initial if available
            Suff: entry.Suff || null, // Add suffix if available
            stores: entry.stores || [], // Add store structure or empty array
            products: [
              {
                product: entry.product || 'VISION',
                contractType: entry.prevCT || 'EMPLOYEE ONLY',
                totDue: 0.00, // Ensure totDue for products is always 0
                homeStore: 'NA'
              }
            ],
            retroactivity: [
              {
                changeCode: entry.changeCode || 'ADD NEW CONTRACT',
                contractID: entry.contractID || null,
                identifier: entry.identifier,
                FN: entry.FN,
                LN: entry.LN,
                product: entry.product || 'VISION',
                prevCT: entry.prevCT || 'EMPLOYEE ONLY',
                adjEff: entry.adjEff || null,
                adjEnd: entry.adjEnd || null,
                adjAmnt: entry.adjAmnt || 0 // Retroactivity amount goes here
              }
            ]
          });
        }
      });
    };

    // Process retroactivity for sortedData
    for (const store in sortedData) {
      sortedData[store].forEach(entry => {
        processRetroactivity([entry]);
      });
    }

    // Process retroactivity for needsAssignment
    processRetroactivity(needsAssignment);

    // Process secondaryData and create or update entries in needsAssignment if necessary
    processSecondaryData(secondaryData, sortedData, needsAssignment);

    // Now set the processed data
    setProcessedData(sortedData);
    setNeedsAssignment(needsAssignment);
    setStoreInfo(storeInfo);
    setSecondaryData(secondaryData); // Set secondaryData in state

    // Calculate all unique stores
    const stores = new Set();
    for (const category in sortedData) {
      for (const store in sortedData[category]) {
        stores.add(store);
      }
    }
    setAllStores(Array.from(stores));

    // If there are needs assignment entries, show the modal
    if (needsAssignment.length > 0) {
      setShowModal(true);
    } else {
      handleDownload(sortedData, storeInfo, secondaryData, []);
    }

  } catch (error) {
    console.error('Error uploading file:', error);
  } finally {
    setLoading(false); // Set loading to false when upload completes
  }
};



const calculateStoreTotals = (data, assignments, needsAssignment) => {
  const totals = {};

  // Helper function to find all matching entries in needsAssignment by identifier
  const findAssignmentEntries = (identifier) => {
    return needsAssignment.filter(entry => entry.identifier === identifier);
  };

  // Process store data (main data)
  for (const store in data) {
    //console.log(`Processing store: ${store}`);
    totals[store] = data[store].reduce((acc, entry) => {
      if (entry.products && Array.isArray(entry.products)) {  // Check if products exist
        entry.products.forEach(product => {
          if (!acc[product.product]) {
            acc[product.product] = 0;
          }
          //console.log(`Adding ${product.totDue} for product ${product.product} in store ${store}`);
          acc[product.product] += product.totDue;

          // Process retroactivity adjustments for each product
          if (entry.retroactivity && Array.isArray(entry.retroactivity)) {
            entry.retroactivity.forEach(retro => {
              if (retro.product === product.product) {
                //console.log(`Adding retroactivity adjustment of ${retro.adjAmnt} for product ${retro.product} in store ${store}`);
                acc[retro.product] += retro.adjAmnt; // Add retroactivity adjustment to the total
              }
            });
          }
        });
      } else {
        //console.log(`No products found for entry in store ${store}`);
      }
      return acc;
    }, {});
    //console.log(`Totals for store ${store}:`, totals[store]);
  }

  //console.log("Assignments: ", assignments);

  // Process retroactivity adjustments from needsAssignment based on assignments
  for (const store in assignments) {
    if (!totals[store]) {
      totals[store] = {};
    }

    assignments[store].forEach(assignedEntry => {
      // Find all matching entries in needsAssignment by identifier
      const matchingEntries = findAssignmentEntries(assignedEntry.identifier);

      matchingEntries.forEach(needsAssignmentEntry => {
        if (needsAssignmentEntry.retroactivity && Array.isArray(needsAssignmentEntry.retroactivity)) {
          needsAssignmentEntry.retroactivity.forEach(retro => {
            if (!totals[store][retro.product]) {
              totals[store][retro.product] = 0;
            }
            //console.log(`Adding retroactivity adjustment of ${retro.adjAmnt} for product ${retro.product} in store ${store}`);
            totals[store][retro.product] += retro.adjAmnt; // Add retroactivity adjustment to the total
          });
        } else {
          //console.log(`No retroactivity found for entry in needsAssignment for identifier ${assignedEntry.identifier}`);
        }
      });
    });

   // console.log(`Totals for store ${store} after retroactivity adjustments:`, totals[store]);
  }

  //console.log('Final calculated totals:', totals);
  return totals;
};



  const mapStoreNumbersToNames = (data, storeInfo) => {
    const updatedData = {};
    for (const store in data) {
      const storeName = storeInfo[store] || 'Unknown Store';
      updatedData[storeName] = data[store];
    }
    return updatedData;
  };

  const handleAssign = (updatedSingleStoreData, updatedMultiStoreData, assignmentData, assignedStoreData) => {
    // Calculate store totals with manual assignments
    const totals = calculateStoreTotals(updatedSingleStoreData, assignmentData, needsAssignment);

    // Update state with new store totals
    setStoreTotals(totals);

    // Update state
    setProcessedData(updatedSingleStoreData);
    setShowModal(false);

    // Set the assigned store data
    setAssignedStoreData(assignedStoreData);

    // Map store numbers to names
    const updatedDataWithStoreNames = mapStoreNumbersToNames(updatedSingleStoreData, storeInfo);
			//console.log("Updated with store names USSD: ", updatedSingleStoreData);

    // Trigger download after assignments are set
    handleDownload(updatedDataWithStoreNames, storeInfo, secondaryData, assignedStoreData);
  };

  const handleDownload = async (updatedSingleStoreData, storeInfo, secondaryData, assignedStoreData) => {
    if (!updatedSingleStoreData) {
      console.error('No store data available for download');
      return;
    }

    try {
		//console.log("handleDownload USSD: ", updatedSingleStoreData);
      const buffer = await generateExcelFile(updatedSingleStoreData, storeInfo, secondaryData, assignedStoreData);

      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'store_totals.xlsx');
    } catch (error) {
      console.error('Error generating Excel file:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setAssignments({});
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>Vision and Dental Component</Typography>
      <TextField
        type="file"
        onChange={handleFileChange}
        sx={{ mb: 2 }}
        InputLabelProps={{ shrink: true }}
        inputProps={{ accept: '.xlsx,.xls,.csv' }}
      />
      <Button
        variant="contained"
        onClick={handleUpload}
        disabled={loading}
        sx={{ mb: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : 'Upload File'}
      </Button>
      {showModal && (
        <MultiStoreModal
          needsAssignment={needsAssignment}
          singleStoreData={processedData}
          onAssign={handleAssign}
          assignments={assignments}
          setAssignments={setAssignments}
          onClose={handleCloseModal} // Pass handleCloseModal to MultiStoreModal
        />
      )}
    </Box>
  );
}

export default VisionDental;
