const createProfitabilityReportSheet = (weeklySalesData) => {
  // Define the header structure with merged cells where required by the spreadsheet format
  const headers = [
    ['', 'Weekly Profitability Report', '', '', '', '', '', '', '', ''],
	['', '', '', '', '', '', '', '', '', ''],
    ['', '', 'Crew Production', '', 'Crew Labor', '', 'Variance', '', 'Cash +/-', ''],
    ['Supervisor', 'Store', 'Week', 'Month', 'Week', 'Month', 'Week', 'Month', 'Week', 'Month']
  ];

  // Calculate the data rows from the weeklySalesData object
  const storeData = weeklySalesData.map(entry => {
    const crewProductionWeek = entry.currentWeek.netSales && entry.profitData.hours_full_week ? (parseFloat(entry.currentWeek.netSales) / entry.profitData.hours_full_week).toFixed(2) : 'N/A';
    const crewProductionMonth = entry.netSalesMonth && entry.profitData.totalHoursForMonth ? (parseFloat(entry.netSalesMonth) / entry.profitData.totalHoursForMonth).toFixed(2) : 'N/A';
    const crewLaborWeek = entry.currentWeek.labor && entry.currentWeek.netSales
      ? ((parseFloat(entry.currentWeek.labor) / parseFloat(entry.currentWeek.netSales)) * 100).toFixed(2)
      : 'N/A';
    const crewLaborMonth = entry.totalLaborMonth && entry.netSalesMonth
      ? ((parseFloat(entry.totalLaborMonth) / parseFloat(entry.netSalesMonth)) * 100).toFixed(2)
      : 'N/A';
    const varianceWeek = entry.profitData.food_variance_week !== null ? entry.profitData.food_variance_week : 'N/A';
    const varianceMonth = entry.profitData.food_variance_month !== null ? entry.profitData.food_variance_month : 'N/A';
    const cashWeek = entry.currentWeek.overshort !== null ? entry.currentWeek.overshort : 'N/A';
    const cashMonth = entry.profitData.totalOvershortMonth !== null ? entry.profitData.totalOvershortMonth : 'N/A';

    return [
      entry.supervisorName,
      entry.storeName,
      crewProductionWeek,
      crewProductionMonth,
      crewLaborWeek,
      crewLaborMonth,
      varianceWeek,
      varianceMonth,
      cashWeek,
      cashMonth
    ];
  });

  // Combine the header and store data arrays
  const reportData = [...headers, ...storeData];

  return reportData;
};

export default createProfitabilityReportSheet;