import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, useSortBy } from 'react-table';
import { calculateKpiPoints } from './kpiPointsCalculator';
import moment from 'moment';
import styles from './EmployeeAnalyzer.module.css';

const getBaseUrl = () => {
  const hostname = window.location.hostname;

  if (hostname === '72.167.34.236') {
    return 'http://72.167.34.236:5000';
  } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
    return 'https://prolifi.app';
  } else {
    console.error('Unknown host');
    return ''; // Return an empty string or a default URL if preferred
  }
};

const EmployeeAnalyzer = () => {
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [employees, setEmployees] = useState([]);
  const [period, setPeriod] = useState('');
  const [analysisType, setAnalysisType] = useState('overall');
  const [selectedDaypart, setSelectedDaypart] = useState('');
  const [employeeShiftData, setEmployeeShiftData] = useState({});
  const [filteredEmployees, setFilteredEmployees] = useState([]);

  const generalManagers = ['Jonathan Boltin', 'Abigail Edgecomb']; // Static list of general managers

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const baseUrl = getBaseUrl();
        const response = await axios.get(`${baseUrl}/api/store/stores`);
        setStores(response.data);
      } catch (error) {
        console.error('Error fetching stores:', error);
      }
    };

    fetchStores();
  }, []);

  useEffect(() => {
    if (selectedStore) {
      const fetchEmployees = async () => {
        try {
          const baseUrl = getBaseUrl();
          const response = await axios.get(`${baseUrl}/api/store/employees/${selectedStore}`);
          const employeesList = response.data
            .flatMap(employee => employee.staff.split(',').map(name => name.trim()))
            .filter((value, index, self) => value !== '-' && self.indexOf(value) === index); // Unique employees excluding '-'
          setEmployees(employeesList);
        } catch (error) {
          console.error('Error fetching employees:', error);
        }
      };

      fetchEmployees();
    }
  }, [selectedStore]);

  useEffect(() => {
    // Reset employeeShiftData whenever period changes
    setEmployeeShiftData({});

    if (selectedStore && period && employees.length > 0) {
      const fetchEmployeeShiftData = async () => {
        const baseUrl = getBaseUrl();
        const data = {};

        // Calculate the start date based on the selected period
        let startDate;
        const endDate = moment(); // Current date

        switch (period) {
          case '1_year':
            startDate = moment().subtract(1, 'year');
            break;
          case '6_months':
            startDate = moment().subtract(6, 'months');
            break;
          case '3_months':
            startDate = moment().subtract(3, 'months');
            break;
          case '2_months':
            startDate = moment().subtract(2, 'months');
            break;
          case '1_month':
            startDate = moment().subtract(1, 'month');
            break;
          case '1_week': // New "1 Week" option
            startDate = moment().subtract(1, 'week');
            break;
          default:
            startDate = moment('2000-01-01'); // If "all" is selected or any other fallback case
        }

        console.log(`Fetching data from ${startDate.format('YYYY-MM-DD')} to ${endDate.format('YYYY-MM-DD')}`);

console.log('ret start: ', startDate.format('YYYY-MM-DD'), ' & ret end ', endDate.format('YYYY-MM-DD'));

      for (const employee of employees) {
        const params = {
          storeId: selectedStore,
          employee,
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          analysisType,
          selectedDaypart
        };

        console.log('Params for API request:', params); // Log the params object

        try {
          const response = await axios.get(`${baseUrl}/api/store/employee-shifts`, {
            params
          });
          if (hasValidKPI(response.data)) {
            data[employee] = response.data;
          }
        } catch (error) {
          console.error(`Error fetching shifts for ${employee}:`, error);
        }
      }

      setEmployeeShiftData(data);
    };

      fetchEmployeeShiftData();
    }
  }, [selectedStore, period, employees, analysisType, selectedDaypart]); // Updated dependencies

  useEffect(() => {
    if (Object.keys(employeeShiftData).length > 0) {
      const totalShifts = Object.values(employeeShiftData).reduce((total, shifts) => total + shifts.length, 0);
      const threshold = totalShifts * .000000001;
	  //console.log(`Total Shifts: ${totalShifts} Threshold: ${threshold} shifts`);

      const filterEmployeesWithKPI = () => {
        const validEmployees = employees
          .filter(employee => hasValidKPI(employeeShiftData[employee]))
          .filter(employee => !generalManagers.includes(employee)) // Exclude general managers
          .filter(employee => {
            const shiftCount = employeeShiftData[employee].length;
            //console.log(`${employee} has ${shiftCount} shifts`);
            return shiftCount >= threshold; // Exclude employees with less than 5% of total shifts
          });
        setFilteredEmployees(validEmployees);
      };

      filterEmployeesWithKPI();
    }
  }, [employeeShiftData, employees, analysisType, selectedDaypart]); // Updated dependencies

  const hasValidKPI = (data) => {
    if (!data || data.length === 0) return false;
    return data.some(shift => Object.values(shift.dayparts).some(daypart => {
      return daypart.labor > 0 || daypart.spmh > 0 || daypart.on_time > 0 || daypart.avg_ticket_time > 0 || daypart.tickets > 0 || daypart.net_sales > 0;
    }));
  };

const calculateKPI = (shiftsData) => {
  let totalTickets = 0;
  let totalTicketTime = 0;
  let totalWeightedLabor = 0;
  let totalSales = 0;
  let totalOnTimeTickets = 0;
  let totalSpmhHours = 0;
  let totalWeight = 0;

  let totalPoints = 0;
  let entryCount = 0;
  let shiftCount = 0;

  shiftsData.forEach(shift => {
    Object.entries(shift.dayparts).forEach(([daypart, data]) => {
      if (analysisType === 'overall' && (daypart === 'Breakfast' || daypart === 'Evening')) return;
      if (analysisType === 'daypart' && daypart !== selectedDaypart) return;
      if (data.weight <= 0.333334) {
        //console.log(`Excluding ${daypart} due to weight: ${data.weight}`);
        return;
      }

      shiftCount++; // Count the number of valid shifts for this employee

      const tickets = parseFloat(data.tickets) || 0;
      const avgTicketTime = parseFloat(data.avg_ticket_time) || 0;
      const labor = parseFloat(data.labor) || 0;
      const netSales = parseFloat(data.net_sales) || 0;
      const onTime = parseFloat(data.on_time) || 0;
      const spmh = parseFloat(data.spmh) || 0;
      const weight = parseFloat(data.weight) || 0;

      totalTickets += tickets;
      totalTicketTime += avgTicketTime * tickets;
      totalWeightedLabor += (labor / netSales) * weight * netSales;
      totalSales += netSales;
      totalOnTimeTickets += onTime;
      totalSpmhHours += spmh;
      totalWeight += weight;

      const points = calculateKpiPoints({
        avgTicketTime: `${Math.floor(avgTicketTime / 60)}:${Math.round(avgTicketTime % 60) < 10 ? '0' : ''}${Math.round(avgTicketTime % 60)}`,
        labor: totalSales ? ((totalWeightedLabor / totalSales) * 100).toFixed(2) : 0,
        onTime: totalTickets ? ((totalOnTimeTickets / totalTickets) * 100).toFixed(2) : 0,
        spmh: totalSpmhHours ? (totalSales / totalSpmhHours).toFixed(2) : 0,
        weight: weight,
		daypart: daypart
      });

      totalPoints += points.avgTicketTimePoints + points.laborPoints + points.onTimePoints + points.spmhPoints;
      entryCount++;

      //console.log(`Including ${daypart}`);
      //console.log('Daypart data:', data);
    });
  });

  //console.log('Total Shifts:', shiftCount); // Log the number of shifts for the employee

  //console.log('Total Tickets:', totalTickets);
  //console.log('Total Ticket Time:', totalTicketTime);
  //console.log('Total Weighted Labor:', totalWeightedLabor);
  //console.log('Total Sales:', totalSales);
  //console.log('Total On Time Tickets:', totalOnTimeTickets);
  //console.log('Total SPMH Hours:', totalSpmhHours);
  //console.log('Total Weight:', totalWeight);

  // Calculate average metrics
  const avgTicketTime = totalTickets ? (totalTicketTime / totalTickets) : 0;
  const labor = totalSales ? (totalWeightedLabor / totalSales) : 0;
  const onTime = totalTickets ? (totalOnTimeTickets / totalTickets) : 0;
  const spmh = totalSpmhHours ? (totalSales / totalSpmhHours) : 0;

  //console.log('Avg Ticket Time (seconds):', avgTicketTime);
  //console.log('Labor Percentage:', labor);
  //console.log('On Time Percentage:', onTime);
  //console.log('SPMH Value:', spmh);

  // Convert avgTicketTime to minutes:seconds format
  const minutes = Math.floor(avgTicketTime / 60);
  const seconds = Math.round(avgTicketTime % 60);
  const formattedAvgTicketTime = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  const avgPoints = entryCount > 0 ? (totalPoints / entryCount).toFixed(2) : 0;

  return {
    avgTicketTime: formattedAvgTicketTime,
    labor: (labor * 100).toFixed(2),
    onTime: (onTime * 100).toFixed(2),
    spmh: spmh.toFixed(2),
    avgPoints,
    shiftCount // Return the shift count for threshold filtering
  };
};

useEffect(() => {
  if (Object.keys(employeeShiftData).length > 0) {
    const totalShifts = Object.values(employeeShiftData).reduce((total, shifts) => {
      return total + shifts.reduce((daypartTotal, shift) => {
        return daypartTotal + Object.entries(shift.dayparts).reduce((daypartCount, [daypart, data]) => {
          if (analysisType === 'overall' && (daypart === 'Breakfast' || daypart === 'Evening')) return daypartCount;
          if (analysisType === 'daypart' && daypart !== selectedDaypart) return daypartCount;
          return daypartCount + 1; // Count the shift if it matches the analysis criteria
        }, 0);
      }, 0);
    }, 0);

    const threshold = totalShifts * 0.015; // Adjust the threshold to 5% of total shifts

    const filterEmployeesWithKPI = () => {
      const validEmployees = employees
        .filter(employee => hasValidKPI(employeeShiftData[employee]))
        .filter(employee => !generalManagers.includes(employee)) // Exclude general managers
        .filter(employee => {
          const shiftData = calculateKPI(employeeShiftData[employee]);
          //console.log(`Total Shifts: ${totalShifts} Threshold: ${threshold} shifts`);
          //console.log(`${employee} has ${shiftData.shiftCount} shifts`);
          return shiftData.shiftCount >= threshold; // Exclude employees with less than 5% of total shifts
        });
      setFilteredEmployees(validEmployees);
    };

    filterEmployeesWithKPI();
  }
}, [employeeShiftData, employees, analysisType, selectedDaypart]); // Updated dependencies

  const data = useMemo(() => {
    return filteredEmployees.map(employee => {
      const kpiData = employeeShiftData[employee] ? calculateKPI(employeeShiftData[employee]) : {};
      return {
        employee,
        avgTicketTime: kpiData.avgTicketTime,
        labor: kpiData.labor,
        onTime: kpiData.onTime,
        spmh: kpiData.spmh,
        avgPoints: kpiData.avgPoints
      };
    }).filter(row => row.avgTicketTime !== '0:00');
  }, [filteredEmployees, employeeShiftData, analysisType, selectedDaypart]);

  const columns = useMemo(
    () => [
      {
        Header: 'Employee',
        accessor: 'employee',
      },
      {
        Header: 'Ave. Time',
        accessor: 'avgTicketTime',
      },
      {
        Header: 'Labor',
        accessor: 'labor',
      },
      {
        Header: 'On Time',
        accessor: 'onTime',
      },
      {
        Header: 'SPMH',
        accessor: 'spmh',
      },
      {
        Header: 'Avg Points',
        accessor: 'avgPoints',
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const isReadyToRenderTable = () => {
    if (analysisType === 'daypart') {
      return selectedStore && period && selectedDaypart && filteredEmployees.length > 0;
    } else {
      return selectedStore && period && filteredEmployees.length > 0;
    }
  };

 return (
    <div>
      <h2>Employee Analyzer</h2>
      <div className={styles.filters}>
        <div>
          <label>Select Store:</label>
          <select value={selectedStore} onChange={(e) => setSelectedStore(e.target.value)}>
            <option value="">--Select Store--</option>
            {stores.map((store) => (
              <option key={store.store_id} value={store.store_id}>
                {store.store_id}
              </option>
            ))}
          </select>
        </div>
        {selectedStore && (
          <>
            <div>
              <label>Select Time Period:</label>
              <select value={period} onChange={(e) => setPeriod(e.target.value)}>
                <option value="">--Select Time Period--</option>
                <option value="all">All Time</option>
                <option value="1_year">1 Year</option>
                <option value="6_months">6 Months</option>
                <option value="3_months">3 Months</option>
                <option value="2_months">2 Months</option>
                <option value="1_month">1 Month</option>
                <option value="1_week">1 Week</option> {/* New 1 Week Option */}
              </select>
            </div>
            <div>
              <label>Select Analysis Type:</label>
              <select value={analysisType} onChange={(e) => setAnalysisType(e.target.value)}>
                <option value="overall">Overall</option>
                <option value="daypart">Individual Daypart</option>
              </select>
            </div>
            {analysisType === 'daypart' && (
              <div>
                <label>Select Daypart:</label>
                <select value={selectedDaypart} onChange={(e) => setSelectedDaypart(e.target.value)}>
                  <option value="">--Select Daypart--</option>
                  <option value="Breakfast">Breakfast</option>
                  <option value="Lunch">Lunch</option>
                  <option value="Afternoon">Afternoon</option>
                  <option value="Dinner">Dinner</option>
                  <option value="Evening">Evening</option>
                </select>
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.tableContainer}>
        {isReadyToRenderTable() && (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default EmployeeAnalyzer;
