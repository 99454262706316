import React, { useState, useEffect } from 'react';

function StickerComponent() {
  const [isApiLoaded, setApiLoaded] = useState(false);

  useEffect(() => {
    const checkApiAvailability = () => {
      if (typeof window.bGateWebPrintAPI === 'function') {
        console.log('bGateWebPrintAPI is available and is a function:', window.bGateWebPrintAPI);
        setApiLoaded(true);
      } else {
        console.warn('bGateWebPrintAPI not available yet.', window.bGateWebPrintAPI);
        setTimeout(checkApiAvailability, 500);
      }
    };
    checkApiAvailability();
  }, []);

  const handlePrint = () => {
    if (!isApiLoaded) {
      console.error('API is not yet loaded.');
      return;
    }

    try {
      const bgtWebPrint = new window.bGateWebPrintAPI();
      console.log('Successfully created bGateWebPrintAPI instance:', bgtWebPrint);

      // Expanded additional operations with the instance
      var ipAddr = "192.168.0.100";
      var shopID = "BGATE_SAMPLE_SHOP";
      var devID = "local_printer";

      bgtWebPrint.sendData(ipAddr, shopID, devID);

      bgtWebPrint.makeAlign({pos: 'center'});
      bgtWebPrint.makeText({
        code: 'page0',
        ics: 'usa',
        font: 'fonta',
        wd: '2',
        ht: '2',
        uline: 'false',
        bold: 'true',
        reverse: 'false',
        rotate: '0',
        lsp: 256,
        data: 'Hello! World'
      });

      bgtWebPrint.makePaperCut({cuttype: 'feed'});
    } catch (error) {
      console.error('Failed to create bGateWebPrintAPI instance:', error);
    }
  };

  return (
    <div>
      <h1>Successfully connected to Sticker Component</h1>
      <button onClick={handlePrint} disabled={!isApiLoaded}>Print Sticker</button>
    </div>
  );
}

export default StickerComponent;