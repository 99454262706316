import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import { useTable, useSortBy, usePagination } from 'react-table';
import Modal from 'react-modal';
import styles from './CorrectiveActionDashboard.module.css';

// Helper function to get base URL
const getBaseUrl = () => {
  const hostname = window.location.hostname;
  if (hostname === '72.167.34.236') {
    return 'http://72.167.34.236:5000';
  } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
    return 'https://prolifi.app';
  } else {
    console.error('Unknown host');
    return ''; // Return an empty string or a default URL if preferred
  }
};

const offenseTypes = [
  'Attendance',
  'Substandard Work',
  'Rudeness',
  'Violation of Company Policies',
  'Violation of Safety Rules',
  'Cash Shortage',
  'Correct Uniform Warning',
  'Other'
]; // Updated offense types
const timePeriods = ['1 Month', '3 Months', '6 Months', '1 Year', 'All Time']; // Example time periods

const CorrectiveActionDashboard = () => {
  const [employeeNames, setEmployeeNames] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');
  const [selectedOffenseType, setSelectedOffenseType] = useState('');
  const [selectedTimePeriod, setSelectedTimePeriod] = useState('');
  const [correctiveActions, setCorrectiveActions] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [actionToDelete, setActionToDelete] = useState(null);

  useEffect(() => {
    const fetchEmployeeNames = async () => {
      try {
        const baseUrl = getBaseUrl();
        const response = await axios.get(`${baseUrl}/api/corrective-action-dashboard/employee-names`, { withCredentials: true });
        setEmployeeNames(response.data);
      } catch (error) {
        console.error('Error fetching employee names:', error);
      }
    };

    fetchEmployeeNames();
  }, []);

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = suggestion => suggestion;

  const renderSuggestion = suggestion => (
    <div className={styles.suggestionItem}>
      {suggestion}
    </div>
  );

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const filteredSuggestions = inputLength === 0 ? [] : employeeNames.filter(name =>
      name.toLowerCase().includes(inputValue)
    );

    console.log('Filtered Suggestions:', filteredSuggestions); // Debugging line
    return filteredSuggestions;
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const handleOffenseTypeChange = (event) => {
    setSelectedOffenseType(event.target.value);
  };

  const handleTimePeriodChange = (event) => {
    setSelectedTimePeriod(event.target.value);
  };

  const calculateDaysOld = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const differenceInTime = currentDate - createdDate;
    return Math.floor(differenceInTime / (1000 * 3600 * 24));
  };

  const handleListCAsClick = async () => {
    const baseUrl = getBaseUrl();
    try {
      const response = await axios.get(`${baseUrl}/api/corrective-action-dashboard/list-cas`, {
        params: {
          employeeName: value,
          offenseType: selectedOffenseType,
          timePeriod: selectedTimePeriod,
        },
        withCredentials: true
      });
      setCorrectiveActions(response.data);
    } catch (error) {
      console.error('Error fetching corrective actions:', error);
    }
  };

  const handleGeneratePDF = async (id) => {
    const baseUrl = getBaseUrl();
    try {
      const response = await axios.get(`${baseUrl}/api/corrective-action-dashboard/generate-pdf/${id}`, {
        responseType: 'blob',
        withCredentials: true,
      });

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `CorrectiveAction_${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  const openModal = (id) => {
    setActionToDelete(id);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setActionToDelete(null);
    setModalIsOpen(false);
  };

  const handleDeleteCA = async () => {
    const baseUrl = getBaseUrl();
    try {
      await axios.delete(`${baseUrl}/api/corrective-action-dashboard/delete/${actionToDelete}`, { withCredentials: true });
      // Update state to remove deleted corrective action
      setCorrectiveActions(prev => prev.filter(action => action.id !== actionToDelete));
      closeModal();
    } catch (error) {
      console.error('Error deleting corrective action:', error);
    }
  };

  const data = useMemo(() => correctiveActions.map(action => ({
    id: action.id,
    warningType: action.warningType,
    description: action.description,
    improvementPlan: action.improvementPlan,
    consequences: action.consequences,
    daysOld: calculateDaysOld(action.createdAt),
    submitterName: action.submitterName,
  })), [correctiveActions]);

  const columns = useMemo(() => [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Warning Type', accessor: 'warningType' },
    { Header: 'Description', accessor: 'description' },
    { Header: 'Improvement', accessor: 'improvementPlan' },
    { Header: 'Consequences', accessor: 'consequences' },
    { Header: 'Days Old', accessor: 'daysOld' },
    { Header: 'Submitting Manager', accessor: 'submitterName' },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <div className={styles.actionButtons}>
          <button onClick={() => handleGeneratePDF(row.original.id)} className={styles.actionButton}>PDF</button>
          <button onClick={() => openModal(row.original.id)} className={styles.actionButton}>Delete</button>
        </div>
      )
    }
  ], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable({
    columns,
    data,
    initialState: { pageIndex: 0, pageSize: 10 }, // Set page size to 10
  }, useSortBy, usePagination);

  const inputProps = {
    placeholder: 'Type employee name...',
    value,
    onChange,
    className: styles.inputField
  };

  return (
    <div className={styles.cadContainer}>
      <h1>CAD Successfully Loaded</h1>
      <div className={styles.formRow}>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          theme={{
            container: styles.autosuggestContainer,
            suggestionsContainer: styles.suggestionsContainer,
            suggestion: styles.suggestionItem,
            suggestionHighlighted: styles.suggestionItemHighlighted,
            suggestionsList: styles.suggestionsList,
          }}
        />
        <select value={selectedOffenseType} onChange={handleOffenseTypeChange} className={styles.dropdown}>
          <option value="">Select Offense Type</option>
          {offenseTypes.map((offenseType, index) => (
            <option key={index} value={offenseType}>{offenseType}</option>
          ))}
        </select>
        <select value={selectedTimePeriod} onChange={handleTimePeriodChange} className={styles.dropdown}>
          <option value="">Select Time Period</option>
          {timePeriods.map((timePeriod, index) => (
            <option key={index} value={timePeriod}>{timePeriod}</option>
          ))}
        </select>
        <button onClick={handleListCAsClick} className={styles.button}>List CAs</button>
      </div>
      {correctiveActions.length > 0 && (
        <div className={styles.tableContainer}>
          <table {...getTableProps()} className={styles.table}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className={styles.pagination}>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Previous
            </button>
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </button>
          </div>
        </div>
      )}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Delete"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this corrective action?</p>
        <button onClick={handleDeleteCA} className={styles.confirmButton}>Yes</button>
        <button onClick={closeModal} className={styles.cancelButton}>No</button>
      </Modal>
    </div>
  );
};

export default CorrectiveActionDashboard;
