import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <div style={styles.policyContainer}>
        <h1 style={styles.header}>Privacy Policy</h1>
        <p style={styles.date}>Effective Date: September 18th, 2024</p>
        
        <section>
          <h2 style={styles.sectionHeader}>1. Introduction</h2>
          <p style={styles.text}>
            ProlifiTech - CheckLists (referred to as "the App") is developed by ProlifiTech Incorporated 
            (referred to as "we", "our", or "us"). This Privacy Policy outlines how we collect, use, and 
            protect any information you provide when using the App.
          </p>
        </section>

        <section>
          <h2 style={styles.sectionHeader}>2. Information We Collect</h2>
          <p style={styles.text}>
            We do not collect personal information such as your name, email, or phone number. However, 
            we may collect location data through GeoFencing to log your device into the correct store 
            location and load the corresponding checklists.
          </p>
          <p style={styles.highlightedText}>
            <strong>Location Data:</strong> We collect location data to ensure the App is associated with 
            the correct store location. This data is used solely for this functionality and is not shared 
            with any third party.
          </p>
        </section>

        <section>
          <h2 style={styles.sectionHeader}>3. Use of Information</h2>
          <p style={styles.text}>
            The information we collect is used for ensuring the App functions properly by associating 
            your device with the correct store. Once the location data has been used for this purpose, 
            it is destroyed and not retained.
          </p>
        </section>

        {/* Additional Sections as in the original */}
        <section>
          <h2 style={styles.sectionHeader}>4. Third-Party Services</h2>
          <p style={styles.text}>
            We do not use third-party services, analytics tools, or ad networks within the App. Your 
            data is not shared with any third parties for advertising, analytics, or any other purposes.
          </p>
        </section>

        <section>
          <h2 style={styles.sectionHeader}>5. Data Retention</h2>
          <p style={styles.text}>
            We do not retain any personal or location data beyond its immediate use to ensure the App 
            functions correctly. Data collected for store association is destroyed after use.
          </p>
        </section>

        {/* Continue with other sections as per your original code */}

        <section>
          <h2 style={styles.sectionHeader}>11. Contact Us</h2>
          <p style={styles.text}>
            If you have any questions or concerns regarding this Privacy Policy or the practices of 
            the App, please contact us at:
          </p>
          <p style={styles.contact}>
            Email: <a style={styles.emailLink} href="mailto:AppHelp@Prolifi.App">AppHelp@Prolifi.App</a>
          </p>
        </section>
      </div>
    </div>
  );
};

const styles = {
  container: {
    background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)', // Dynamic gradient background
    padding: '40px 20px',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
  },
  policyContainer: {
    backgroundColor: '#fff',
    color: '#333',
    borderRadius: '15px',
    padding: '40px',
    maxWidth: '800px',
    boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
    transition: 'transform 0.3s ease',
    ':hover': {
      transform: 'scale(1.01)', // Slight zoom on hover for dynamic effect
    },
  },
  header: {
    fontSize: '2.5rem',
    fontWeight: '700',
    textAlign: 'center',
    marginBottom: '20px',
    background: 'linear-gradient(90deg, #ff7e5f 0%, #feb47b 100%)', // Gradient text
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  date: {
    fontSize: '1rem',
    textAlign: 'center',
    color: '#888',
    marginBottom: '40px',
  },
  sectionHeader: {
    fontSize: '1.8rem',
    color: '#ff7e5f',
    marginBottom: '15px',
  },
  text: {
    fontSize: '1.1rem',
    lineHeight: '1.6',
    color: '#444',
    marginBottom: '20px',
  },
  highlightedText: {
    fontSize: '1.1rem',
    lineHeight: '1.6',
    backgroundColor: '#fffbeb',
    padding: '10px',
    borderRadius: '5px',
    color: '#555',
    marginBottom: '20px',
  },
  contact: {
    fontSize: '1.1rem',
    color: '#333',
    marginTop: '30px',
  },
  emailLink: {
    color: '#ff7e5f',
    textDecoration: 'none',
    transition: 'color 0.3s ease',
    ':hover': {
      color: '#feb47b',
    },
  },
};

export default PrivacyPolicy;
