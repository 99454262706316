import React from 'react';
import { Box, Typography, useTheme, useMediaQuery } from '@mui/material';

function AboutUs() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  
  return (
    <section id="about" style={{ 
		marginTop: '250px',
        padding: '4rem 0', 
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        paddingBottom: matches ? '10rem' : '4rem', // Add dynamic bottom padding
    }}>
      <Box sx={{
        maxWidth: 960,
        margin: 'auto',
        padding: '0 16px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(4),
        transform: matches ? 'translateY(-50%)' : 'none', 
      }}>
        {/* Title */}
        <Typography variant="h4" align="center" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
          About ProlifiTech Incorporated
        </Typography>
        
        {/* Styled Paragraphs */}
        <Typography paragraph sx={{ textIndent: '2rem', marginBottom: 2, textAlign: 'justify', fontFamily: "'Roboto Slab', serif", fontSize: matches ? '1rem' : '0.8rem' }}>
          At <strong>ProlifiTech Incorporated</strong>, our journey began with a visionary ambition: to democratize the digital landscape for businesses of all sizes. We recognized a pressing need — the need for businesses to transition from manual, labor-intensive operations to streamlined, automated, and data-driven processes. The digital frontier is vast and, at times, daunting. Our mission was clear: devise a strategy that not only simplifies this transition but also makes it cost-effective.
        </Typography>
        
        <Typography paragraph sx={{ textIndent: '2rem', marginBottom: 2, textAlign: 'justify', fontFamily: "'Roboto Slab', serif", fontSize: matches ? '1rem' : '0.8rem' }}>
          Since our inception, we have stood by countless businesses as they navigated their way through digital evolution. Our role has been pivotal — enabling our clients to not just understand their data better but to liberate them from the time-consuming tasks of data collection, spreadsheet creation, and complex analysis. The result? Our clients have discovered newfound efficiencies, unlocking time to focus more on what truly matters — innovation and growth.
        </Typography>
        
        <Typography paragraph sx={{ textIndent: '2rem', marginBottom: 2, textAlign: 'justify', fontFamily: "'Roboto Slab', serif", fontSize: matches ? '1rem' : '0.8rem' }}>
          Unlike the one-size-fits-all model prevalent in the industry, we at ProlifiTech champion a bespoke approach. We understand that each business is unique, characterized by its specific processes and challenges. That's why we engage directly with you, tailoring every solution to fit your distinctive needs seamlessly. Our belief is unwavering: when technology is applied thoughtfully, it becomes a force multiplier for your business, enhancing your capabilities and setting the stage for unprecedented efficiency.
        </Typography>
        
        <Typography paragraph sx={{ textIndent: '2rem', marginBottom: 2, textAlign: 'justify', fontFamily: "'Roboto Slab', serif", fontSize: matches ? '1rem' : '0.8rem' }}>
          Our goal is unambiguous. We strive to empower as many businesses as possible — whether big, small, or in between — to thrive in this technologically-centric era. Through affordability and customization, ProlifiTech aims to be your ally, guiding you through the digital metamorphosis with ease and precision. Welcome to ProlifiTech. Let's navigate the future of your business, together.
        </Typography>
        
      </Box>
    </section>
  );
}

export default AboutUs;