import React from 'react';
import logo from './assets/ProlifiTech-P.svg'; // Adjust the path to your logo file
import styles from './LoadingIndicator.module.css'; // Adjust the path to your CSS module

function LoadingIndicator() {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.logoWrapper}>
        <img src={logo} alt="Loading..." className={styles.logo} />
        <div className={styles.spinner}></div>
      </div>
    </div>
  );
}

export default LoadingIndicator;