//WeeklySalesReport.js   This is the front end file
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './WeeklySalesReport.module.css';
import { WeeklySalesTable } from './WeeklySalesTable';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import { WeeklyProfitabilityTable } from './WeeklyProfitabilityTable';
import { WeeklyServiceTable } from './WeeklyServiceTable';
import { aggregateDataForWeekGroups } from './aggregateWeekGroupData';
import exportToExcel from './exportToExcel';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';


function getMondayOfISOWeek(week, year) {
  const simple = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7));
  const dayOfWeek = simple.getUTCDay();
  const ISOweekStart = simple;
  if (dayOfWeek <= 4) {
    ISOweekStart.setUTCDate(simple.getUTCDate() - simple.getUTCDay() + 1);
  } else {
    ISOweekStart.setUTCDate(simple.getUTCDate() + 8 - simple.getUTCDay());
  }
  return ISOweekStart;
}

function getBaseUrl() {
  if (process.env.NODE_ENV === 'development') {
    return 'http://72.167.34.236:5000';
  }
  return 'https://prolifi.app';
}

const WeeklySalesReport = () => {
  const [salesData, setSalesData] = useState(null);
  const [missingSalesData, setMissingSalesData] = useState({});
  
  
 
  const [isMissingSalesDataExpanded, setIsMissingSalesDataExpanded] = useState(false);
  const [isWeeklySalesExpanded, setIsWeeklySalesExpanded] = useState(false);
  const [isWeeklyProfitabilityExpanded, setIsWeeklyProfitabilityExpanded] = useState(false);
  const [isWeeklyServiceExpanded, setIsWeeklyServiceExpanded] = useState(false);
 
  

  
  // State for the selected week and year
  const [selectedWeek, setSelectedWeek] = useState();
  const [selectedYear, setSelectedYear] = useState();

  function getDefaultWeekYear() {
    const today = new Date();
    const startOfYear = new Date(Date.UTC(today.getFullYear(), 0, 1));
    const daysSinceStartOfYear = (today - startOfYear) / (24 * 60 * 60 * 1000);
    const weekNo = Math.ceil((daysSinceStartOfYear + startOfYear.getUTCDay() + 1) / 7);
    let year = today.getFullYear();

    // Adjust for the previous week
    let week = weekNo - 1;
    // If the adjustment makes the week number 0, we should change it to the last week of the previous year
    if (week < 1) {
      year -= 1; // Go to the previous year
      week = 52; // Assume 52, but we should check if the last year had 53 weeks
      if (new Date(year, 11, 31).getUTCDay() === 4 || (new Date(year, 11, 31).getUTCDay() === 3 && isLeapYear(year))) {
        week = 53; // The previous year had 53 weeks
      }
    }

    return { week, year };
  }

  // Check if a year is a leap year
  function isLeapYear(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }
  
  useEffect(() => {
    const { week, year } = getDefaultWeekYear();
    setSelectedWeek(week);
    setSelectedYear(year);
  }, []);

  useEffect(() => {
    if (selectedWeek != null && selectedYear != null) {
      const monday = getMondayOfISOWeek(selectedWeek, selectedYear);
      const sunday = new Date(monday);
      sunday.setDate(monday.getDate() + 6);
      const startDate = monday.toISOString().split('T')[0];
      const endDate = sunday.toISOString().split('T')[0];
      const baseUrl = getBaseUrl();

      axios.get(`${baseUrl}/api/weekly-sales/${startDate}/${endDate}`, {
        withCredentials: true
      })
      .then(response => {
        setSalesData(response.data);
        setMissingSalesData(response.data.missingStoresWithSupervisors);
        console.log('Sales Data:', response.data);
      })
      .catch(error => {
        console.error('Error fetching sales data:', error);
      });
    }
  }, [selectedWeek, selectedYear]);

  const handleWeekYearChange = (event) => {
    const [week, year] = event.target.value.split('-');
    setSelectedWeek(parseInt(week, 10));
    setSelectedYear(parseInt(year, 10));
  };

  const generateWeekOptions = () => {
    const today = new Date();
    const currentYear = today.getUTCFullYear();
    const options = [];

    // Next week (might be next year)
    const { week: nextWeek, year: nextYear } = getDefaultWeekYear();
    options.push({
      weekNumber: nextWeek,
      year: nextYear,
      dateRange: formatDateRange(getMondayOfISOWeek(nextWeek, nextYear))
    });

    // Last 52 weeks (including this week)
    for (let i = 0; i < 52; i++) {
      const weekNumber = nextWeek - i - 1 <= 0 ? 52 + (nextWeek - i - 1) : nextWeek - i - 1;
      const year = nextWeek - i - 1 <= 0 ? nextYear - 1 : currentYear;
      options.push({
        weekNumber,
        year,
        dateRange: formatDateRange(getMondayOfISOWeek(weekNumber, year))
      });
    }

    return options;
  };

const formatDateRange = (monday) => {
  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);
  
  return `${(monday.getUTCMonth() + 1).toString().padStart(2, '0')}-${monday.getUTCDate().toString().padStart(2, '0')}-${monday.getUTCFullYear()}` +
    ` to ` +
    `${(sunday.getUTCMonth() + 1).toString().padStart(2, '0')}-${sunday.getUTCDate().toString().padStart(2, '0')}-${sunday.getUTCFullYear()}`;
}

const handleMissingSalesDataToggle = () => {
    setIsMissingSalesDataExpanded(!isMissingSalesDataExpanded);
    setIsWeeklySalesExpanded(false);
    setIsWeeklyProfitabilityExpanded(false);
    setIsWeeklyServiceExpanded(false);
};

const handleWeeklySalesToggle = () => {
    setIsMissingSalesDataExpanded(false);
    setIsWeeklySalesExpanded(!isWeeklySalesExpanded);
    setIsWeeklyProfitabilityExpanded(false);
    setIsWeeklyServiceExpanded(false);
};
  
const handleWeeklyProfitabilityToggle = () => {
    setIsMissingSalesDataExpanded(false);
    setIsWeeklySalesExpanded(false);
    setIsWeeklyProfitabilityExpanded(!isWeeklyProfitabilityExpanded);
    setIsWeeklyServiceExpanded(false);
};
  
const handleWeeklyServiceToggle = () => {
    setIsMissingSalesDataExpanded(false);
    setIsWeeklySalesExpanded(false);
    setIsWeeklyProfitabilityExpanded(false);
    setIsWeeklyServiceExpanded(!isWeeklyServiceExpanded);
};



  const renderToggleBar = (handleToggle, isExpanded, text) => (
    <div className={`${styles.toggleBar}`} onClick={handleToggle} role="button" tabIndex="0">
      {text} {isExpanded ? <FaAngleUp /> : <FaAngleDown />}
    </div>
  );
  

const formatDate = (date) => {
  // Formats the date to 'MM-DD-YYYY'
  return `${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}-${date.getUTCFullYear()}`;
};

const handleExport = () => {
  if (salesData && salesData.weeklySalesData) {
    // Transform the object data into an array
    const weeklySalesArray = Object.keys(salesData.weeklySalesData).map(storeId => {
      return {
        storeId: storeId,
        ...salesData.weeklySalesData[storeId]
      };
    });

    // Calculate the start (Monday) and end (Sunday) dates of the selected week
    const monday = getMondayOfISOWeek(selectedWeek, selectedYear);
    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6); // Add 6 days to get to Sunday

    // Format start and end dates
    const startDate = formatDate(monday); // Should output '01-08-2024' for January 8, 2024
    const endDate = formatDate(sunday);   // Should output '01-14-2024' for January 14, 2024

   const aggregatedWeekGroupData = aggregateDataForWeekGroups(weeklySalesArray);
    console.log(aggregatedWeekGroupData);
	const aoaAggregatedWeekGroupData = convertAggregatedDataToAoa(aggregatedWeekGroupData);
    console.log(aoaAggregatedWeekGroupData);

    // Pass formatted dates and sales data array to the export function
    exportToExcel(weeklySalesArray, aoaAggregatedWeekGroupData, startDate, endDate, selectedWeek, selectedYear);
  } else {
    // Handle the case where there is no data to export
    console.error('Weekly sales data is not available for export.');
  }
};

const sortOrder = ["NWA", "CENTRAL AR", "TENNESSEE", "ST LOUIS", "CHAMPAIGN", "NEBRASKA", "KANSAS"];

// Function to sort the aggregated data according to the specified sortOrder
const sortData = (data) => {
  return data.sort((a, b) => {
    const indexA = sortOrder.indexOf(a[2]); // Get the sort order index of the 3rd entry in array a
    const indexB = sortOrder.indexOf(b[2]); // Get the sort order index of the 3rd entry in array b
    return indexA - indexB;
  });
};

const convertAggregatedDataToAoa = (aggregatedData) => {
  // Convert the object into an array of arrays suitable for aoa_to_sheet
  let aoa = Object.entries(aggregatedData).map(([weekGroup, data]) => {
    return [
      '', // Empty
      '', // Empty
      weekGroup,
      data.groupNetSalesThisYear,
      data.groupNetSalesLastYear,
      data.percentDifferenceSales,
      data.mgrLaborP,
      data.crewLaborP,
      data.totalLaborP,
      data.groupTicketsThisYear,
      data.groupTicketsLastYear,
      data.percentDifferenceTickets,
      data.ticketAverage,
      data.onTimePercent,
    ];
  });

  // Sort the array of arrays based on the sortOrder
  aoa = sortData(aoa);

  // We return the sorted array of arrays representing the rows
  return aoa;
};


//console.log(aoa);

// ... (the rest of your WeeklySalesReport component)

  const weekOptions = generateWeekOptions();

return (
  <div className={styles.scrollableTableContainer}>
    <div className={styles.weekSelector}>
      <label htmlFor="weekSelect">Select Week: </label>
<div className={styles.customDropdown}>
  <select id="weekSelect" value={`${selectedWeek}-${selectedYear}`} onChange={handleWeekYearChange}>
    {weekOptions.map(({ weekNumber, year, dateRange }) => (
      <option key={`${weekNumber}-${year}`} value={`${weekNumber}-${year}`}>
        Week {weekNumber}: {dateRange}
      </option>
    ))}
  </select>
  {/* Apply the spacing class to the button */}
  <Button
    variant="contained"
    color="primary"
    startIcon={<GetAppIcon />}
    onClick={handleExport}
    className={styles.buttonSpacing}  // This adds the margin to the left of the button
  >
    Download Weekly Report
  </Button>
</div>
    </div>
    <div className={styles.container}>
      {/* Card for Missing Sales Data */}
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          {renderToggleBar(handleMissingSalesDataToggle, isMissingSalesDataExpanded, 'Missing Sales Data')}
        </div>
        {isMissingSalesDataExpanded && (
          <table className={styles.missingSalesDataTable}>
            <thead>
              <tr>
                <th>Supervisor Name</th>
                <th>Stores Missing Data</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(missingSalesData).map(([supervisor, stores], index) => (
                <tr key={index}>
                  <td>{supervisor}</td>
                  <td>{stores.join(', ')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Card for Weekly Sales */}
      <div className={styles.card}>
        <div className={styles.cardHeader}>
          {renderToggleBar(handleWeeklySalesToggle, isWeeklySalesExpanded, 'Weekly Sales')}
        </div>
        {isWeeklySalesExpanded && salesData && (
          <WeeklySalesTable weeklySalesData={salesData.weeklySalesData} />
        )}
      </div>

      {/* New card for Weekly Profitability */}
		  <div className={styles.card}>
			<div className={styles.cardHeader}>
			  {renderToggleBar(handleWeeklyProfitabilityToggle, isWeeklyProfitabilityExpanded, 'Weekly Profitability')}
			</div>
			{isWeeklyProfitabilityExpanded && salesData && (
			  <WeeklyProfitabilityTable weeklyProfitabilityData={salesData.weeklySalesData} />
			)}
		  </div>
		  
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        {/* Toggle bar for Weekly Service section */}
        {renderToggleBar(handleWeeklyServiceToggle, isWeeklyServiceExpanded, 'Weekly Service')}
      </div>
      {isWeeklyServiceExpanded && salesData && (
        <WeeklyServiceTable weeklyServiceData={salesData.weeklySalesData} />
      )}
    </div>
  
    </div>
  </div>
);
};

export default WeeklySalesReport;