import React, { useState } from 'react';
import './ConverterInstructions.css'; // Custom styles for instructions

const ConverterInstructions = () => {
  const [openSection, setOpenSection] = useState(null); // Track which section is open

  // Toggle the sections: only one should be open at a time
  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <div className="instructions-container">
      <h3>Converter Instructions</h3>
      <ol>
        {/* Section 1 */}
        <li>
          <div className="section-header" onClick={() => toggleSection(1)}>
            <strong>Section 1: Inventory Xpress Setup {openSection === 1 ? '▲' : '▼'}</strong>
          </div>
          {openSection === 1 && (
            <div className="section-content">
              <ol>
                <li>
                  Navigate to{' '}
                  <a
                    href="https://online.inventoryxpress.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Inventory Xpress
                  </a>{' '}
                  (This will open in a new window/tab).
                </li>
                <li>Create your Inventory Xpress Order.</li>
                <li>
                  Once the order loads, you can click 'Custom Settings' to adjust your 'On hand buffer' or 'Days to be Closed' options if needed.
                </li>
                <li>
                  Make adjustments to your order by selecting different sections. To order more or less of an item, change the number of cases in the 'Quantity To Order' column.
                </li>
                <li>
                  When you’re finished, click the 'Export' button to export the order in the correct format.
                </li>
                <p>
                  The file will download and be named something like:{' '}
                  <em>'Reinhart_2410-48thSt_Order_2024-09-07'</em>.
                </p>
              </ol>
            </div>
          )}
        </li>

        {/* Section 6 */}
        <li>
          <div className="section-header" onClick={() => toggleSection(6)}>
            <strong>Section 2: Prolifi Truck Converter {openSection === 6 ? '▲' : '▼'}</strong>
          </div>
          {openSection === 6 && (
            <div className="section-content">
              <ol>
                <li>Return to the prolifi.app Truck Converter.</li>
                <li>
                  Click 'Browse' to open the file dialog, find the IX file you just created, select it, and click 'Open'.
                </li>
                <li>
                  The 'Convert' button should now be activated and green. Click it to convert the file. The converted file will automatically download, and its name will look something like{' '}
                  <em>'ProlifiTruck_2024-09-07_090600.csv'</em>.
                </li>
              </ol>
            </div>
          )}
        </li>

        {/* Section 9 */}
        <li>
          <div className="section-header" onClick={() => toggleSection(9)}>
            <strong>Section 3: Upload to CustomerFirst {openSection === 9 ? '▲' : '▼'}</strong>
          </div>
          {openSection === 9 && (
            <div className="section-content">
              <ol>
                <li>
                  Navigate to{' '}
                  <a
                    href="https://www.customerfirstsolutions.com/?bu=performance"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PFG Website
                  </a>{' '}
                  (This will open in a new window/tab).
                </li>
                <li>
                  Log in and click 'Orders' at the top of the screen. This will drop down a menu where you need to select 'Import Order'.
                </li>
                <li>
                  On the right side of this page, you'll see 'Upload file'. Click the 'Choose file' button below that.
                </li>
                <li>
                  This will open the file upload dialog. Find the ProlifiTech Converted file you downloaded, select it, and click 'Open'.
                </li>
                <li>
                  If there are any issues with adding items to your order during the upload process, PFG will show an 'Important failures' warning.
                  <br />
                  <strong>Note:</strong> If you encounter any issues, note the error and inform your supervisor to get the item updated and prevent further errors.
                </li>
                <li>
                  The 'Order Details' dialog will open. Here, you can set the delivery date and give the order a name (e.g., ProlifiTech Rocks!).
                </li>
                <li>Click 'Create Order'.</li>
                <li>
                  The order will process, and all your items should be added to your 'Order Review' page.
                </li>
                <li>
                  On the 'Order Review' page, you can review the items and cases added to your order. The 'Did you forget?' tool will help identify items not currently in your order but might be needed.
                </li>
                <li>
                  Once you're satisfied with your order, click 'Submit Order' on the right-hand side to complete your truck order.
                </li>
              </ol>
            </div>
          )}
        </li>
      </ol>
    </div>
  );
};

export default ConverterInstructions;
