// createSalesReportSheet.js

const calculatePercentChange = (current, lastYear) => {
  if (lastYear === 0) {
    return 'N/A';  // Avoid division by zero and indicate not applicable or return a suitable value
  }
  return ((current - lastYear) / lastYear * 100).toFixed(2);
};

const calculateLaborPercent = (labor, netSales) => {
  if (netSales === 0) {
    return 0;  // Avoid division by zero
  }
  return (labor / netSales * 100).toFixed(2);
};

const createSalesReportSheet = (weeklySalesArray, aoaAggregatedWeekGroupData, startDate, endDate) => {
  let headers = [
    ['', 'Weekly Sales Report'],
    ['', `Sales for the week of ${startDate} to ${endDate}`],
    [], // Empty row
    ['','','','Net Sales','','','Labor (%)','','','Tickets','','','Ticket','On Time'],
    ['', '', '', 'This Year', 'Last Year', '% +/-', 'Mgr', 'Crew', 'Total', 'This Year', 'Last Year', '% +/-', 'Average', '%'],
  ];
  
  
  
  const sortedWeeklySalesArray = weeklySalesArray.sort((a, b) => {
    // Convert to numbers and subtract to get the sort order
    return parseFloat(b.currentWeek.netSales) - parseFloat(a.currentWeek.netSales);
  });

  // Then create the salesDataRows from the sorted array
  const salesDataRows = sortedWeeklySalesArray.map((storeData, index) => [
    index + 1,
    storeData.supervisorName,
    storeData.storeName,
    storeData.currentWeek.netSales || 0,
    storeData.lastYearSameWeek.netSales || 0,
    calculatePercentChange(parseFloat(storeData.currentWeek.netSales), parseFloat(storeData.lastYearSameWeek.netSales)),
    calculateLaborPercent(storeData.currentWeek.managerLabor, parseFloat(storeData.currentWeek.netSales)),
    calculateLaborPercent(storeData.currentWeek.labor, parseFloat(storeData.currentWeek.netSales)),
    (parseFloat(calculateLaborPercent(storeData.currentWeek.managerLabor, parseFloat(storeData.currentWeek.netSales))) +
     parseFloat(calculateLaborPercent(storeData.currentWeek.labor, parseFloat(storeData.currentWeek.netSales)))).toFixed(2),
    storeData.currentWeek.tickets || 0,
    storeData.lastYearSameWeek.tickets || 0,
    calculatePercentChange(parseFloat(storeData.currentWeek.tickets), parseFloat(storeData.lastYearSameWeek.tickets)),
    (storeData.currentWeek.tickets > 0) ? (parseFloat(storeData.currentWeek.netSales) / parseFloat(storeData.currentWeek.tickets)).toFixed(2) : '0',
    (storeData.currentWeek.tickets > 0) ? (parseFloat(storeData.currentWeek.ontimeTickets) / parseFloat(storeData.currentWeek.tickets) * 100).toFixed(2) : '0',
  ]);

  // Calculate averages and sums for net sales, manager labor, crew labor, tickets, and on-time tickets
const sumsAndCounts = salesDataRows.reduce((acc, row) => {
  const netSalesThisYear = parseFloat(row[3]);
  const netSalesLastYear = parseFloat(row[4]);
  const ticketsThisYear = parseFloat(row[9]);
  const ticketsLastYear = parseFloat(row[10]);

  return {
    netSalesThisYear: acc.netSalesThisYear + (netSalesThisYear || 0),
    netSalesLastYearCount: netSalesLastYear ? acc.netSalesLastYearCount + 1 : acc.netSalesLastYearCount,
    netSalesLastYear: acc.netSalesLastYear + (netSalesLastYear || 0),
    managerLabor: acc.managerLabor + (parseFloat(row[6]) || 0),
    crewLabor: acc.crewLabor + (parseFloat(row[7]) || 0),
    ticketsThisYear: acc.ticketsThisYear + (ticketsThisYear || 0),
    ticketsThisYearCount: ticketsThisYear ? acc.ticketsThisYearCount + 1 : acc.ticketsThisYearCount,
    ticketsLastYear: acc.ticketsLastYear + (ticketsLastYear || 0),
    ticketsLastYearCount: ticketsLastYear ? acc.ticketsLastYearCount + 1 : acc.ticketsLastYearCount,
    onTimeTickets: acc.onTimeTickets + (parseFloat(row[13]) * ticketsThisYear / 100 || 0),
    ticketAverageSum: ticketsThisYear ? acc.ticketAverageSum + (netSalesThisYear / ticketsThisYear) : acc.ticketAverageSum,
    count: acc.count + 1,
  };
}, {
  netSalesThisYear: 0, netSalesLastYear: 0, netSalesLastYearCount: 0, managerLabor: 0, crewLabor: 0,
  ticketsThisYear: 0, ticketsThisYearCount: 0, ticketsLastYear: 0, ticketsLastYearCount: 0, onTimeTickets: 0, ticketAverageSum: 0, count: 0
});

const averages = {
  netSalesThisYearAvg: (sumsAndCounts.netSalesThisYear / sumsAndCounts.count).toFixed(2),
  netSalesLastYearAvg: (sumsAndCounts.netSalesLastYear / sumsAndCounts.netSalesLastYearCount).toFixed(2),
  ticketsThisYearAvg: (sumsAndCounts.ticketsThisYear / sumsAndCounts.ticketsThisYearCount).toFixed(2),
  ticketsLastYearAvg: (sumsAndCounts.ticketsLastYear / sumsAndCounts.ticketsLastYearCount).toFixed(2),
  mgrLaborAvg: (sumsAndCounts.managerLabor / sumsAndCounts.count).toFixed(2),
  crewLaborAvg: (sumsAndCounts.crewLabor / sumsAndCounts.count).toFixed(2),
  ticketAverage: (sumsAndCounts.ticketAverageSum / sumsAndCounts.ticketsThisYearCount).toFixed(2),
};

// Construct the Averages row for the worksheet
const averageRow = [
  '', '', 'AVERAGE:',
  averages.netSalesThisYearAvg, // Average net sales this year
  averages.netSalesLastYearAvg, // Average net sales last year
  calculatePercentChange(parseFloat(averages.netSalesThisYearAvg), parseFloat(averages.netSalesLastYearAvg)),
  averages.mgrLaborAvg,                                               // Average manager labor percent
  averages.crewLaborAvg,                                              // Average crew labor percent
  (parseFloat(averages.mgrLaborAvg) + parseFloat(averages.crewLaborAvg)).toFixed(2),                                                      // Average total labor percent
  averages.ticketsThisYearAvg,  // Average tickets this year
  averages.ticketsLastYearAvg,  // Average tickets last year
  calculatePercentChange(parseFloat(averages.ticketsThisYearAvg), parseFloat(averages.ticketsLastYearAvg)),
  averages.ticketAverage,
  ((sumsAndCounts.onTimeTickets / sumsAndCounts.ticketsThisYear) * 100).toFixed(2) // On-time percent average
];
  
    const sameStoreResultsSection = [
    [], // Empty row before the section starts
    ['', '', 'Same Store Results'], // Section header; this should be styled as bold later
    ...aoaAggregatedWeekGroupData // The actual aggregate data rows
  ];

  // Combining all the sections into the final worksheet data
  const worksheetData = [
    ...headers,
    ...salesDataRows, // Records for individual stores
    [], // Blank row after individual store records
    averageRow, // Averages of the individual-store data
    ...sameStoreResultsSection // Aggregate week group data
  ];

  return worksheetData;
};

export default createSalesReportSheet;