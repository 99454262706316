//workbookFormatter.js
import ExcelJS from 'exceljs';

/**
 * Applies formatting to a given worksheet object.
 * @param {ExcelJS.Worksheet} ws - The worksheet object to format.
 * @param {Array} kpiGoals - The array of KPI goals to be used for additional formatting.
 */
export function formatWorksheet(ws, kpiGoals) {
  // Merge cells A1 to F2
  ws.mergeCells('A1:F2');
  const a1Cell = ws.getCell('A1');
  a1Cell.value = 'KPI Standards';
  a1Cell.font = {
    name: 'Calibri',
    bold: true,
    size: 24,
  };
  a1Cell.alignment = {
    horizontal: 'center',
    vertical: 'center',
  };

  // Merge cells A to C on rows 3 to 11
  for (let row = 3; row <= 11; row++) {
    ws.mergeCells(`A${row}:C${row}`);
    const cell = ws.getCell(`A${row}`);
    cell.alignment = {
      horizontal: 'center',
      vertical: 'center',
    };
  }

  // Apply styles to KPI Titles in column A
  ws.eachRow((row, rowNumber) => {
    if (rowNumber >= 3) {
      const cell = row.getCell(1); // Column A
      if (cell.value && cell.value.toString().endsWith('*')) {
        cell.font = { bold: true };
      }
      cell.alignment = { horizontal: 'center', vertical: 'center' };
    }
  });

  // Define styles for the Green (D), Yellow (E), and Red (F) columns
  const greenColumnStyle = {
    font: { color: { argb: 'FFFFFFFF' }, bold: true },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF00FF00' } },
    alignment: { horizontal: 'center', vertical: 'center' },
  };

  const yellowColumnStyle = {
    font: { color: { argb: 'FF000000' } },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF00' } },
    alignment: { horizontal: 'center', vertical: 'center' },
  };

  const redColumnStyle = {
    font: { color: { argb: 'FFFFFFFF' }, bold: true },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFF0000' } },
    alignment: { horizontal: 'center', vertical: 'center' },
  };

  for (let row = 3; row <= 11; row++) {
    ws.getCell(`D${row}`).style = greenColumnStyle;
    ws.getCell(`E${row}`).style = yellowColumnStyle;
    ws.getCell(`F${row}`).style = redColumnStyle;
  }
  
    const storeHeaderRow = ws.getRow(12);
  storeHeaderRow.eachCell((cell) => {
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'center', vertical: 'center' };
  });

  console.log('Finished applying styles to cells');
}
