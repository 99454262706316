import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Tabs, Tab, Box, Checkbox, FormControlLabel, Button, Typography, LinearProgress, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Collapse
} from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import { CheckCircle, RadioButtonUnchecked } from '@mui/icons-material';
import moment from 'moment-timezone';
import styles from './ClosingCheckList.module.css';
import { getBaseUrl } from './utils/baseUrl';
import IncompleteItemsModal from './IncompleteItemsModal';
import ManagerWalkthroughModal from './ManagerWalkthroughModal';
import { debounce } from 'lodash';
import { getWebSocketUrl } from './utils/webSocketUrl';

const ClosingCheckList = ({ storeID }) => {
  const [activeSection, setActiveSection] = useState(0);
  const [sections, setSections] = useState({});
  const [filteredSections, setFilteredSections] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [incompleteItems, setIncompleteItems] = useState({});
  const [canSubmit, setCanSubmit] = useState(false);
  const [ws, setWs] = useState(null);
  const [securityCode, setSecurityCode] = useState('');
  const [securityCodeModalOpen, setSecurityCodeModalOpen] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [modalTaskName, setModalTaskName] = useState('');
  const [pendingCheck, setPendingCheck] = useState(null);
  const [currentHourGroup, setCurrentHourGroup] = useState('');
  const [isWithinAllowedTimeRange, setIsWithinAllowedTimeRange] = useState(false);

  const fetchCheckListItems = async () => {
    try {
      console.log("Fetching checklist items...");
      const response = await axios.get(`${getBaseUrl()}/api/closingLists/getProgress/${storeID}`);
      let savedProgress = response.data;

      if (Object.keys(savedProgress).length) {
        console.log("Using saved progress:", savedProgress);

        for (const section in savedProgress) {
          savedProgress[section] = await Promise.all(
            savedProgress[section].map(async (task) => {
              if (task.task.toLowerCase().includes('weekly cleaning items')) {
                console.log(`Weekly cleaning items task found in section: ${section}, fetching items...`);
                let weeklyCleaningItems = await fetchWeeklyCleaningItems(storeID, section);
                //console.log(`Weekly cleaning items fetched for section: ${section}`, weeklyCleaningItems);

                const today = moment().format('dddd');
                weeklyCleaningItems = weeklyCleaningItems.filter(item => item.dayOfWeek === today);
                //console.log(`Filtered weekly cleaning items for today (${today}):`, weeklyCleaningItems);

                return {
                  ...task,
                  weeklyItemsFetched: true,
                  weeklyItemsVisible: true,
                  weeklyItems: weeklyCleaningItems,
                };
              } else {
                return task;
              }
            })
          );
        }

        setSections(savedProgress);
      } else {
        //console.log("Fetching fresh checklist items...");
        const checklistResponse = await axios.get(`${getBaseUrl()}/api/closingLists/listGrab/${storeID}`);
        const data = checklistResponse.data;
        //console.log("Checklist items fetched:", data);

        const initializedSections = {};

        for (const section in data) {
          initializedSections[section] = await Promise.all(
            data[section].map(async (task) => {
             // console.log(`Processing task: ${task.task}`);
              if (task.task.toLowerCase().includes('weekly cleaning items')) {
              //  console.log(`Weekly cleaning items task found in section: ${section}, fetching items...`);
                let weeklyCleaningItems = await fetchWeeklyCleaningItems(storeID, section);
              //  console.log(`Weekly cleaning items fetched for section: ${section}`, weeklyCleaningItems);

                const today = moment().format('dddd');
                weeklyCleaningItems = weeklyCleaningItems.filter(item => item.dayOfWeek === today);
              //  console.log(`Filtered weekly cleaning items for today (${today}):`, weeklyCleaningItems);

                return {
                  ...task,
                  checked: false,
                  reason: '',
                  weeklyItemsFetched: true,
                  weeklyItemsVisible: true,
                  weeklyItems: weeklyCleaningItems,
                };
              } else {
                return {
                  ...task,
                  checked: false,
                  reason: '',
                  weeklyItemsFetched: false,
                  weeklyItemsVisible: false,
                };
              }
            })
          );
        }

        setSections(initializedSections);
        //console.log("Initialized sections with weekly items:", initializedSections);
      }
    } catch (error) {
      console.error('Error fetching checklist items:', error);
    }
  };

  const fetchWeeklyCleaningItems = async (storeId, section) => {
    try {
      //console.log(`Fetching weekly cleaning items for storeId: ${storeId}, section: ${section}...`);
      const response = await axios.get(`${getBaseUrl()}/api/closingLists/getWeeklyCleaningItems/${storeId}/${section}`);
      //console.log("Weekly cleaning items fetched:", response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching weekly cleaning items:', error);
      return [];
    }
  };

const filterSectionsByTime = (sections) => {
  const currentTime = moment().tz('America/Chicago');
  const currentHour = currentTime.hour();
  const currentMinute = currentTime.minute();

  // Check if the current time is between 5 PM and 3:30 AM
  const isWithinAllowedTimeRange = (
    (currentHour === 17 && currentMinute >= 0) || // 5:00 PM to 5:59 PM
    (currentHour >= 18 && currentHour <= 23) ||   // 6:00 PM to 11:59 PM
    (currentHour >= 0 && currentHour < 3) ||      // 12:00 AM to 2:59 AM
    (currentHour === 3 && currentMinute <= 30)    // 3:00 AM to 3:30 AM
  );

  if (!isWithinAllowedTimeRange) {
   // console.log("Current time is outside of allowed time range (5 PM - 3:30 AM). No tasks will be shown.");
    setFilteredSections({});
    return '';
  }

  // Calculate the hour group starting from 5 PM
  let hourGroup;
  if (currentHour >= 17) {
    hourGroup = currentHour - 17; // Starts at 5 PM
  } else {
    hourGroup = currentHour + 7; // Adjust for hours before 5 PM
  }

  const formattedHourGroup = moment().hour(hourGroup + 17).minute(0).format('h A');

  let lastHourGroup = 0;

  for (const section in sections) {
    sections[section].forEach(task => {
      if (task.group.startsWith('hour_')) {
        const taskHour = parseInt(task.group.replace('hour_', ''), 10);
        if (taskHour > lastHourGroup) {
          lastHourGroup = taskHour;
        }
      }
    });
  }

  const filtered = {};
  for (const section in sections) {
    filtered[section] = sections[section].map(task => {
      if (task.group.startsWith('end_of_shift')) {
        const isEndOfShiftVisible = hourGroup > lastHourGroup;
        return {
          ...task,
          isOverdue: false,
          isVisible: isEndOfShiftVisible,
        };
      } else {
        const taskHour = parseInt(task.group.replace('hour_', ''), 10);
        const isOverdueTask = taskHour < hourGroup && !task.checked;
        const isCurrentHourTask = taskHour === hourGroup;
        return {
          ...task,
          isOverdue: isOverdueTask,
          isVisible: isCurrentHourTask || isOverdueTask,
        };
      }
    });
  }

  setFilteredSections(filtered);
  return formattedHourGroup;
};

  useEffect(() => {
    fetchCheckListItems();

const connectWebSocket = () => {
  const websocketUrl = getWebSocketUrl();
  const websocket = new WebSocket(websocketUrl);

  websocket.onopen = () => {
    console.log('WebSocket connection opened');
  };

  websocket.onmessage = (event) => {
    try {
      const data = JSON.parse(event.data);
      const { storeID: receivedStoreID, progress } = data;

      if (receivedStoreID === storeID) {
        console.log("Received updated progress via WebSocket:", progress);
        setSections(progress);
        const formattedHourGroup = filterSectionsByTime(progress);
        setCurrentHourGroup(formattedHourGroup);
      }
    } catch (error) {
      console.error('Error parsing WebSocket message:', error);
    }
  };

  websocket.onclose = () => {
    console.log('WebSocket connection closed. Attempting to reconnect...');
    setTimeout(connectWebSocket, 3000);
  };

  websocket.onerror = (error) => {
    console.error('WebSocket encountered an error:', error);
    websocket.close();
  };

  setWs(websocket);
};

    connectWebSocket();

    return () => {
      if (ws) ws.close();
    };
  }, [storeID]);

  useEffect(() => {
    const formattedHourGroup = filterSectionsByTime(sections);
    setCurrentHourGroup(formattedHourGroup);
  }, [sections]);

  const handleCheckboxChange = async (sectionName, index) => {
    const sectionTasks = [...filteredSections[sectionName]];
    const task = sectionTasks[index];

    // Toggle the parent task checked state
    task.checked = !task.checked;

    setFilteredSections(prev => ({
      ...prev,
      [sectionName]: sectionTasks,
    }));

    const updatedSections = { ...sections };
    updatedSections[sectionName][index].checked = task.checked;

    // Save progress
    debouncedSaveProgress(updatedSections);
  };

  const handleModalCancel = () => {
    setPendingCheck(null);
    setModalOpen(false);
  };

  const handleModalConfirm = () => {
    if (pendingCheck) {
      const { sectionName, index } = pendingCheck;
      const updatedSections = { ...sections };
      updatedSections[sectionName][index].checked = true;

      setSections(updatedSections);
      debouncedSaveProgress(updatedSections);
      setPendingCheck(null);
    }
    setModalOpen(false);
  };

  const debouncedSaveProgress = debounce(async (sectionsToSave) => {
    try {
     // console.log("Saving progress:", sectionsToSave);
      await axios.post(`${getBaseUrl()}/api/closingLists/saveProgress/${storeID}`, { progress: sectionsToSave });
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ storeID, progress: sectionsToSave }));
      }
    } catch (error) {
      console.error('Error saving progress:', error);
    }
  }, 500, { leading: true });

  const validateSubmit = (items) => {
    const canSubmit = Object.keys(items).every(section =>
      items[section].every(item => item.checked || item.reason.trim() !== '')
    );
    setCanSubmit(canSubmit);
  };

  const handleSubmit = () => {
    const incomplete = {};
    Object.keys(sections).forEach(section => {
      const incompleteTasks = sections[section].filter(item => !item.checked);
      if (incompleteTasks.length > 0) {
        incomplete[section] = incompleteTasks;
      }
    });

    if (Object.keys(incomplete).length > 0) {
      setIncompleteItems(incomplete);
      validateSubmit(incomplete);
      setOpenDialog(true);
    } else {
      setSecurityCodeModalOpen(true);
    }
  };

  const handleSecurityCodeSubmit = async () => {
    if (!securityCode || securityCode.length !== 4) {
      setSubmissionError('Please enter a valid 4-digit security code.');
      return;
    }

    try {
      const response = await axios.post(`${getBaseUrl()}/api/closingLists/validateSecurityCode`, {
        storeID,
        securityCode
      });

      const { isValid, userID } = response.data;

      if (!isValid) {
        setSubmissionError('Invalid security code or insufficient permissions.');
        return;
      }

      handleFinalSubmit(userID);
    } catch (error) {
      setSubmissionError('Error during submission. Please try again.');
    }
  };

  const handleFinalSubmit = async (userID) => {
    if (canSubmit) {
      const updatedSections = { ...sections };

      Object.keys(incompleteItems).forEach(section => {
        incompleteItems[section].forEach((item, index) => {
          updatedSections[section] = updatedSections[section].map((task, i) =>
            i === index ? { ...task, checked: item.checked, reason: item.reason } : task
          );
        });
      });

      try {
        await axios.post(`${getBaseUrl()}/api/closingLists/submitChecklist`, {
          storeID,
          sections: updatedSections,
          userID
        });

        setSections({});
        setSecurityCode('');
        setIncompleteItems({});
        setSubmissionError(null);

        fetchCheckListItems();

        setSecurityCodeModalOpen(false);
      } catch (error) {
        setSubmissionError('Error during final submission. Please try again.');
      }
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleIncompleteCheckboxChange = (sectionName, index) => {
    const updatedIncompleteItems = { ...incompleteItems };
    updatedIncompleteItems[sectionName][index].checked = !updatedIncompleteItems[sectionName][index].checked;
    setIncompleteItems(updatedIncompleteItems);
    validateSubmit(updatedIncompleteItems);
  };

  const handleIncompleteReasonChange = (sectionName, index, value) => {
    const updatedIncompleteItems = { ...incompleteItems };
    updatedIncompleteItems[sectionName][index].reason = value;
    setIncompleteItems(updatedIncompleteItems);
    validateSubmit(updatedIncompleteItems);
  };

  const springProps = useSpring({
    opacity: 1,
    transform: 'translateY(0px)',
    from: { opacity: 0, transform: 'translateY(20px)' },
    config: { tension: 220, friction: 20 },
  });

  const getProgress = () => {
    const tasks = filteredSections[Object.keys(filteredSections)[activeSection]] || [];
    const completedTasks = tasks.filter(task => task.checked).length;
    return (completedTasks / tasks.length) * 100;
  };

  return (
    <div className={styles.closingCheckList}>
      <Typography variant="h4" className={styles.title}>
        Closing Check-List
      </Typography>
      <Typography variant="subtitle1" className={styles.storeId}>
        Store ID: {storeID} | Current Hour: {currentHourGroup}
      </Typography>

      {!isWithinAllowedTimeRange ? (
        <Typography variant="h6" color="textSecondary" className={styles.notice}>
          The system is waiting until 5 PM to display the Closing Check List.
        </Typography>
      ) : (
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={styles.tabContainer}>
            <Tabs
              value={activeSection}
              onChange={(e, newValue) => setActiveSection(newValue)}
              aria-label="Closing Check-list Sections"
              variant="scrollable"
              scrollButtons="auto"
              className={styles.tabs}
            >
              {Object.keys(filteredSections).map((section, index) => (
                <Tab
                  key={index}
                  label={section}
                  className={activeSection === index ? styles.activeTab : styles.inactiveTab}
                />
              ))}
            </Tabs>
          </Box>

          <LinearProgress variant="determinate" value={getProgress()} className={styles.progressBar} />

          <animated.div style={springProps}>
            <Box sx={{ p: 3 }} className={styles.taskContainer}>
              <ul className={styles.taskList}>
                {filteredSections[Object.keys(filteredSections)[activeSection]]?.map((item, index) => (
                  item.isVisible && (
                    <li
                      key={index}
                      className={`${styles.taskItem} ${item.weeklyItemsFetched ? styles.hasSubItems : ''} ${item.isOverdue ? styles.overdueTask : ''}`}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            icon={<RadioButtonUnchecked />}
                            checkedIcon={<CheckCircle />}
                            checked={item.checked}
                            onChange={() => handleCheckboxChange(Object.keys(filteredSections)[activeSection], index)}
                            className={styles.checkbox}
                          />
                        }
                        label={item.isOverdue ? `${item.task} (Overdue)` : item.task}
                        className={`${styles.taskLabel} ${item.isOverdue ? styles.overdueLabel : ''}`}
                      />
                      {item.weeklyItemsFetched && (
                        <Collapse in={item.weeklyItemsVisible}>
                          <ul className={styles.subTaskList}>
                            {item.weeklyItems.map((weeklyItem, subIndex) => (
                              <li key={subIndex} className={styles.subTaskItem}>
                                <Typography variant="body2" className={styles.subTaskLabel}>
                                  {weeklyItem.cleaningItem}
                                </Typography>
                              </li>
                            ))}
                          </ul>
                        </Collapse>
                      )}
                    </li>
                  )
                ))}
              </ul>
            </Box>
          </animated.div>

          <Button variant="contained" color="primary" className={styles.submitButton} onClick={handleSubmit}>
            Submit Check-List
          </Button>
        </>
      )}

      <IncompleteItemsModal
        open={openDialog}
        handleDialogClose={handleDialogClose}
        handleFinalSubmit={handleFinalSubmit}
        handleCheckboxChange={handleIncompleteCheckboxChange}
        handleReasonChange={handleIncompleteReasonChange}
        incompleteItems={incompleteItems}
        canSubmit={canSubmit}
      />

      <ManagerWalkthroughModal
        open={modalOpen}
        onClose={handleModalCancel}
        onConfirm={handleModalConfirm}
        taskName={modalTaskName}
      />

      <Dialog open={securityCodeModalOpen} onClose={() => setSecurityCodeModalOpen(false)}>
        <DialogTitle>Enter Security Code</DialogTitle>
        <DialogContent>
          <TextField
            label="4-Digit Security Code"
            value={securityCode}
            onChange={(e) => setSecurityCode(e.target.value)}
            error={!!submissionError}
            helperText={submissionError}
            type="password"
            inputProps={{ maxLength: 4 }}
            className={styles.securityCodeInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSecurityCodeSubmit} color="primary" variant="contained">
            Submit
          </Button>
          <Button onClick={() => setSecurityCodeModalOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClosingCheckList;
