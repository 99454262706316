import React, { useState, useEffect } from 'react';
import { Route, Link, Routes } from 'react-router-dom';
import styles from './MobileStoreDashboardLayout.module.css';
import MainView from './MainView'; // Your main view component
import BackUpCashCount from './BackUpCashCount'; // Your Back-Up Cash Count component
import CorrectionActionForm from './CorrectionActionForm'; // Your Correction Action Form component
import ClosingCheckList from './ClosingCheckList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faHouse, faMoneyBillWave, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';

const StoreDashboard = () => {
  const [storeID, setStoreID] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setStoreID('2410'); // Static ID for development
    setLoading(false);
  }, []);

  if (loading) return <div>Loading store information...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className={styles.mobileStoreDashboardLayout}>
      <nav className={styles.mobileNavigation}>
        <ul>
		  <li><Link to="/store/closing-check-list"><FontAwesomeIcon icon={faCheckSquare} /></Link></li>
          <li><Link to="/store"><FontAwesomeIcon icon={faHouse} /></Link></li>
          <li><Link to="/store/backup-cash-count"><FontAwesomeIcon icon={faMoneyBillWave} /></Link></li>
          <li><Link to="/store/correction-action"><FontAwesomeIcon icon={faExclamationTriangle} /></Link></li>
        </ul>
      </nav>
      <main className={styles.mobileMainContent}>
        <Routes>
          <Route path="backup-cash-count" element={<BackUpCashCount storeID={storeID} />} />
          <Route path="correction-action" element={<CorrectionActionForm />} />
		  <Route path="closing-check-list" element={<ClosingCheckList storeID={storeID} />} />
          <Route path="/" element={<MainView storeID={storeID} />} />
        </Routes>
      </main>
    </div>
  );
};

export default StoreDashboard;
