import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Checkbox, FormControlLabel, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { getBaseUrl } from './utils/baseUrl';

const ManagerWalkthroughModal = ({ open, onClose, onConfirm, taskName }) => {
  const [groupedCheckItems, setGroupedCheckItems] = useState({});
  const [expanded, setExpanded] = useState(false); // State to track which accordion is expanded
  const [checkedItems, setCheckedItems] = useState({}); // Track the state of each checkbox

  useEffect(() => {
    if (open) {
      const fetchCheckItems = async () => {
        try {
          const encodedTaskName = encodeURIComponent(taskName); // Encode the task name
          const response = await axios.get(`${getBaseUrl()}/api/closingLists/getStockCheckItems/${encodedTaskName}`);
          const items = response.data;

          // Group items by their area
          const grouped = items.reduce((acc, item) => {
            if (!acc[item.area]) {
              acc[item.area] = [];
            }
            acc[item.area].push(item.product);
            return acc;
          }, {});

          setGroupedCheckItems(grouped);

          // Initialize all checkboxes as unchecked
          const initialCheckedState = {};
          Object.keys(grouped).forEach(area => {
            grouped[area].forEach(product => {
              initialCheckedState[`${area}_${product}`] = false;
            });
          });
          setCheckedItems(initialCheckedState);
        } catch (error) {
          console.error('Error fetching stock check items:', error);
        }
      };

      fetchCheckItems();
    }
  }, [open, taskName]);

  const handleAccordionChange = (area) => (event, isExpanded) => {
    setExpanded(isExpanded ? area : false);
  };

  const handleCheckboxChange = (area, product) => (event) => {
    setCheckedItems(prevState => ({
      ...prevState,
      [`${area}_${product}`]: event.target.checked,
    }));
  };

  const allItemsChecked = Object.values(checkedItems).every(isChecked => isChecked);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{taskName} Checklist</DialogTitle>
      <DialogContent dividers>
        {Object.keys(groupedCheckItems).map((area, index) => (
          <Accordion
            key={index}
            expanded={expanded === area}
            onChange={handleAccordionChange(area)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h6">{area}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box display="flex" flexDirection="column">
                {groupedCheckItems[area].map((product, subIndex) => (
                  <Box key={subIndex} mb={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedItems[`${area}_${product}`] || false}
                          onChange={handleCheckboxChange(area, product)}
                        />
                      }
                      label={product}
                    />
                  </Box>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button 
          onClick={onConfirm} 
          color="primary" 
          variant="contained" 
          disabled={!allItemsChecked} // Disable the button if not all items are checked
        >
          Complete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManagerWalkthroughModal;
