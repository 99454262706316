// aggregateWeekGroupData.js

// Function to get unique weekGroups from the weeklySalesArray
const getUniqueWeekGroups = (salesData) => {
    const allWeekGroups = salesData.map(store => store.weekGroup);
    return [...new Set(allWeekGroups)];
};

// Helper function to calculate the percent difference
const calculatePercentDifference = (current, lastYear) => {
    if (lastYear === 0 || current === 0) {
        return 0; // Avoid division by zero
    }
    return ((current - lastYear) / lastYear * 100).toFixed(2);
};

// Helper function to calculate labor percentage
const calculateLaborPercentage = (labor, netSales) => {
    if (netSales === 0) {
        return 0; // Avoid division by zero
    }
    return (labor / netSales * 100).toFixed(2);
};

// Function to aggregate data for each weekGroup
const aggregateDataForWeekGroups = (salesData) => {
    const weekGroups = getUniqueWeekGroups(salesData);
    const aggregatedData = {};

   
    weekGroups.forEach(weekGroup => {
				const excludedStores = {
					'6841': '2025-02-01', // Example date format YYYY-MM-DD
					'1028': '2025-01-01',
				};
				const currentDate = new Date();	
				
        const groupData = salesData.filter(store => {
            return store.weekGroup === weekGroup && 
                   (!excludedStores[store.storeId] || 
                    currentDate >= new Date(excludedStores[store.storeId]));
        });
        
        let validStoreCount = 0; // Count of stores with valid sales and tickets

        const groupTotals = groupData.reduce((acc, store) => {
            const netSalesThisYear = parseFloat(store.currentWeek.netSales);
            const netSalesLastYear = parseFloat(store.lastYearSameWeek.netSales);
            const ticketsThisYear = parseInt(store.currentWeek.tickets, 10);
            const ticketsLastYear = parseInt(store.lastYearSameWeek.tickets, 10);
            const labor = parseFloat(store.currentWeek.labor);
            const ontimeTickets = parseFloat(store.currentWeek.ontimeTickets);

            // Only count the store if net sales and tickets are not 0
            if (netSalesThisYear !== 0 && ticketsThisYear !== 0) {
                acc.netSalesThisYear += netSalesThisYear;
                acc.netSalesLastYear += netSalesLastYear;
                acc.ticketsThisYear += ticketsThisYear;
                acc.ticketsLastYear += ticketsLastYear;
                acc.labor += labor;
                acc.ontimeTickets += ontimeTickets;
                validStoreCount++;
            }

            return acc;
        }, {
            netSalesThisYear: 0,
            netSalesLastYear: 0,
            ticketsThisYear: 0,
            ticketsLastYear: 0,
            ontimeTickets: 0,
            labor: 0,
        });

        // Calculate the averages for the group
        const avgNetSalesThisYear = validStoreCount > 0 ? (groupTotals.netSalesThisYear / validStoreCount).toFixed(2) : '0';
        const avgNetSalesLastYear = validStoreCount > 0 ? (groupTotals.netSalesLastYear / validStoreCount).toFixed(2) : '0';
        const avgTicketsThisYear = validStoreCount > 0 ? (groupTotals.ticketsThisYear / validStoreCount).toFixed(2) : '0';
        const avgTicketsLastYear = validStoreCount > 0 ? (groupTotals.ticketsLastYear / validStoreCount).toFixed(2) : '0';
        const avgOnTimeTickets = validStoreCount > 0 ? (groupTotals.ontimeTickets / validStoreCount).toFixed(2) : '0';

        // Calculate labor values
        const mgrLaborD = 960; // Fixed manager labor per store
        const crewLaborD = validStoreCount > 0 ? (groupTotals.labor / validStoreCount).toFixed(2) : '0';
        const totalLabor = (mgrLaborD + parseFloat(crewLaborD)).toFixed(2);

        // Assign calculations to the group
        aggregatedData[weekGroup] = {
            groupNetSalesThisYear: avgNetSalesThisYear,
            groupNetSalesLastYear: avgNetSalesLastYear,
            percentDifferenceSales: calculatePercentDifference(parseFloat(avgNetSalesThisYear), parseFloat(avgNetSalesLastYear)),
            mgrLaborD: mgrLaborD,
            crewLaborD: crewLaborD,
            totalLabor: totalLabor,
            mgrLaborP: calculateLaborPercentage(mgrLaborD, parseFloat(avgNetSalesThisYear)),
            crewLaborP: calculateLaborPercentage(parseFloat(crewLaborD), parseFloat(avgNetSalesThisYear)),
            totalLaborP: calculateLaborPercentage(parseFloat(totalLabor), parseFloat(avgNetSalesThisYear)),
            groupTicketsThisYear: avgTicketsThisYear,
            groupTicketsLastYear: avgTicketsLastYear,
            percentDifferenceTickets: calculatePercentDifference(parseInt(avgTicketsThisYear, 10), parseInt(avgTicketsLastYear, 10)),
            ticketAverage: parseFloat(avgTicketsThisYear) > 0 ? (parseFloat(avgNetSalesThisYear) / parseFloat(avgTicketsThisYear)).toFixed(2) : '0',
            groupOnTimeTickets: avgOnTimeTickets,
            onTimePercent: parseFloat(avgTicketsThisYear) > 0 ? (parseFloat(avgOnTimeTickets) / parseFloat(avgTicketsThisYear) * 100).toFixed(2) : '0',
        };
    });

    return aggregatedData;
};

export { aggregateDataForWeekGroups };