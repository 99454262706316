import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';

// Consider importing icons or images representing each service
import WebIcon from '@mui/icons-material/Web';
import AnalyticsIcon from '@mui/icons-material/Assessment';
import CloudIcon from '@mui/icons-material/Cloud';
import SecurityIcon from '@mui/icons-material/Security';
import TransformationIcon from '@mui/icons-material/Autorenew';
import MobileIcon from '@mui/icons-material/PhoneIphone';

// Array updated to include icons, ensure you import or define these icons/images
const servicesArray = [
  { title: "Web Application Development", description: "Crafting high-performance, user-centric web applications that are custom-built to meet your unique business objectives.", icon: <WebIcon /> },
  { title: "Data Analytics & Insights", description: "Harness the power of your data with bespoke analytics solutions, designed to empower your decision-making with actionable insights.", icon: <AnalyticsIcon /> },
  { title: "Cloud Computing Solutions", description: "Offering scalable, secure, and resilient cloud services tailored to support your business growth, from seamless migration to end-to-end cloud management.", icon: <CloudIcon /> },
  { title: "Cybersecurity Solutions", description: "Safeguarding your digital presence with state-of-the-art cybersecurity services that are tailor-made to mitigate risks and protect your assets.", icon: <SecurityIcon /> },
  { title: "Digital Transformation Strategies", description: "Guiding your digital transformation journey with expert consulting and comprehensive implementation services, to keep you ahead in a digital-first world.", icon: <TransformationIcon /> },
  { title: "Mobile App Development", description: "Building captivating, smooth, and responsive mobile applications for iOS and Android that engage your audience anytime, anywhere.", icon: <MobileIcon /> }
];

function Services() {
  return (
    <section id="services" style={{ backgroundColor: '#f5f5f5', padding: '4rem 0' }}>
      <Typography variant="h3" align="center" gutterBottom>
        Our Services
      </Typography>
      <Grid container spacing={4} justifyContent="center"> {/* Ensure proper alignment */}
        {servicesArray.map((service, index) => (
          <Grid 
            item 
            xs={12} 
            sm={6} 
            md={4} 
            key={index} 
            display="flex" // This ensures flex container properties are applied
            justifyContent="center" // Centers the card horizontally in its grid cell
          >
            <Card sx={{ maxWidth: 345, ':hover': { transform: 'scale(1.05)', transition: 'transform .3s' } }}>
              <CardContent sx={{ textAlign: 'center' }}> {/* Center-aligns the CardContent */}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '8px' }}>
                  {service.icon}
                  <Typography variant="h5" component="h2" sx={{ marginLeft: '8px' }}>
                    {service.title}
                  </Typography>
                </Box>
                <Typography>
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </section>
  );
}

export default Services;