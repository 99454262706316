import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useTable, useSortBy } from 'react-table';
import moment from 'moment';
import styles from './EmployeeAnalyzer.module.css'; // Ensure this file exists and contains your CSS styles

const EmployeeDataViewer = () => {
  // Toggle for 'Shift Affinity' option
  const shiftAffinityEnabled = false; // Set to true to enable, false to disable

  const [userGroup, setUserGroup] = useState('');
  const [userStore, setUserStore] = useState('');
  const [supervisedStores, setSupervisedStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [analysisType, setAnalysisType] = useState(shiftAffinityEnabled ? 'Shift Affinity' : 'Fraud Prevention');
  const [shiftSize, setShiftSize] = useState(3);
  const [overallData, setOverallData] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [employeeSpecificData, setEmployeeSpecificData] = useState([]);

  const excludedEmployees = ['Mary Gappa', 'Jalen Wachter-Bluford'];

  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === '72.167.34.236') {
      return 'http://72.167.34.236:5000';
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return 'https://prolifi.app';
    } else {
      console.error('Unknown host');
      return ''; 
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const baseUrl = getBaseUrl();
        const response = await axios.get(`${baseUrl}/api/user-store`, { withCredentials: true });
        const { userGroup, userStore } = response.data;

        setUserGroup(userGroup);
        setUserStore(userStore);
        setSelectedStore(userStore);

        if (userGroup === 'supervisor' || userGroup === 'director') {
          const storesResponse = await axios.get(`${baseUrl}/api/supervised-stores`, { withCredentials: true });
          setSupervisedStores(storesResponse.data);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleStoreChange = (e) => {
    setSelectedStore(e.target.value);
  };

  const handleTypeChange = (e) => {
    setAnalysisType(e.target.value);
  };

  const handleShiftSizeChange = (e) => {
    setShiftSize(Number(e.target.value));
  };

  const handleFetchShifts = async () => {
    const baseUrl = getBaseUrl();
    try {
      let response;
      if (analysisType === 'Fraud Prevention') {
        response = await axios.get(`${baseUrl}/api/store/fraud-prevention`, {
          params: {
            storeId: selectedStore,
            startDate,
            endDate,
          },
          withCredentials: true
        });
        setOverallData(response.data.overallResults);
        setDailyData(response.data.dailyResults);
      } else {
        response = await axios.get(`${baseUrl}/api/store/store-shifts`, {
          params: {
            storeId: selectedStore,
            startDate,
            endDate,
            shiftSize 
          },
          withCredentials: true
        });
        console.log(response.data); 
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const columnsOverall = useMemo(() => [
    {
      Header: 'Employee',
      accessor: 'employee',
    },
    {
      Header: 'PFS',
      accessor: 'prolifiFraudScore',
    },
    {
      Header: 'Discount %',
      accessor: 'overallDiscountPercentage',
    },
    {
      Header: 'Void %',
      accessor: 'overallVoidPercentage',
    },
    {
      Header: 'Error Correct %',
      accessor: 'overallErrorCorrectPercentage',
    },
  ], []);

  const tableData = useMemo(() => {
    return Object.keys(overallData)
      .filter(employee => !excludedEmployees.includes(employee)) // Filter out excluded employees
      .map(employee => ({
        employee,
        prolifiFraudScore: overallData[employee].prolifiFraudScore.toFixed(2),
        overallDiscountPercentage: overallData[employee].overallDiscountPercentage.toFixed(2) + '%',
        overallVoidPercentage: overallData[employee].overallVoidPercentage.toFixed(2) + '%',
        overallErrorCorrectPercentage: overallData[employee].overallErrorCorrectPercentage.toFixed(2) + '%',
      }));
  }, [overallData]);

  const tableInstance = useTable({
    columns: columnsOverall,
    data: tableData,
  }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Employee Data Viewer</h1>

      {userGroup === 'gm' && (
        <p>Store: {userStore}</p>
      )}

      {(userGroup === 'supervisor' || userGroup === 'director') && (
        <div>
          <label>Select Store: </label>
          <select value={selectedStore} onChange={handleStoreChange}>
            {supervisedStores.map(store => (
              <option key={store} value={store}>{store}</option>
            ))}
          </select>
          {userGroup === 'director' && (
            <p style={{ color: 'orange' }}>Warning: Director logic is still in progress. These results are based on supervised stores.</p>
          )}
        </div>
      )}

      <div>
        <label>Start Date: </label>
        <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        <label>End Date: </label>
        <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
      </div>

      {shiftAffinityEnabled && (
        <div>
          <label>Select Type: </label>
          <select value={analysisType} onChange={handleTypeChange}>
            <option value="Shift Affinity">Shift Affinity</option>
            <option value="Fraud Prevention">Fraud Prevention</option>
          </select>
        </div>
      )}

      <div>
        <label>Select Shift Size: </label>
        <select value={shiftSize} onChange={handleShiftSizeChange}>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={5}>5</option>
        </select>
      </div>

      <button onClick={handleFetchShifts}>Fetch Shifts</button>

      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeDataViewer;
