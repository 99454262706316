// GradeKPIs.js

// Helper function to convert time string to total seconds for comparison
const timeToSeconds = (time) => {
  const [mins, secs] = time.split(':').map(Number);
  return mins * 60 + secs;
};

const gradeKPI = {
  LaborPercentage: (value, thresholds) => {
	  //console.log("Checking ThresholdS");
	  //console.log(thresholds);
    const threshold = thresholds.LaborPercentage;
    //console.log("Grading Labor Percentage - Value:", value);
    //console.log("Thresholds - Green:", threshold.greenThreshold, "Red:", threshold.redThreshold);
    
    const num = parseFloat(value);
    let result;
    if (num <= threshold.greenThreshold) {
      result = { color: 'green', points: 5 };
     // console.log("Result: Green");
    } else if (num >= threshold.redThreshold) {
      result = { color: 'red', points: 0 };
    //  console.log("Result: Red");
    } else {
      result = { color: 'yellow', points: 3 };
    //  console.log("Result: Yellow");
    }
    return result;
  },

CrewProduction: (value, thresholds) => {
  const threshold = thresholds.CrewProduction;
  //console.log("Grading Crew Production - Value:", value);
 // console.log("Thresholds - Green:", threshold.greenThreshold, "Red:", threshold.redThreshold);
  
  // Remove dollar sign and commas before parsing to float
  const num = parseFloat(value.replace(/[$,]/g, ''));
  let result;
  if (num >= threshold.greenThreshold) {
    result = { color: 'green', points: 5 };
    //console.log("Result: Green");
  } else if (num <= threshold.redThreshold) {
    result = { color: 'red', points: 0 };
    //console.log("Result: Red");
  } else {
    result = { color: 'yellow', points: 3 };
    //console.log("Result: Yellow");
  }
  return result;
},

  OnTimesPercentage: (value, thresholds) => {
    const threshold = thresholds.OnTimesPercentage;
    const num = parseFloat(value);
    return num >= threshold.greenThreshold ? { color: 'green', points: 5 } :
           num < threshold.redThreshold ? { color: 'red', points: 0 } :
           { color: 'yellow', points: 3 };
  },
  FormattedAveTime: (value, thresholds) => {
    const threshold = thresholds.FormattedAveTime;
    const seconds = timeToSeconds(value);
    return seconds <= threshold.greenThreshold ? { color: 'green', points: 5 } :
           seconds >= threshold.redThreshold ? { color: 'red', points: 0 } :
           { color: 'yellow', points: 3 };
  },
  FormattedReplyTime: (value, thresholds) => {
    const threshold = thresholds.FormattedReplyTime;
    const seconds = timeToSeconds(value);
    return seconds <= threshold.greenThreshold ? { color: 'green', points: 5 } :
           seconds >= threshold.redThreshold ? { color: 'red', points: 0 } :
           { color: 'yellow', points: 3 };
  },
  SalesChange: (value, thresholds) => {
    const threshold = thresholds.SalesChange;
    const num = parseFloat(value.replace('%', ''));
    return num >= threshold.greenThreshold ? { color: 'green', points: 5 } :
           (threshold.yellowThreshold != null && num >= threshold.yellowThreshold) ? { color: 'yellow', points: 3 } :
           { color: 'red', points: 0 };
  },
  ComplaintsPer10k: (value, thresholds) => {
    const threshold = thresholds.ComplaintsPer10k;
    const num = parseFloat(value);
    return num <= threshold.greenThreshold ? { color: 'green', points: 5 } :
           num >= threshold.redThreshold ? { color: 'red', points: 0 } :
           { color: 'yellow', points: 3 };
  },
  FormattedCash: (value, thresholds) => {
    const threshold = thresholds.FormattedCash;
    const isNegative = value.startsWith('(') && value.endsWith(')');
    const num = parseFloat(value.replace(/[\($,\)]/g, '')) * (isNegative ? -1 : 1);
    return num >= threshold.greenThreshold ? { color: 'green', points: 5 } :
           num < threshold.redThreshold ? { color: 'red', points: 0 } :
           { color: 'yellow', points: 3 };
  },
  FoodVarianceMonth: (value, thresholds) => {
    const threshold = thresholds.FoodVarianceMonth;
    const num = parseFloat(value);
    return num >= threshold.greenThreshold ? { color: 'green', points: 5 } :
           num <= threshold.redThreshold ? { color: 'red', points: 0 } :
           { color: 'yellow', points: 3 };
  },
  ContExpenses: (value, thresholds) => {
    const threshold = thresholds.ContExpenses;
    const num = parseFloat(value.replace('%', ''));
    return num <= threshold.greenThreshold ? { color: 'green', points: 5 } :
           num >= threshold.redThreshold ? { color: 'red', points: 0 } :
           { color: 'yellow', points: 3 };
  }
};

export default gradeKPI;

