//SScheduleAnalyzer.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import ProgressButton from 'react-progress-button';
import 'react-progress-button/react-progress-button.css'; // Ensure the CSS is imported
import styles from './SScheduleAnalyzer.module.css';

function SScheduleAnalyzer() {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [excelFileUrl, setExcelFileUrl] = useState('');
  const [buttonState, setButtonState] = useState('');
    const [isFormVisible, setIsFormVisible] = useState(true); // New state for form visibility

  const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
    setButtonState('');
  };

  const getBaseUrl = () => {
    return process.env.NODE_ENV === 'development'
      ? 'http://72.167.34.236:5000'
      : 'https://prolifi.app';
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setErrorMessage('');
	setButtonState('');
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      Swal.fire('Oops...', 'Please select a file first', 'error');
	  setButtonState('error');
      return;
    }

    setButtonState('loading');
    const formData = new FormData();
    formData.append('file', file);

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.post(
        `${baseUrl}/api/sScheduleUpload`, 
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true
        }
      );

      if (response.data.filePath) {
        setExcelFileUrl(response.data.filePath);
        setButtonState('success');
      } else {
        Swal.fire('Error', 'No file path returned from server', 'error');
        setButtonState('error');
      }
    } catch (error) {
      console.error('Upload error:', error);
      let errMessage = error.response && error.response.data ? error.response.data : 'An unexpected error occurred';
      Swal.fire('Error', errMessage, 'error');
      setErrorMessage(errMessage);
      setButtonState('error');
    }
  };

  useEffect(() => {
    if (excelFileUrl) {
      const downloadExcelFile = async () => {
        try {
          const fullUrl = `${getBaseUrl()}/api/view-excel/${excelFileUrl}`;
          const response = await axios.get(fullUrl, { responseType: 'blob' });
          const file = new Blob([response.data], { type: 'application/vnd.ms-excel' });
          const fileURL = window.URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = 'downloaded_file.xlsx';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(fileURL);
        } catch (error) {
          console.error('Error downloading Excel file:', error);
          Swal.fire('Error', 'Failed to download Excel file', 'error');
        }
      };
      downloadExcelFile();
    }
  }, [excelFileUrl]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.uploadContainer}>
        <button onClick={toggleFormVisibility} className={styles.toggleBar}>
          {isFormVisible ? '▲ Hide Form' : '▼ Show Form'}
        </button>

        {isFormVisible && (
          <form onSubmit={handleUpload} className={styles.form}>
            <h1 className={styles.formTitle}>Supervisor Schedule Analyzer</h1>
            <input type="file" onChange={handleFileChange} className={styles.input} />
            <ProgressButton state={buttonState} className={`${styles.progressButton} ${styles.customButton}`}>
              Analyze
            </ProgressButton>
          </form>
        )}

        {errorMessage && (
          <div className={styles.errorMessage}>{errorMessage}</div>
        )}
      </div>
    </div>
  );
}

export default SScheduleAnalyzer;
