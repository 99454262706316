import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './PdfReview.module.css';

function PdfReview() {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [pdfUrl, setPdfUrl] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [userStore, setUserStore] = useState(null);

  const getBaseUrl = () => {
    return process.env.NODE_ENV === 'development'
      ? 'http://72.167.34.236:5000'
      : 'https://prolifi.app';
  };

  useEffect(() => {
    const fetchUserStore = async () => {
      try {
        const baseUrl = getBaseUrl();
        // Make sure to update the endpoint to the actual one that provides user store info
		
		const userResponse = await axios.get(`${baseUrl}/api/user-store`, {
		   withCredentials: true
		});
        setUserStore(userResponse.data.userStore);
      } catch (error) {
        console.error('Error fetching user store:', error);
      }
    };

    fetchUserStore();
  }, []);

  useEffect(() => {
    const fetchPdfList = async () => {
		//console.log('selected month:', selectedMonth);
		//console.log('selected year:', selectedYear);
		//console.log('store:', userStore);
      try {
        const baseUrl = getBaseUrl();
        const response = await axios.get(`${baseUrl}/api/pdf-list`, {
          params: {
            month: selectedMonth,
            year: selectedYear,
            // To be handled based on your server implementation:
            // If the server filters by store, you can include 'store' in 'params'.
            // If not, you might need to filter the resulting 'response.data' manually.
            store: userStore
          }
        });
        setPdfFiles(response.data);
      } catch (error) {
        console.error('Error fetching PDF list:', error);
      }
    };

    if (selectedMonth && selectedYear && userStore) {
      fetchPdfList();
    } else {
      setPdfFiles([]);
    }
  }, [selectedMonth, selectedYear, userStore]);

  const handleMonthSelect = (e) => {
    setSelectedMonth(e.target.value);
    setPdfUrl(''); // Reset the PDF URL
  };

  const handleYearSelect = (e) => {
    setSelectedYear(e.target.value);
    setPdfUrl(''); // Reset the PDF URL
  };

  const handlePdfSelect = (e) => {
    const fileName = e.target.value;
    if (fileName) {
     // console.log('Selected PDF file:', fileName);
      const baseUrl = getBaseUrl();
      const encodedFileName = encodeURIComponent(fileName);
      setPdfUrl(`${baseUrl}/api/view-pdf/${encodedFileName}`);
    }
  };

  const years = Array.from(
    new Array(4), // Adjust the number of years as needed
    (val, index) => new Date().getFullYear() - index
  );

return (
  <div className={styles.mainContainer}>
    <h1>PDF Review Page</h1>
    <div className={styles.formContainer}>
      <div className={styles.dateSelectors}>
        <select onChange={handleYearSelect} value={selectedYear} className={styles.input}>
          <option value="" disabled>Select a Year</option>
          {years.map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>
        <select onChange={handleMonthSelect} value={selectedMonth} className={styles.input}>
          <option value="" disabled>Select a Month</option>
          {Array.from({ length: 12 }, (v, i) => (
            <option key={i} value={i + 1}>
              {new Date(0, i).toLocaleString('default', { month: 'long' })}
            </option>
          ))}
        </select>
      </div>
      {selectedMonth && selectedYear && userStore && (
        <div className={styles.pdfSelector}>
          <select onChange={handlePdfSelect} className={styles.input} defaultValue="">
            <option value="" disabled>Select a PDF</option>
            {pdfFiles.map((file, index) => (
              <option key={index} value={file}>{file}</option>
            ))}
          </select>
        </div>
      )}
    </div>
    {pdfUrl && (
      <div className={styles.pdfViewerContainer}>
        <iframe src={pdfUrl} className={styles.pdfViewer} title="PDF Preview"></iframe>
      </div>
    )}
  </div>
);

}

export default PdfReview;