import React, { useState, useEffect } from 'react';
import axios from 'axios';
import processSalesData from './SalesDataProcessor'; // Ensure this is correctly imported
import styles from './ForecastDisplay.module.css';

function ForecastDisplay() {
    const [userDetails, setUserDetails] = useState({ userStore: '', userGroup: '', company: '', username: '' });
    const [salesData, setSalesData] = useState([]);
    const [complexData, setComplexData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const getBaseUrl = () => {
        const hostname = window.location.hostname;
        return /^(www\.)?prolifi\.app$/.test(hostname) ? 'https://prolifi.app' : 'http://72.167.34.236:5000';
    };

    // Fetch user details first
    useEffect(() => {
        axios.get(`${getBaseUrl()}/api/user-store`, { withCredentials: true })
        .then(response => {
            setUserDetails(response.data);
        })
        .catch(err => {
            console.error('Failed to fetch user details:', err);
            setError('Failed to fetch user details');
            setLoading(false); // Ensure to stop loading even on error
        });
    }, []); // Empty dependency array ensures this runs only once on mount

    // Fetch sales data when userDetails.userStore is available
    useEffect(() => {
        const fetchSalesData = async () => {
            try {
                const response = await axios.get(`${getBaseUrl()}/api/sales-forecaster/sales-data/${userDetails.userStore}`);
                const processedData = processSalesData(response.data);
                setComplexData(processedData);
                setLoading(false);
            } catch (err) {
                console.error('Failed to fetch sales data:', err);
                setError('Failed to fetch sales data');
                setLoading(false);
            }
        };

        if (userDetails.userStore) {
            fetchSalesData();
        }
    }, [userDetails.userStore]); // Dependency on userStore ensures fetch executes only when userStore is set

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
	
	    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const periods = ['2weeks', '4weeks', '6weeks', '3months', '6months', '12months'];

return (
    <div className={styles.forecastDisplay}>
        <h1>Welcome, {userDetails.username}</h1>
        <div>
            <p><strong>Store ID:</strong> {userDetails.userStore}</p>
            <p><strong>Group:</strong> {userDetails.userGroup}</p>
            <p><strong>Company:</strong> {userDetails.company}</p>
            <div className={styles.tableContainer}>
                <table>
                    <thead>
                        <tr>
                            <th>Day/Period</th>
                            {periods.map(period => <th key={period}>{period}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {daysOfWeek.map(day => (
                            <tr key={day}>
                                <td>{day}</td>
                                {periods.map(period => (
                                    <td key={period}>{complexData[period] && complexData[period].dailyAverages[day]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);

}

export default ForecastDisplay;
