export const calculateKpiPoints = ({ avgTicketTime, labor, onTime, spmh, weight, daypart }) => {
  // Convert time to seconds
  const timeToSeconds = (time) => {
    if (typeof time !== 'string') return 0;
    const [minutes, seconds] = time.split(':').map(Number);
    return (minutes * 60) + (seconds || 0);
  };

  // Labor Points Calculation
  const laborPoints = (labor, daypart) => {
    const percentage = parseFloat(labor);
    let points = 0;

    if (daypart === 'Breakfast') {
      points = 0; // Always 0 points for Breakfast
    } else {
      if (percentage <= 22) points = 10;
      else if (percentage <= 22.5) points = 9;
      else if (percentage <= 23) points = 8;
      else if (percentage <= 23.5) points = 7;
      else if (percentage <= 23.75) points = 4;
      else if (percentage <= 24) points = 1;
      else if (percentage <= 24.5) points = 0;
      else if (percentage > 25) points = -1;
    }

    //console.log(`Labor Points: ${points}`);
    const weightedPoints = points * weight;
    return weightedPoints;
  };

  // SPMH Points Calculation
  const spmhPoints = (spmh, daypart) => {
    const value = parseFloat(spmh);
    let points = 0;

    if (daypart === 'Breakfast') {
      points = 0; // Always 0 points for Breakfast
    } else {
      if (value >= 67) points = 10;
      else if (value >= 66) points = 9;
      else if (value >= 65) points = 8;
      else if (value >= 64) points = 7;
      else if (value >= 62) points = 4;
      else if (value >= 60) points = 1;
      else if (value >= 55) points = 0;
      else if (value < 55) points = -1;
    }

    //console.log(`SPMH Points: ${points}`);
    const weightedPoints = points * weight;
    return weightedPoints;
  };

  // Avg Ticket Time Points Calculation
  const avgTicketTimePoints = (time, daypart) => {
    const seconds = timeToSeconds(time);
    let points = 0;

    if (seconds <= 135) points = 20; // 2:15
    else if (seconds <= 150) points = 18;  // 2:30
    else if (seconds <= 165) points = 16;  // 2:45
    else if (seconds <= 180) points = 14;  // 3:00
    else if (seconds <= 210) points = 8;  // 3:30
    else if (seconds <= 225) points = 2;  // 3:45
    else if (seconds <= 240) points = 0;  // 4:00
    else if (seconds > 300) points = -2;  // 5:00

    //console.log(`Daypart: ${daypart}, SPMH: ${spmh}, Avg Ticket Time: ${seconds} seconds, Initial Points: ${points}`);

    // Breakfast-specific bonuses and penalties
    if (daypart === 'Breakfast') {
      if (spmh > 50) {
        //console.log(`SPMH > 50, applying breakfast bonus.`);
        if (seconds < 150) {
          points += 4;  // Below 2:30
          //console.log(`Bonus 4 points (Avg Ticket Time < 2:30). Points after bonus: ${points}`);
        } else if (seconds < 160) {
          points += 2.5;  // Below 2:40
         // console.log(`Bonus 2.5 points (Avg Ticket Time < 2:40). Points after bonus: ${points}`);
        } else if (seconds < 155) {
          points += 1.5;  // Below 2:35
        //  console.log(`Bonus 1.5 points (Avg Ticket Time < 2:35). Points after bonus: ${points}`);
        }
      } else if (spmh < 40) {
        console.log(`SPMH < 40, applying breakfast penalty.`);
        if (seconds > 195) {
          points -= 2;  // Above 3:15
        //  console.log(`Penalty 2 points (Avg Ticket Time > 3:15). Points after penalty: ${points}`);
        } else if (seconds > 210) {
          points -= 2.5;  // Above 3:30
        //  console.log(`Penalty 2.5 points (Avg Ticket Time > 3:30). Points after penalty: ${points}`);
        } else if (seconds > 225) {
          points -= 3;  // Above 3:45
        //  console.log(`Penalty 3 points (Avg Ticket Time > 3:45). Points after penalty: ${points}`);
        }
      }
    } else {
      // Default bonus and penalty system for other dayparts
      if (spmh > 70) {
       // console.log(`SPMH > 70, applying standard bonus.`);
        if (points < 0) points += 0.5;  // Half of 1 point
        else if (points >= 4 && points < 8) points += 1.5;  // Half of 3 points
        else if (points >= 8 && points < 10) points += 2.5;  // Half of 5 points
        else if (points === 10) points += 4;  // Half of 8 points
       // console.log(`Points after standard bonus: ${points}`);
      }

      if (spmh < 55) {
       // console.log(`SPMH < 55, applying standard penalty.`);
        if (points < 7 && points >= 4) points -= 4;  // Penalty 4 points
        else if (points < 4 && points >= 1) points -= 5;  // Penalty 5 points
        else if (points === 1 || points === 0 || points === -1) points -= 6;  // Penalty 6 points
        //console.log(`Points after standard penalty: ${points}`);
      }
    }

   // console.log(`Daypart: ${daypart}, Final Avg Ticket Time Points: ${points}`);
    const weightedPoints = points * weight;
   // console.log(`Weighted Avg Ticket Time Points: ${weightedPoints}`);
    return weightedPoints;
  };

  // On Time Points Calculation
  const onTimePoints = (onTime, daypart) => {
    const percentage = parseFloat(onTime);
    let points = 0;

    if (percentage >= 90) points = 20;
    else if (percentage >= 87) points = 18;
    else if (percentage >= 85) points = 16;
    else if (percentage >= 82) points = 14;
    else if (percentage >= 80) points = 8;
    else if (percentage >= 75) points = 2;
    else if (percentage >= 72) points = 0;
    else if (percentage < 70) points = -2;

   // console.log(`Daypart: ${daypart}, SPMH: ${spmh}, On Time Percentage: ${percentage}%, Initial Points: ${points}`);

    // Breakfast-specific bonuses and penalties
    if (daypart === 'Breakfast') {
      if (spmh > 50) {
        //console.log(`SPMH > 50, applying breakfast bonus.`);
        if (percentage > 87) {
          points += 4;
        //  console.log(`Bonus 4 points (On Time > 87%). Points after bonus: ${points}`);
        } else if (percentage > 85) {
          points += 2.5;
        //  console.log(`Bonus 2.5 points (On Time > 85%). Points after bonus: ${points}`);
        } else if (percentage > 80) {
          points += 1.5;
        //  console.log(`Bonus 1.5 points (On Time > 80%). Points after bonus: ${points}`);
        }
      } else if (spmh < 40) {
        console.log(`SPMH < 40, applying breakfast penalty.`);
        if (percentage < 72) {
          points -= 2;
         // console.log(`Penalty 2 points (On Time < 72%). Points after penalty: ${points}`);
        } else if (percentage < 68) {
          points -= 2.5;
        //  console.log(`Penalty 2.5 points (On Time < 68%). Points after penalty: ${points}`);
        } else if (percentage < 65) {
          points -= 3;
        //  console.log(`Penalty 3 points (On Time < 65%). Points after penalty: ${points}`);
        }
      }
    } else {
      // Default bonus and penalty system for other dayparts
      if (spmh > 70) {
       // console.log(`SPMH > 70, applying standard bonus.`);
        if (points < 0) points += 0.5;  // Half of 1 point
        else if (points >= 4 && points < 8) points += 1.5;  // Half of 3 points
        else if (points >= 8 && points < 10) points += 2.5;  // Half of 5 points
        else if (points === 10) points += 4;  // Half of 8 points
        //console.log(`Points after standard bonus: ${points}`);
      }

      if (spmh < 55) {
       // console.log(`SPMH < 55, applying standard penalty.`);
        if (points < 7 && points >= 4) points -= 4;  // Penalty 4 points
        else if (points < 4 && points >= 1) points -= 5;  // Penalty 5 points
        else if (points === 1 || points === 0 || points === -1) points -= 6;  // Penalty 6 points
       // console.log(`Points after standard penalty: ${points}`);
      }
    }

   // console.log(`Daypart: ${daypart}, Final On Time Points: ${points}`);
    const weightedPoints = points * weight;
   // console.log(`Weighted On Time Points: ${weightedPoints}`);
    return weightedPoints;
  };

  const avgTicketPoints = avgTicketTimePoints(avgTicketTime, daypart);
  const laborPts = laborPoints(labor, daypart);
  const onTimePts = onTimePoints(onTime, daypart);
  const spmhPts = spmhPoints(spmh, daypart);

  return {
    avgTicketTimePoints: avgTicketPoints,
    laborPoints: laborPts,
    onTimePoints: onTimePts,
    spmhPoints: spmhPts,
  };
};
