// SalesDataProcessor.js
const processSalesData = (salesData) => {
    return processComplexSalesData(salesData);
};

const processComplexSalesData = (salesData) => {
    const getWeekNumber = (date) => {
        const dateObj = new Date(date);
        const firstDayOfYear = new Date(dateObj.getFullYear(), 0, 1);
        const pastDaysOfYear = (dateObj - firstDayOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };

    const dayOfWeek = (date) => {
        const day = new Date(date).getDay();  // Returns 0 for Sunday, 1 for Monday, etc.
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[day];
    };

    const groupedByWeeksAndDays = salesData.reduce((acc, { date, netsales }) => {
        const week = getWeekNumber(date);
        const year = new Date(date).getFullYear();
        const day = dayOfWeek(date);
        const weekKey = `${year}-W${week}`;
        const dayKey = `${weekKey}-${day}`;

        if (!acc.weeks[weekKey]) acc.weeks[weekKey] = [];
        acc.weeks[weekKey].push(parseFloat(netsales));

        if (!acc.days[dayKey]) acc.days[dayKey] = [];
        acc.days[dayKey].push(parseFloat(netsales));

        return acc;
    }, { weeks: {}, days: {} });

    const calculateAverages = (data) => {
        if (data.length === 0) return 0;
        const sortedData = [...data].sort((a, b) => a - b);
        return (sortedData.reduce((acc, curr) => acc + curr, 0) / sortedData.length).toFixed(2);
    };

    const results = {};
    const periods = [
        { key: '2weeks', weeks: 2 },
        { key: '4weeks', weeks: 4 },
        { key: '6weeks', weeks: 6 },
        { key: '3months', weeks: 12 },
        { key: '6months', weeks: 24 },
        { key: '12months', weeks: 48 }
    ];

    // Calculate averages for specified periods
    periods.forEach(({ key, weeks }) => {
        const currentYear = new Date().getFullYear();
        const recentWeeks = Object.keys(groupedByWeeksAndDays.weeks)
                                  .filter(wk => wk.startsWith(currentYear.toString()))
                                  .sort().reverse().slice(0, weeks);

        const allSales = recentWeeks.flatMap(week => groupedByWeeksAndDays.weeks[week]);
        results[key] = {
            avgSales: calculateAverages(allSales),
            dailyAverages: {}
        };

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        daysOfWeek.forEach(day => {
            const dailySales = recentWeeks.flatMap(week => groupedByWeeksAndDays.days[`${week}-${day}`] || []);
            results[key].dailyAverages[day] = calculateAverages(dailySales);
        });
    });

    return results;
};

export default processSalesData;