// src/NavBar.js
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logo from './images/ptechlogoDarkBG.png';
import styles from './NavBar.module.css';
import CSVConverter from './TruckConverter';
import { storeOperationsUsers } from './accessControl';

function NavBar() {
  const [isPayrollExpanded, setIsPayrollExpanded] = useState(false);
  const [isDirectorFunctionsExpanded, setIsDirectorFunctionsExpanded] = useState(false);
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState(false);
  const [isAdminFunctionsExpanded, setIsAdminFunctionsExpanded] = useState(false);
  const [isSupervisorFunctionsExpanded, setIsSupervisorFunctionsExpanded] = useState(false);
  const [isOfficeSectionExpanded, setIsOfficeSectionExpanded] = useState(false); // New state for Office section
  const navigate = useNavigate();
  const [userGroup, setUserGroup] = useState('');
  const [company, setCompany] = useState('');
  const [username, setUsername] = useState('');
  const [isJBoltinSectionExpanded, setIsJBoltinSectionExpanded] = useState(false);
  const [isStoreOperationsExpanded, setIsStoreOperationsExpanded] = useState(false);

  const getBaseUrl = () => {
    const hostname = window.location.hostname;

    if (hostname === '72.167.34.236') {
      return 'http://72.167.34.236:5000';
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return 'https://prolifi.app';
    } else {
      console.error('Unknown host');
      return ''; // Return an empty string or a default URL if preferred
    }
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const baseUrl = getBaseUrl();
        const response = await axios.get(`${baseUrl}/api/user-store`, { withCredentials: true });
        setUserGroup(response.data.userGroup);
        setCompany(response.data.company); // Set company state
        setUsername(response.data.username);
        console.log('Received userGroup:', response.data.userGroup, 'Company:', response.data.company);
      } catch (error) {
        console.error('Error fetching user details', error);
      }
    };

    fetchUserDetails();
  }, []);

  const toggleNavbar = () => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  };

  const togglePayroll = () => {
    setIsPayrollExpanded(!isPayrollExpanded);
  };

  const toggleDirectorFunctions = () => {
    setIsDirectorFunctionsExpanded(!isDirectorFunctionsExpanded);
  };

  const toggleSupervisorFunctions = () => {
    setIsSupervisorFunctionsExpanded(!isSupervisorFunctionsExpanded);
  };

  const toggleAdminFunctions = () => {
    setIsAdminFunctionsExpanded(!isAdminFunctionsExpanded);
  };

  const toggleOfficeSection = () => {
    setIsOfficeSectionExpanded(!isOfficeSectionExpanded);
  };
  
  const toggleStoreOperations = () => {
    setIsStoreOperationsExpanded(!isStoreOperationsExpanded);
  };

  const handleLogout = async () => {
    const hostname = window.location.hostname;
    const baseUrl = getBaseUrl();

    try {
      await axios.get(`${baseUrl}/api/auth/logout`, { withCredentials: true });
      navigate('/login');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const showPayrollProcessing = company === 'esch' || company === 'simons';
  const showSupervisorFunctions = (company === 'esch' || company === 'simons') && ['Admin', 'supervisor', 'director'].includes(userGroup);
  const showDirectorFunctions = (company === 'esch' || company === 'simons') && ['Admin', 'director'].includes(userGroup);
  const showAdminFunctions = userGroup === 'Admin';
  const showJBoltinSection = username === 'Jboltin' || username === 'jnauertc';
  const showOfficeSection = userGroup === 'director' || userGroup === 'office' || username === 'Jboltin'; // New condition for Office section
  const showStoreOperationsSection = storeOperationsUsers.map(user => user.toLowerCase()).includes(username.toLowerCase());
  
  return (
    <div className={`${styles.navBar} ${isNavbarCollapsed ? styles.collapsed : ''}`}>
      <div className={styles.navBarToggle} onClick={toggleNavbar}>
        <span className="material-icons">
          {isNavbarCollapsed ? 'menu_open' : 'menu'}
        </span>
      </div>

      <div className={styles.navItems}>
        <img src={logo} alt="Logo" className={styles.logo} />

        {showPayrollProcessing && (
          <>
            <div className={styles.category} onClick={togglePayroll}>
              Payroll Processing
            </div>
            {isPayrollExpanded && (
              <>
                <Link to="/prFileUpload" className={styles.navItemLink}>
                  <p className={styles.linkText}>Payroll Converter</p>
                </Link>
                <Link to="/pdfReview" className={styles.navItemLink}>
                  <p className={styles.linkText}>Post-Payroll Reports</p>
                </Link>
              </>
            )}
          </>
        )}
		
		        {showStoreOperationsSection && (
          <>
            <div className={styles.category} onClick={toggleStoreOperations}>
              Store Operations
            </div>
            {isStoreOperationsExpanded && (
              <>
			  <Link to="/csv-converter" className={styles.navItemLink}>
				<p className={styles.linkText}>Truck Converter</p>
			  </Link>
              </>
            )}
          </>
        )}

        {showSupervisorFunctions && (
          <>
            <div className={styles.category} onClick={toggleSupervisorFunctions}>
              Supervisor Functions
            </div>
            {isSupervisorFunctionsExpanded && (
              <>
                <Link to="/bbUpload" className={styles.navItemLink}>
                  <p className={styles.linkText}>BlueBook Upload</p>
                </Link>
                <Link to="/pops-report" className={styles.navItemLink}>
                  <p className={styles.linkText}>Pops Report</p>
                </Link>
                <Link to="/w-manual" className={styles.navItemLink}>
                  <p className={styles.linkText}>WEEKLY Manual Data</p>
                </Link>
                <Link to="/monthly-manual" className={styles.navItemLink}>
                  <p className={styles.linkText}>MONTHLY Manual Data</p>
                </Link>
              </>
            )}
          </>
        )}

        {showDirectorFunctions && (
          <>
            <div className={styles.category} onClick={toggleDirectorFunctions}>
              Director Functions
            </div>
            {isDirectorFunctionsExpanded && (
              <>
                <Link to="/sScheduleAnalyzer" className={styles.navItemLink}>
                  <p className={styles.linkText}>Schedule Analyzer</p>
                </Link>
                <Link to="/weekly-sales-report" className={styles.navItemLink}>
                  <p className={styles.linkText}>Weekly Sales Report</p>
                </Link>
                <Link to="/ee-data-upload" className={styles.navItemLink}>
                  <p className={styles.linkText}>TPC Data Upload</p>
                </Link>
                <Link to="/tz-file-upload" className={styles.navItemLink}>
                  <p className={styles.linkText}>TZ File Upload</p>
                </Link>
                <Link to="/fraud-prevention" className={styles.navItemLink}>
                  <p className={styles.linkText}>Fraud Prevention</p>
                </Link>
                <Link to="/monthly-processing" className={styles.navItemLink}>
                  <p className={styles.linkText}>Monthly Processing</p>
                </Link>
                <Link to="/pops-report" className={styles.navItemLink}>
                  <p className={styles.linkText}>Pops Report</p>
                </Link>
                <Link to="/grading-standards" className={styles.navItemLink}>
                  <p className={styles.linkText}>PoPs Standards</p>
                </Link>
              </>
            )}
          </>
        )}

        {showOfficeSection && (
          <>
            <div className={styles.category} onClick={toggleOfficeSection}>
              Office
            </div>
            {isOfficeSectionExpanded && (
              <>
                <Link to="/vision-dental" className={styles.navItemLink}>
                  <p className={styles.linkText}>Vision & Dental</p>
                </Link>
              </>
            )}
          </>
        )}

        {showJBoltinSection && (
          <>
            <div className={styles.category} onClick={() => setIsJBoltinSectionExpanded(!isJBoltinSectionExpanded)}>
              Work-In-Progress
            </div>
            {isJBoltinSectionExpanded && (
              <>
                <Link to="/forecast-display" className={styles.navItemLink}>
                  <p className={styles.linkText}>Sales Forecaster</p>
                </Link>
                <Link to="/sales-forecaster" className={styles.navItemLink}>
                  <p className={styles.linkText}>Weather Data Importer</p>
                </Link>
                <Link to="/sticker-component" className={styles.navItemLink}>
                  <p className={styles.linkText}>Sticker Component</p>
                </Link>
                <Link to="/store-user-management" className={styles.navItemLink}>
                  <p className={styles.linkText}>Store User Management</p>
                </Link>
                <Link to="/corrective-action-dashboard" className={styles.navItemLink}>
                  <p className={styles.linkText}>Corrective Action Dashboard</p>
                </Link>
				<Link to="/shift-analyze" className={styles.navItemLink}>
                  <p className={styles.linkText}>Excel Uploader</p>
                </Link>
				<Link to="/employee-analyzer" className={styles.navItemLink}>
                  <p className={styles.linkText}>Employee Analyzer</p>
                </Link>
				<Link to="/employee-data-viewer" className={styles.navItemLink}>
				  <p className={styles.linkText}>Employee Affinity</p>
				</Link>
				<Link to="/closing-checklist-builder" className={styles.navItemLink}>
				  <p className={styles.linkText}>Closing Checklist Builder</p>
				</Link>
              </>
            )}
          </>
        )}

        {showAdminFunctions && (
          <>
            <div className={styles.category} onClick={toggleAdminFunctions}>
              Admin
            </div>
            {isAdminFunctionsExpanded && (
              <>
                <Link to="/create-user" className={styles.navItemLink}>
                  <p className={styles.linkText}>Create User</p>
                </Link>
                <Link to="/session-checker" className={styles.navItemLink}>
                  <p className={styles.linkText}>Session Checker</p>
                </Link>
              </>
            )}
          </>
        )}

        <div className={styles.logoutLink} onClick={handleLogout}>
          Logout
        </div>
      </div>
    </div>
  );
}

export default NavBar;
