// ChatWidget.js
import React, { useState, useRef } from 'react';
import { Box, Button, TextField, List, ListItem, Typography, Fab } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';

function ChatWidget() {
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [hasInitiatedContact, setHasInitiatedContact] = useState(false);
  const messagesEndRef = useRef(null);

  const toggleChat = () => setOpen(!open);
  const handleInputChange = (event) => setInputValue(event.target.value);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

const getBaseUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://72.167.34.236:5000';
  }
  return 'https://prolifi.app';
};


const displayTypingEffect = (message, fromUser) => {
  let currentMessage = '';
  const messageCharacters = message.split('');
  const scrollInterval = 5; // Adjust this value as needed
  let charCount = 0;

  const typeCharacterByCharacter = () => {
    if (messageCharacters.length) {
      currentMessage += messageCharacters.shift();
      charCount++;
      setMessages((prevMessages) => {
        if (!fromUser && prevMessages.length && !prevMessages[prevMessages.length - 1].fromUser && prevMessages[prevMessages.length - 1].typing) {
          return [...prevMessages.slice(0, -1), { message: currentMessage, fromUser, typing: true }];
        } else {
          return [...prevMessages, { message: currentMessage, fromUser, typing: true }];
        }
      });
      // Scroll to bottom every `scrollInterval` characters
      if (charCount % scrollInterval === 0) {
        scrollToBottom();
      }
      setTimeout(typeCharacterByCharacter, 50);
    } else {
      setMessages((prevMessages) => {
        if (!fromUser && prevMessages.length && !prevMessages[prevMessages.length - 1].fromUser && prevMessages[prevMessages.length - 1].typing) {
          return [...prevMessages.slice(0, -1), { message, fromUser }];
        } else {
          return [...prevMessages, { message, fromUser }];
        }
      });
      scrollToBottom(); // Ensure the final message is fully in view
    }
  };

  typeCharacterByCharacter();
};


const sendMessage = async (event) => {
  event.preventDefault();
  const trimmedInput = inputValue.trim();
  if (trimmedInput) {
    setMessages((prevMessages) => [...prevMessages, { message: trimmedInput, fromUser: true }]);
    setInputValue('');
    scrollToBottom();

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.post(`${baseUrl}/api/chat`, { 
        message: trimmedInput,
        hasInitiatedContact // Pass the current state to the backend
      });
      const { reply, hasInitiatedContact: updatedHasInitiatedContact } = response.data;

      setHasInitiatedContact(updatedHasInitiatedContact); // Update the flag based on the backend response

      displayTypingEffect(reply, false);
    } catch (error) {
      console.error('Failed to fetch:', error);
      setMessages((prevMessages) => [...prevMessages, { message: 'Sorry, something went wrong.', fromUser: false }]);
      scrollToBottom();
    }
  }
};


  return (
    <Box sx={{ position: 'fixed', bottom: 24, right: 24, zIndex: 1500 }}>
      <Fab color="primary" onClick={toggleChat} sx={{ position: 'absolute', bottom: open ? '440px' : '16px', right: '16px', transition: 'all 0.5s' }}>
        <ChatIcon />
      </Fab>
      {open && (
        <Box sx={{
          width: 350,
          height: 450,
          backgroundColor: 'background.paper',
          borderRadius: '20px',
          boxShadow: '0px 8px 10px rgba(0,0,0,0.3)',
          display: 'flex',
          flexDirection: 'column',
          transition: 'all 0.5s',
          padding: '20px',
          border: '2px solid #1976d2',
        }}>
          <Typography variant="h6" align="center" sx={{ fontWeight: 'bold' }}>Chat with Us</Typography>
          <List sx={{ overflowY: 'scroll', flexGrow: 1, px: 1, py: 2 }} ref={messagesEndRef}>
            {messages.map((message, index) => (
              <ListItem key={index} sx={{ display: 'flex', justifyContent: message.fromUser ? 'flex-end' : 'flex-start' }}>
                <Box sx={{
                  background: message.fromUser ? '#1976d2' : '#eee',
                  color: message.fromUser ? '#fff' : '#000',
                  borderRadius: '20px',
                  padding: '10px 12px',
                  wordBreak: 'break-word',
                  maxWidth: '80%',
                  opacity: message.typing ? 0.5 : 1,
                }}>
                  <Typography variant="body2">{message.message}</Typography>
                </Box>
              </ListItem>
            ))}
            <div ref={messagesEndRef} />
          </List>
          <form onSubmit={sendMessage} sx={{ borderTop: '1px solid #ccc', pt: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type a message..."
              value={inputValue}
              onChange={handleInputChange}
              InputProps={{
                endAdornment: (
                  <Button type="submit" sx={{ p: 0, minWidth: 'auto' }}>
                    <SendIcon sx={{ color: 'primary.main' }} />
                  </Button>
                ),
              }}
            />
          </form>
        </Box>
      )}
    </Box>
  );
}

export default ChatWidget;
