import React from 'react';
import styles from './WeeklyProfitabilityTable.module.css'; // Make sure to create this CSS module file
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export function WeeklyProfitabilityTable({ weeklyProfitabilityData }) {
  if (!weeklyProfitabilityData) {
    console.log('No weekly profitability data provided.');
    return <p>No profitability data available.</p>;
  }

  // Function to calculate week and month production and labor

const calculateValues = (data) => {
  // Use optional chaining and nullish coalescing to ensure values are defined
  const weekNetSales = parseFloat(data?.currentWeek?.netSales ?? 0);
  const monthNetSales = parseFloat(data?.netSalesMonth ?? 0);
  const weekLabor = parseFloat(data?.currentWeek?.labor ?? 0);
  const monthLabor = parseFloat(data?.totalLaborMonth ?? 0);
  const hoursFullWeek = parseFloat(data?.profitData?.hours_full_week ?? 0);
  const totalHoursForMonth = parseFloat(data?.profitData?.totalHoursForMonth ?? 0);

  const weekProduction = hoursFullWeek ? weekNetSales / hoursFullWeek : 0;
  const monthProduction = totalHoursForMonth ? monthNetSales / totalHoursForMonth : 0;
  const weekLaborPercentage = weekNetSales ? weekLabor / weekNetSales : 0;
  const monthLaborPercentage = monthNetSales ? monthLabor / monthNetSales : 0;

  return { 
    weekProduction, 
    monthProduction, 
    weekLaborPercentage, 
    monthLaborPercentage 
  };
};


const renderTableBody = () => {
  if (!weeklyProfitabilityData) return null;

  // Sort the data by week production highest to lowest
  const sortedData = Object.entries(weeklyProfitabilityData).sort((a, b) => {
    const productionA = calculateValues(a[1]).weekProduction;
    const productionB = calculateValues(b[1]).weekProduction;
    return productionB - productionA;
  });

  return sortedData.map(([storeId, data], index) => {
    const { weekProduction, monthProduction, weekLaborPercentage, monthLaborPercentage } = calculateValues(data);

    return (
      <TableRow key={index}>
        <TableCell>{data?.supervisorName ?? 'N/A'}</TableCell>
        <TableCell>{data?.storeName ?? 'N/A'}</TableCell>
        <TableCell>{isNaN(weekProduction) ? 'N/A' : weekProduction.toFixed(2)}</TableCell>
        <TableCell>{isNaN(monthProduction) ? 'N/A' : monthProduction.toFixed(2)}</TableCell>
        <TableCell>{isNaN(weekLaborPercentage) ? 'N/A' : (weekLaborPercentage * 100).toFixed(2)}</TableCell>
        <TableCell>{isNaN(monthLaborPercentage) ? 'N/A' : (monthLaborPercentage * 100).toFixed(2)}</TableCell>
        <TableCell>{data?.profitData?.food_variance_week ?? 'N/A'}</TableCell>
        <TableCell>{data?.profitData?.food_variance_month ?? 'N/A'}</TableCell>
        <TableCell>{data?.currentWeek?.overshort ?? 'N/A'}</TableCell>
        <TableCell>{data?.profitData?.totalOvershortMonth ?? 'N/A'}</TableCell>
      </TableRow>
    );
  });
};

return (
  <TableContainer component={Paper} className={styles.tableContainer}>
    <Table aria-label="weekly profitability table" className={styles.table}>
      <TableHead>
        <TableRow>
          <TableCell rowSpan={2} className={styles.cardHeader}>Supervisor</TableCell>
          <TableCell rowSpan={2} className={styles.cardHeader}>Store</TableCell>
          <TableCell colSpan={2} className={`${styles.cardHeader}`}>Crew Production</TableCell>
          <TableCell colSpan={2} className={`${styles.cardHeader}`}>Crew Labor</TableCell>
          <TableCell colSpan={2} className={`${styles.cardHeader}`}>Variance</TableCell>
          <TableCell colSpan={2} className={`${styles.cardHeader}`}>Cash +/-</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={styles.headerBold}>Week</TableCell>
          <TableCell className={styles.headerBold}>Month</TableCell>
          <TableCell className={styles.headerBold}>Week</TableCell>
          <TableCell className={styles.headerBold}>Month</TableCell>
          <TableCell className={styles.headerBold}>Week</TableCell>
          <TableCell className={styles.headerBold}>Month</TableCell>
          <TableCell className={styles.headerBold}>Week</TableCell>
          <TableCell className={styles.headerBold}>Month</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {renderTableBody()}
      </TableBody>
    </Table>
  </TableContainer>
);
}
