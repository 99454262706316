import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import gradeKPI from './GradeKPIs';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const captureTableAndDownloadPDF = (reportData, selectedMonth, selectedArea = '', gradingStandards) => {
  // Define header cells with separate style, including bold and bigger font size
  const headerCells = [
    { text: '', style: 'tableHeader' },
    { text: 'Store', style: 'tableHeader' },
	{ text: 'MTD Sales', style: 'tableHeader' },
	{ text: 'Sales % Change', style: 'tableHeader' },
	{ text: 'Controllables', style: 'tableHeader' },
	{ text: 'Production', style: 'tableHeader' },
    { text: 'Labor %', style: 'tableHeader' },
    { text: 'Variance', style: 'tableHeader' },
    { text: 'On Times', style: 'tableHeader' },
    { text: 'Ave. Time', style: 'tableHeader' },
    { text: 'Reply Time', style: 'tableHeader' },
    { text: 'Sync', style: 'tableHeader' },
    { text: 'Cash', style: 'tableHeader' }
  ];

function getDefaultFillColor() {
    return '#FFFFFF'; // White color, adjust as needed
}

const thresholds = gradingStandards;

const bodyData = reportData.map(row => [
    {
        text: row.rank.toString(),
        noWrap: true,
        style: 'boldText',
        fillColor: getDefaultFillColor()
    },
    {
        text: row.storeName,
        noWrap: true,
        style: 'boldText',
        fillColor: getDefaultFillColor() // Assuming the store name does not need color grading
    },
	{
        text: row.MtdSales,
        style: 'normalText',
        fillColor: getDefaultFillColor() // Assuming the MTD Sales does not need color grading
    },
    {
      text: row.usedAreaAverage ? '-' : row.SalesChange,
      style: row.usedAreaAverage ? 'centeredText' : 'normalText',
      fillColor: gradeKPI.SalesChange(row.SalesChange, thresholds).color
    },
	{
        text: row.ContExpenses,
        style: 'normalText',
        fillColor: gradeKPI.ContExpenses(row.ContExpenses, thresholds).color
    },
	{
        text: row.CrewProduction,
        style: 'normalText',
        fillColor: gradeKPI.CrewProduction(row.CrewProduction, thresholds).color
    },
    {
        text: row.LaborPercentage,
        style: 'normalText',
        fillColor: gradeKPI.LaborPercentage(row.LaborPercentage, thresholds).color
    },
    {
        text: row.FoodVarianceMonth,
        style: 'normalText',
        fillColor: gradeKPI.FoodVarianceMonth(row.FoodVarianceMonth, thresholds).color
    },
    {
        text: row.OnTimesPercentage,
        style: 'normalText',
        fillColor: gradeKPI.OnTimesPercentage(row.OnTimesPercentage, thresholds).color
    },
    {
        text: row.FormattedAveTime,
        style: 'normalText',
        fillColor: gradeKPI.FormattedAveTime(row.FormattedAveTime, thresholds).color
    },
    {
        text: row.FormattedReplyTime,
        style: 'normalText',
        fillColor: gradeKPI.FormattedReplyTime(row.FormattedReplyTime, thresholds).color
    },

    {
        text: row.ComplaintsPer10k,
        style: 'normalText',
        fillColor: gradeKPI.ComplaintsPer10k(row.ComplaintsPer10k, thresholds).color
    },
    {
        text: row.FormattedCash,
        style: 'normalText',
        fillColor: gradeKPI.FormattedCash(row.FormattedCash, thresholds).color
    }
]);
  
    const columnWidths = [
    10, // Width for 'Rank'
    75,    // Width for 'Store Name', takes remaining space
    45, // Width for 'MTD Sales'
    45, // Width for 'Sales % Change'
    60, // Width for 'Cont. Expenses'
    45, // Width for 'Crew Prod'
    35, // Width for 'Labor %'
    38, // Width for 'Variance'
    40, // Width for 'On Time %'
    42, // Width for 'Ave Time'
    45, // Width for 'Reply Time'
    25, // Width for 'Sync'
    45  // Width for 'Cash'
  ];

const docDefinition = {
	pageOrientation: 'landscape',
  content: [
    // Area and Month Title
    {
      text: `${selectedArea ? `${selectedArea} ` : ''}Pops Report`,
      style: 'reportTitle'
    },
    {
      text: selectedMonth,
      style: 'reportSubtitle'
    },
    // Spacing between the title and the table
    {
      text: '',
      margin: [-15, 0, 0, 10] // Adjust bottom margin for spacing
    },
    // Table Definition
    {
      table: {
        headerRows: 1,
        widths: columnWidths,
        body: [
          headerCells, // Include customized header cells here
          ...bodyData
        ]
      }
    }
  ],
    styles: {
      reportTitle: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 50, 0] // Adjust as needed for your specific design
      },
      reportSubtitle: {
        fontSize: 12,
        alignment: 'center',
        margin: [0, 5, 50, 0] // Adjust as needed for your specific design
      },
      tableHeader: {
        bold: true,
        fontSize: 9,
        color: 'black',
        alignment: 'center'
      },
      boldText: {
        bold: true,
        fontSize: 8,
      },
      normalText: {
        fontSize: 8,
      },
      centeredText: {
        fontSize: 8,
        alignment: 'center'
      }
    },
    pageMargins: [85, 20, 20, 10]
  };

pdfMake.createPdf(docDefinition).download('pops_report.pdf');
};