import React from 'react';
import NavBar from './NavBar';
import { Outlet } from 'react-router-dom';
import MainContent from './MainContent';
import styles from './BaseLayout.module.css';

function BaseLayout() {
  return (
    <div className={styles.container}>
      <NavBar />
      <div className={styles.mainContent}>
        <Outlet /> {/* This will render the matched nested route components */}
      </div>
    </div>
  );
}

export default BaseLayout;
