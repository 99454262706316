// src/accessControl.js

export const storeOperationsUsers = [
  'Jboltin',
  'Ewinters',
  'jnauertc',
  'Knickell',
  'Zakin',
  'store5302',
  'sonic6715',
  'store1022',
  'store1212',
  'sonic3645',
  'store6744',
  'sonic6841',
  'stutton',
  'psexton',
];
