// BusinessPage.js
import React from 'react';
import NavBar from './NavBar'; // Adjust path as necessary
import HeroSection from './HeroSection'; // Adjust path as necessary
import Services from './Services'; // Adjust path as necessary
import AboutUs from './AboutUs'; // Adjust path as necessary
import Footer from './Footer';
import ChatWidget from './ChatWidget'; 
import './biz.global.css'; // Ensure this path correctly points to your CSS file

function BusinessPage() {
  return (
    <div>
      <NavBar />
      <HeroSection />
      <Services />
      <AboutUs />
	  <Footer />
	  <ChatWidget />
      {/* Additional sections or components go here */}
    </div>
  );
}

export default BusinessPage;
