//PrFileUpload.js front end
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import ProgressButton from 'react-progress-button';
import 'react-progress-button/react-progress-button.css';
import styles from './PrFileUpload.module.css';

function PrFileUpload() {
  const [file, setFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonState, setButtonState] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [userRole, setUserRole] = useState(''); // State to store the user role
  const [warnings, setWarnings] = useState([]);


  useEffect(() => {
    const fetchStores = async () => {
      const baseUrl = getBaseUrl();
      try {
        const { data: { userGroup } } = await axios.get(`${baseUrl}/api/user-group`, { withCredentials: true });
        setUserRole(userGroup); // Update the userRole state based on the fetched user group

        if (userGroup === 'supervisor' || userGroup === 'director') {
          const storesUrl = userGroup === 'supervisor' ? `${baseUrl}/api/store-selection/stores-for-supervisor` : `${baseUrl}/api/store-selection/stores-for-director`;
          const { data: storesData } = await axios.get(storesUrl, { withCredentials: true });
          setStores(storesData);
        } else {
          // If the user is not a supervisor or director, you can clear the stores or handle as needed
          setStores([]);
        }
      } catch (error) {
        console.error('Error fetching stores:', error);
        Swal.fire('Error', 'Failed to fetch stores.', 'error');
      }
    };

    fetchStores();
  }, []);
  
    const toggleFormVisibility = () => {
    setIsFormVisible(!isFormVisible);
	setButtonState('')
  };

  const getBaseUrl = () => {
    if (process.env.NODE_ENV === 'development') {
      return 'http://72.167.34.236:5000';
    }
    return 'https://prolifi.app';
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setErrorMessage('');
    setPdfUrl('');
	setButtonState('');
	setWarnings([]);
  };

const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
        Swal.fire('Oops...', 'Please select a file first', 'error');
        setButtonState('error');
        return;
    }

    setButtonState('loading');
    const formData = new FormData();
    formData.append('file', file);

    const sendEmailReport = async (storeID, pdfPath) => {
        try {
            const baseUrl = getBaseUrl();
            const forceSendResponse = await axios.post(
                `${baseUrl}/api/forceSendEmail`,
                { storeID, pdfPath },
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            Swal.fire('Sent!', forceSendResponse.data.message, 'success');
            setButtonState('success');
			
        } catch (error) {
            Swal.fire('Error', 'Failed to send the report.', 'error');
            setButtonState('error');
        }
    };

    try {
        const baseUrl = getBaseUrl();
        const response = await axios.post(
            `${baseUrl}/api/prFileUpload`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
                withCredentials: true
            }
        );

        console.log("Response data:", response.data);  // Log the response data

        if (response.data.excelPath) {
            const excelFileName = response.data.excelPath.split('/').pop();
            const downloadUrl = `${baseUrl}/api/download/${excelFileName}`;
            window.open(downloadUrl, '_blank');
            setButtonState('success');
        }

        if (response.data.pdfPath) {
            const pdfFileName = response.data.pdfPath.split('/').pop();
            const viewUrl = `${baseUrl}/api/view-pdf/${pdfFileName}`;
            setPdfUrl(viewUrl);
            setButtonState('success');
        }
		
		

        // Check if it's not a typical payroll processing day
        if (response.data.status === 'not_payroll_day') {
            Swal.fire({
                title: 'Are you sure?',
                text: 'Today is not a typical payroll processing day. Do you still want to email the report to your supervisor?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, send it!',
                cancelButtonText: 'No!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const { storeID, pdfPath } = response.data;
                    await sendEmailReport(storeID, pdfPath);
                }
            });
			
					if (response.data.warnings && response.data.warnings.length > 0) {
			setWarnings(response.data.warnings); // Save warnings from the response
		} else {
			setWarnings([]); // Clear any existing warnings if there are none in the response
		}
		
        } else {
            // Automatically send the report on typical payroll days
            const { storeID, pdfPath } = response.data;
            await sendEmailReport(storeID, pdfPath);
        }
    } catch (error) {
        console.error('Upload error:', error);
        let errMessage = error.response && error.response.data ? error.response.data : 'An unexpected error occurred';
        setErrorMessage(errMessage);
        setButtonState('error');
    }
};

const handleStoreChange = async (e) => {
    const storeId = e.target.value;
    setSelectedStore(storeId);

    // Update the session on the server with the selected store ID
    try {
        const baseUrl = getBaseUrl();
        await axios.post(`${baseUrl}/api/update-selected-store`, { storeId }, { withCredentials: true });
        // Optional: Show success message or handle the updated session state
    } catch (error) {
        console.error('Error updating session with selected store:', error);
        // Optional: Show error message
    }
};


  return (
    <div className={styles.mainContainer}>
      <div className={styles.uploadContainer}>
        <button onClick={toggleFormVisibility} className={styles.toggleBar}>
          {isFormVisible ? '▲ Hide Form' : '▼ Show Form'}
        </button>

        {isFormVisible && (
          <form onSubmit={handleUpload} className={styles.form}>
            <h1 className={styles.mainTitle}>ProlifiConverter</h1>
            <h2 className={styles.secondaryTitle}>A Payroll Timecard Converter</h2>
            {/* Conditionally render the dropdown based on user role */}
            {(userRole === 'supervisor' || userRole === 'director') && (
              <div className={styles.dropdownContainer}>
                <label htmlFor="storeSelect">Select Store:</label>
                <select id="storeSelect" value={selectedStore} onChange={handleStoreChange} className={styles.storeDropdown}>
                  <option value="">Select a Store</option>
                  {stores.map((storeID) => (
                    <option key={storeID.toString()} value={storeID}>
                      {storeID}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <input type="file" onChange={handleFileChange} className={styles.input} />
            <ProgressButton state={buttonState} className={`${styles.progressButton} ${styles.customButton}`}>
              Process
            </ProgressButton>
			
			  {warnings.length > 0 && (
			<div>
			  {warnings.map((warning, index) => (
				<div key={index} className={styles.warningMessage}>
				  {warning}
				</div>
			  ))}
			</div>
		  )}
			
          </form>
        )}
      </div>

    {errorMessage && (
      <div className={styles.errorMessage} dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
    )}

    {pdfUrl && (
      <div className={styles.pdfViewerContainer}>
        <iframe src={pdfUrl} className={styles.pdfViewer} title="PDF Preview"></iframe>
      </div>
    )}
  </div>
);
}

export default PrFileUpload;
