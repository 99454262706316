import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import PopsReportTable from './PopsReportTable';
import styles from './PopsReport.module.css';
import calculateScores from './CalculateScores';
import { captureTableAndDownloadPDF } from './generatePopsReportPDF';
import { captureTableAndDownloadExcel } from './generatePopsReportExcel';
import { FaFilePdf, FaFileExcel } from 'react-icons/fa'; // Import FaFileExcel
import LoadingIndicator from '../LoadingIndicator';
import ExcelJS from 'exceljs';

function PopsReport() {
  const [selectedMonth, setSelectedMonth] = useState(formatMonth(new Date()));
  const [userGroup, setUserGroup] = useState('');
  const [selectedArea, setSelectedArea] = useState('Area Selection');
  const [directorAreas] = useState([
    'Area Selection',
    'Esch Group',
    'Northwest Arkansas',
    'Central Arkansas',
    'Tennessee',
    'St Louis',
    'Champaign',
    'Kansas & Nebraska',
  ]);

  const [gradingStandardsLoaded, setGradingStandardsLoaded] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [gradingStandards, setGradingStandards] = useState({});
  const [areaAveragesState, setAreaAveragesState] = useState([]);

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '20px',
      borderColor: 'rgba(0, 123, 255, 0.5)',
      boxShadow: 'none',
      '&:hover': { borderColor: 'rgba(0, 123, 255, 0.7)' },
      backgroundColor: 'white',
      minWidth: '175px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'blue' : 'black',
      backgroundColor: state.isFocused ? 'rgba(0, 123, 255, 0.1)' : state.isSelected ? 'lightgray' : 'null',
      '&:active': {
        backgroundColor: state.isSelected ? 'darkgray' : 'rgba(0, 123, 255, 0.2)',
      },
    }),
    menu: (provided) => ({
      ...provided,
      minWidth: '175px',
      zIndex: 9999,
    }),
  };

  function formatMonth(date) {
    return date.toLocaleString('default', { month: 'long', year: 'numeric' });
  }

  const getBaseUrl = () => {
    const hostname = window.location.hostname;

    if (hostname === '72.167.34.236') {
      return 'http://72.167.34.236:5000';
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return 'https://prolifi.app';
    } else {
      return 'http://localhost:5000';
    }
  };

  const months = Array.from({ length: 12 }, (_, i) => {
    const d = new Date();
    d.setMonth(d.getMonth() - i);
    return formatMonth(d);
  });

  const selectMonths = months.map(month => ({ value: month, label: month }));
  const selectDirectorAreas = directorAreas.map(area => ({ value: area, label: area }));

  useEffect(() => {
    const getUserGroupAndCompany = async () => {
      try {
        const baseUrl = getBaseUrl();
        const response = await axios.get(`${baseUrl}/api/user-store`, { withCredentials: true });
        setUserGroup(response.data.userGroup);
        if (response.data.userGroup === 'director') {
          setSelectedArea('Kansas & Nebraska'); // Temporarily set to 'Esch Group'
          setTimeout(() => {
            setSelectedArea('Area Selection'); // Then back to 'Area Selection'
          }, 0); // Delay the change to ensure the data pull
        } else {
         setSelectedArea('Kansas & Nebraska'); // Temporarily set to 'Esch Group'
          setTimeout(() => {
            setSelectedArea(''); // Then back to 'Area Selection'
          }, 0); // Optionally set a non-director-specific default
        }
        fetchGradingStandards(); // Fetch default grading standards after user group is determined
      } catch (error) {
        console.error('Error fetching user group and area:', error);
      }
    };

    getUserGroupAndCompany();
  }, []);

  const fetchGradingStandards = async () => {
    let areaToFetch = selectedArea;

    if (!selectedArea || selectedArea === 'Area Selection') {
      areaToFetch = 'Default';
    }

    setIsLoading(true);
    try {
      const baseUrl = getBaseUrl();
      const encodedArea = encodeURIComponent(areaToFetch);
      const url = `${baseUrl}/api/grading-standards/${encodedArea}`;
      const response = await axios.get(url, { withCredentials: true });

      if (response.data) {
        setGradingStandards(response.data);
        setGradingStandardsLoaded(true);
      } else {
        console.error('No grading standards returned for:', areaToFetch);
        setGradingStandards({});
      }
    } catch (error) {
      console.error('Error fetching grading standards:', error);
      setGradingStandards({});
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!selectedArea || selectedArea === 'Area Selection') {
      console.log("Selected area is 'Area Selection', skipping fetch.");
      setGradingStandards({});
      setGradingStandardsLoaded(false);
      return;
    }
    setIsLoading(true);

    const fetchGradingStandards = async () => {
      try {
        const baseUrl = getBaseUrl();
        const encodedArea = encodeURIComponent(selectedArea);
        const url = `${baseUrl}/api/grading-standards/${encodedArea}`;
        const response = await axios.get(url, { withCredentials: true });

        if (response.data) {
          setGradingStandards(response.data);
          setGradingStandardsLoaded(true);
        } else {
          console.error('No grading standards returned for:', selectedArea);
          setGradingStandards({});
        }
      } catch (error) {
        console.error('Error fetching grading standards:', error);
        setGradingStandards({});
      }
      setIsLoading(false);
    };

    fetchGradingStandards();
  }, [selectedArea]);

  function parseSelectedMonth(selectedMonth) {
    const [monthName, year] = selectedMonth.split(' ');
    const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();
    return { monthIndex, year: parseInt(year) };
  }

  function getDaysInMonth(monthIndex, year) {
    return new Date(year, monthIndex + 1, 0).getDate();
  }

  function daysElapsedInMonth(selectedMonth, currentDate) {
    const { monthIndex, year } = parseSelectedMonth(selectedMonth);
    const today = new Date(currentDate);
    const selectedDateStart = new Date(year, monthIndex, 1);
    const selectedDateEnd = new Date(year, monthIndex + 1, 0);

    if (today >= selectedDateStart && today <= selectedDateEnd) {
      return today.getDate();
    } else if (today > selectedDateEnd) {
      return getDaysInMonth(monthIndex, year);
    } else {
      return 0;
    }
  }

  const calculateAreaAverageSalesChange = (data) => {
    const areaSalesChanges = {};
    const areaCounts = {};

    data.forEach(item => {
      if (item.SalesChange !== 'N/A') {
        const change = parseFloat(item.SalesChange.replace('%', ''));
        if (areaSalesChanges[item.storeArea]) {
          areaSalesChanges[item.storeArea].push(change);
          areaCounts[item.storeArea] += 1;
        } else {
          areaSalesChanges[item.storeArea] = [change];
          areaCounts[item.storeArea] = 1;
        }
      }
    });

    const trimmedMean = (changes) => {
      if (changes.length <= 2) return 0;
      changes.sort((a, b) => a - b);
      changes.pop();
      changes.shift();
      const sum = changes.reduce((acc, val) => acc + val, 0);
      return sum / changes.length;
    };

    const kansasNebraskaChanges = (areaSalesChanges['KANSAS'] || []).concat(areaSalesChanges['NEBRASKA'] || []);
    const kansasNebraskaAverage = kansasNebraskaChanges.length > 2 ? trimmedMean(kansasNebraskaChanges).toFixed(2) : 0;

    const areaAverages = Object.keys(areaSalesChanges).map(area => {
      if (area === 'KANSAS' || area === 'NEBRASKA') {
        return {
          area: area,
          averageSalesChange: `${kansasNebraskaAverage}%`
        };
      } else {
        const changes = areaSalesChanges[area];
        const averageChange = changes.length > 2 ? trimmedMean(changes).toFixed(2) : 0;
        return {
          area: area,
          averageSalesChange: `${averageChange}%`
        };
      }
    });

    return areaAverages;
  };

  const calculateSalesChange = (currentSales, lastYearSales, storeArea, areaAveragesState) => {
    if (lastYearSales === 0) {
      if (currentSales === 0) {
        return { salesChange: '0%', usedAreaAverage: false };
      }
      const areaAverage = areaAveragesState.find(area => area.area === storeArea);
      if (areaAverage) {
        return { salesChange: `${areaAverage.averageSalesChange}`, usedAreaAverage: true };
      } else {
        return { salesChange: 'N/A', usedAreaAverage: false };
      }
    }
    const change = ((currentSales - lastYearSales) / lastYearSales) * 100;
    return { salesChange: `${change.toFixed(2)}%`, usedAreaAverage: false };
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); 
      try {
        const baseUrl = getBaseUrl();
        let url = `${baseUrl}/api/pops-report/${selectedMonth}`;
        if (userGroup === 'director') {
          url += `/${selectedArea}`;
        }
        const response = await axios.get(url, { withCredentials: true });
        
        const transformedData = Object.keys(response.data).map(key => {
          const store = response.data[key];

          const isNumeric = (value) => !isNaN(parseFloat(value)) && isFinite(value);
          const calculatePercentage = (part, total) => {
            if (isNumeric(part) && isNumeric(total) && total !== 0) {
              return `${((parseFloat(part) / parseFloat(total)) * 100).toFixed(2)}%`;
            }
            return 'N/A';
          };
          const formatTime = (seconds) => {
            if (isNumeric(seconds)) {
              const mins = Math.floor(parseInt(seconds, 10) / 60);
              const secs = parseInt(seconds, 10) % 60;
              return `${mins}:${secs.toString().padStart(2, '0')}`;
            }
            return 'N/A';
          };
          const calculateDollarAmount = (value) => {
            return isNumeric(value) ? `$${parseFloat(value).toFixed(2)}` : 'N/A';
          };
          const calculateComplaintsPer10k = (complaints, tickets) => {
            if (isNumeric(complaints) && isNumeric(tickets) && tickets !== 0) {
              return ((parseFloat(complaints) / parseFloat(tickets)) * 10000).toFixed(2);
            }
            return 'N/A';
          };
          const formattedCash = (value) => {
            const num = Number(value);
            if (isNaN(num)) return 'N/A';
            const absValue = Math.abs(num);
            const formattedValue = absValue.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
            return num < 0 ? `(${formattedValue})` : formattedValue;
          };
          const formattedFoodVariance = (value) => {
            const num = Number(value);
            return isNaN(num) ? 'N/A' : num.toFixed(2);
          };
          const formattedMTDSales = (value) => {
            const num = Number(value);
            return isNaN(num) ? 'N/A' : num.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
          };
          const formatFandPCost = (value) => {
            if (value === null) return 'N/A';
            const num = Number(value);
            if (!isNaN(num)) {
              return `${num.toFixed(2)}%`;
            }
            return 'N/A';
          };

          const currentDate = new Date();
          const { monthIndex, year } = parseSelectedMonth(selectedMonth);
          const daysInSelectedMonth = getDaysInMonth(monthIndex, year);
          const daysElapsed = daysElapsedInMonth(selectedMonth, currentDate);

          const costPerDay = 3948 / daysInSelectedMonth;
          const managerProRate = costPerDay * daysElapsed;

          const mtdSalesNumeric = Number(store.NetSales);
          const proRatedManagerLaborNumeric = parseFloat(managerProRate.toFixed(2));

          let proRatedManagerLaborPercentage = 'N/A';
          if (!isNaN(mtdSalesNumeric) && mtdSalesNumeric > 0 && !isNaN(proRatedManagerLaborNumeric)) {
            proRatedManagerLaborPercentage = ((proRatedManagerLaborNumeric / mtdSalesNumeric) * 100).toFixed(2) + '%';
          }

          const cLaborPercentage = calculatePercentage(store.Labor, store.NetSales);
          const cActualFandPCost = store.actualFandPCost;
          const cProRatedManagerLabor = proRatedManagerLaborPercentage;
          const { salesChange, usedAreaAverage } = calculateSalesChange(parseFloat(store.NetSales), parseFloat(store.LastYearNetSales), store.Area, areaAveragesState);

          let contExpenses = (parseFloat(cLaborPercentage) + parseFloat(cActualFandPCost) + parseFloat(cProRatedManagerLabor)).toFixed(2);

          return {
            storeId: key,
            storeName: store.storeName,
            storeArea: store.Area,
            LaborPercentage: calculatePercentage(store.Labor, store.NetSales),
            CrewProduction: calculateDollarAmount(isNumeric(store.NetSales) && isNumeric(store.TotalHours) ? parseFloat(store.NetSales) / parseFloat(store.TotalHours) : undefined),
            OnTimesPercentage: calculatePercentage(store.OnTime, store.Tickets),
            FormattedAveTime: formatTime(store.AveTime),
            FormattedReplyTime: formatTime(store.ReplyTime),
            MysteryShopScore: store.mysteryShopScore ? store.mysteryShopScore.toString() : 'N/A',
            SalesChange: salesChange,
            ComplaintsPer10k: calculateComplaintsPer10k(store.mtdSyncReports, store.Tickets),
            FormattedCash: formattedCash(store.Cash),
            FoodVarianceMonth: formattedFoodVariance(store.foodVarianceMonth),
            MtdSales: formattedMTDSales(store.NetSales),
            ActualFandPCost: formatFandPCost(store.actualFandPCost),
            ProRatedManagerLabor: proRatedManagerLaborPercentage,
            ContExpenses: contExpenses + '%',
            usedAreaAverage: usedAreaAverage,
          };
        });
        
        console.log('TForm Data1:', transformedData);

        const areaAverageSalesChanges = calculateAreaAverageSalesChange(transformedData);
        setAreaAveragesState(areaAverageSalesChanges);
        console.log('Area Average Sales Changes State:', areaAveragesState);

        console.log('Transformed Data:', transformedData);
        console.log('Area Average Sales Changes:', areaAverageSalesChanges);

        const scoredData = calculateScores(transformedData, gradingStandards, areaAveragesState);

        const mergedData = transformedData.map(store => {
          const scoreDataForStore = scoredData.find(scored => scored.storeId === store.storeId);
          return { ...store, overallScore: scoreDataForStore ? scoreDataForStore.overallScore : 0 };
        });

        let sortedAndRankedData;
        if (selectedArea === 'Esch Group' || selectedArea === 'Area Selection' || !selectedArea) {
          sortedAndRankedData = [...mergedData].sort((a, b) => a.storeName.localeCompare(b.storeName));
          sortedAndRankedData.forEach(store => { store.rank = ''; });
        } else {
          sortedAndRankedData = [...mergedData].sort((a, b) => b.overallScore - a.overallScore);
          sortedAndRankedData.forEach((store, index) => { store.rank = index + 1; });
        }

        console.log(sortedAndRankedData);

        setReportData(sortedAndRankedData);
        console.log('REPORT Data:', reportData);
      } catch (error) {
        console.error('Error fetching Pops Report data:', error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [selectedMonth, selectedArea, userGroup]);

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption.value);
  };

  const handleAreaChange = (selectedOption) => {
    if (selectedOption.value === 'Area Selection') {
      console.log("Area Selection is set to placeholder, no operations will be performed.");
      setSelectedArea(selectedOption.value);
      return;
    }
    setSelectedArea(selectedOption.value);
  };

  const handleDownloadPDF = async () => {
    setIsLoading(true);
    await captureTableAndDownloadPDF(reportData, selectedMonth, selectedArea, gradingStandards);
    setIsLoading(false);
  };

  const handleDownloadExcel = async () => {
    await captureTableAndDownloadExcel(reportData, selectedMonth, selectedArea, gradingStandards, areaAveragesState);
  };

  return (
    <div className="pageContainer">
      {isLoading && <LoadingIndicator />}
      <div className={styles.customTitle}>Pops Report</div>
      <div className={styles.selectionContainer}>
        <Select
          options={selectMonths}
          onChange={handleMonthChange}
          styles={customSelectStyles}
          value={selectMonths.find(option => option.value === selectedMonth)}
        />
        {userGroup === 'director' && (
          <Select
            options={selectDirectorAreas}
            onChange={handleAreaChange}
            styles={customSelectStyles}
            value={selectDirectorAreas.find(option => option.value === selectedArea)}
          />
        )}
      </div>
      
      {(userGroup !== 'director' || (gradingStandardsLoaded && selectedArea !== 'Area Selection')) ? (
        <div id="tableToCaptureId" className={styles.reportContainer}>
          <PopsReportTable data={reportData} thresholds={gradingStandards} />
        </div>
      ) : (
        <div className={styles.centerContainer}>
          <div className={styles.loadingContainer}>
            {userGroup === 'director' ? "Loading Grading Standards or waiting for area selection..." : "Loading Grading Standards..."}
          </div>
        </div>
      )}
      
      <div className={styles.buttonContainer}>
        <button className={styles.downloadButton} onClick={handleDownloadPDF} aria-label="Download PDF" title="Download PDF">
          <FaFilePdf size={18} />
        </button>
        <button className={styles.downloadButton} onClick={handleDownloadExcel} aria-label="Download Excel" title="Download Excel">
          <FaFileExcel size={18} />
        </button>
      </div>
    </div>
  );
}

export default PopsReport;
