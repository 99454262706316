import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import styles from './StoreUserManagement.module.css';

function StoreUserManagement() {
  const [storeUsers, setStoreUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [formData, setFormData] = useState({
    id: null,
    Name: '',
    Code: '',
    Permissions: '0000000'  // Updated to 7 characters
  });
  const [errors, setErrors] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const getBaseUrl = () => {
    const hostname = window.location.hostname;

    if (hostname === '72.167.34.236') {
      return 'http://72.167.34.236:5000';
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return 'https://prolifi.app';
    } else {
      console.error('Unknown host');
      return ''; // Return an empty string or a default URL if preferred
    }
  };

  useEffect(() => {
    const fetchStoreUsers = async () => {
      try {
        const baseUrl = getBaseUrl();
        const response = await axios.get(`${baseUrl}/api/store/user-store`, { withCredentials: true });
        setStoreUsers(response.data);
      } catch (error) {
        console.error('Error fetching store users:', error);
      }
    };

    fetchStoreUsers();
  }, []);

  useEffect(() => {
    if (selectedUser === 'create-new') {
      setFormData({
        id: null,
        Name: '',
        Code: '',
        Permissions: '0000000'  // Updated to 7 characters
      });
    } else if (selectedUser) {
      const user = storeUsers.find(user => user.Name === selectedUser);
      setFormData(user || {
        id: null,
        Name: '',
        Code: '',
        Permissions: '0000000'  // Updated to 7 characters
      });
    }
  }, [selectedUser, storeUsers]);

  const handleChange = (e) => {
    setSelectedUser(e.target.value);
    setErrors({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handlePermissionChange = (e) => {
    const { name, checked } = e.target;
    let permissionsArray = formData.Permissions.split('');

    switch (name) {
      case 'CashCount':
        permissionsArray[5] = checked ? '1' : '0';
        break;
      case 'CorrectiveActions':
        permissionsArray[4] = checked ? '1' : '0';
        break;
      case 'WriteUpAssistantManagers':
        permissionsArray[3] = checked ? '1' : '0';
        break;
      case 'WriteUpMIT':
        permissionsArray[2] = checked ? '1' : '0';
        break;
      case 'WriteUpGeneralManager':
        permissionsArray[1] = checked ? '1' : '0';
        break;
      case 'Terminate':
        permissionsArray[0] = checked ? '1' : '0';
        break;
      case 'ClosingChecklist':  // New case for Closing Checklist
        permissionsArray[6] = checked ? '1' : '0';
        break;
      default:
        break;
    }

    setFormData({
      ...formData,
      Permissions: permissionsArray.join('')
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.Name) {
      newErrors.Name = 'Name is required';
    } else if (storeUsers.some(user => user.Name === formData.Name && user.Name !== selectedUser)) {
      newErrors.Name = 'Name is already in use';
    }

    if (!formData.Code) {
      newErrors.Code = 'Code is required';
    } else if (storeUsers.some(user => user.Code === formData.Code && user.Name !== selectedUser)) {
      newErrors.Code = 'Code is already in use';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const baseUrl = getBaseUrl();
        await axios.post(`${baseUrl}/api/store/user-store`, formData, { withCredentials: true });
        setSnackbarMessage('Form submitted successfully.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        // Refetch store users to reflect the changes
        const response = await axios.get(`${baseUrl}/api/store/user-store`, { withCredentials: true });
        setStoreUsers(response.data);

      } catch (error) {
        console.error('Error submitting form:', error);
        setSnackbarMessage('Error submitting form. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage('Form validation failed. Please correct the errors and try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const isCorrectiveActionsEnabled = formData.Permissions[4] === '1';

return (
  <div className={styles.container}>
    <div className={styles.title}>Store User Management</div>
    <div className={styles.formGroup}>
      <label htmlFor="userSelect" className={styles.label}>Select User: </label>
      <select
        id="userSelect"
        className={styles.select}
        value={selectedUser}
        onChange={handleChange}
      >
        <option value="" disabled>Select a user</option>
        {storeUsers.map((user, index) => (
          <option key={index} value={user.Name}>{user.Name}</option>
        ))}
        <option value="create-new">Create New</option>
      </select>
    </div>
    <form onSubmit={handleSubmit}>
      <div className={styles.formGroup}>
        <label htmlFor="name" className={styles.label}>Name: </label>
        <input
          type="text"
          id="name"
          name="Name"
          className={styles.input}
          value={formData.Name}
          onChange={handleInputChange}
          required
        />
        {errors.Name && <span className={styles.error}>{errors.Name}</span>}
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="code" className={styles.label}>Code: </label>
        <input
          type="text"
          id="code"
          name="Code"
          className={styles.input}
          value={formData.Code}
          onChange={handleInputChange}
          required
        />
        {errors.Code && <span className={styles.error}>{errors.Code}</span>}
      </div>
      <div className={styles.checkboxGroup}>
        <label className={styles.groupTitle}>Individual Permissions</label>
        <label>
          <input
            type="checkbox"
            id="cashCount"
            name="CashCount"
            checked={formData.Permissions[5] === '1'}
            onChange={handlePermissionChange}
          />
          Cash Count
        </label>
      </div>
      <div className={`${styles.checkboxGroup} ${!isCorrectiveActionsEnabled ? styles.disabled : ''}`}>
        <label className={styles.groupTitle}>Corrective Actions</label>
        <label>
          <input
            type="checkbox"
            id="correctiveActions"
            name="CorrectiveActions"
            checked={formData.Permissions[4] === '1'}
            onChange={handlePermissionChange}
          />
          Enable Corrective Actions
        </label>
        <label>
          <input
            type="checkbox"
            id="writeUpAssistantManagers"
            name="WriteUpAssistantManagers"
            checked={formData.Permissions[3] === '1'}
            onChange={handlePermissionChange}
            disabled={!isCorrectiveActionsEnabled}
          />
          Can Write Up Assistant Managers
        </label>
        <label>
          <input
            type="checkbox"
            id="writeUpMIT"
            name="WriteUpMIT"
            checked={formData.Permissions[2] === '1'}
            onChange={handlePermissionChange}
            disabled={!isCorrectiveActionsEnabled}
          />
          Can Write Up MIT
        </label>
        <label>
          <input
            type="checkbox"
            id="writeUpGeneralManager"
            name="WriteUpGeneralManager"
            checked={formData.Permissions[1] === '1'}
            onChange={handlePermissionChange}
            disabled={!isCorrectiveActionsEnabled}
          />
          Can Write Up General Manager
        </label>
        <label>
          <input
            type="checkbox"
            id="terminate"
            name="Terminate"
            checked={formData.Permissions[0] === '1'}
            onChange={handlePermissionChange}
            disabled={!isCorrectiveActionsEnabled}
          />
          Can Terminate
        </label>
      </div>
      <div className={styles.checkboxGroup}>
        <label className={styles.groupTitle}>Closing Checklist</label>
        <label>
          <input
            type="checkbox"
            id="closingChecklist"
            name="ClosingChecklist"
            checked={formData.Permissions[6] === '1'}
            onChange={handlePermissionChange}
          />
          Can Use Closing Checklist
        </label>
      </div>
      <button type="submit" className={styles.submitBtn}>Submit</button>
    </form>
    <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
      <MuiAlert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
        {snackbarMessage}
      </MuiAlert>
    </Snackbar>
  </div>
);

}

export default StoreUserManagement;
