//exportToExcel.js

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import createSalesReportSheet from './createSalesReportSheet';
import createProfitabilityReportSheet from './createProfitabilityReportSheet'; // Assume you have this
import createServiceReportSheet from './createServiceReportSheet';
import { applyFormatting, applyProfitabilityFormatting, applyServiceFormatting } from './formatSheet';

const exportToExcel = async (weeklySalesArray, aggregatedWeekGroupData, startDate, endDate, selectedWeek, selectedYear) => {
  // Create a new workbook
  const workbook = new ExcelJS.Workbook();
  
  // Add the Weekly Sales Report worksheet to the workbook
  const salesWorksheet = workbook.addWorksheet('Weekly Sales Report');
  // Call the function that generates the sales report data for the Excel sheet
  const salesWorksheetData = createSalesReportSheet(weeklySalesArray, aggregatedWeekGroupData, startDate, endDate);
  // Use the generated data to populate the sales worksheet
  salesWorksheetData.forEach((rowData, index) => {
    const row = salesWorksheet.getRow(index + 1);
    row.values = rowData;
  });
  
  const lastRow = weeklySalesArray.length + 5;
  // Apply formatting styles to the sales worksheet
  applyFormatting(salesWorksheet, lastRow);

  // Add the Profitability worksheet to the workbook
  const profitabilityWorksheet = workbook.addWorksheet('Profitability');
  // Call the function that generates the profitability report data for the Excel sheet
  // This function needs to be defined by you to provide the relevant data.
  const profitabilityWorksheetData = createProfitabilityReportSheet(weeklySalesArray);
  // Use the generated data to populate the profitability worksheet
  profitabilityWorksheetData.forEach((rowData, index) => {
    const row = profitabilityWorksheet.getRow(index + 1);
    row.values = rowData;
  });
  // Apply formatting styles to the profitability worksheet
  // You may need to create a separate formatting function for this sheet if the formatting differs from the sales report.
  applyProfitabilityFormatting(profitabilityWorksheet);
  
  const serviceWorksheet = workbook.addWorksheet('Service');
  const serviceWorksheetData = createServiceReportSheet(weeklySalesArray); 
  // Fill in the 'Service' worksheet title and headers as needed
  serviceWorksheet.getCell('C1').value = 'Weekly Service Numbers';  // Title
  serviceWorksheet.getCell('D3').value = 'Ave Times';  // Subtitle

// Populate the 'Service' worksheet with data starting from row 4
serviceWorksheetData.forEach((rowData, index) => {
  const row = serviceWorksheet.getRow(index + 4);  // Headers start at row 4
  row.values = rowData;
});

applyServiceFormatting(serviceWorksheet);

  // Generate a file name for the Excel file
  const fileName = `Weekly_Sales_and_Profitability_Report_${selectedWeek}_${selectedYear}.xlsx`;

  // Write to a buffer, then use FileSaver to save file
  const buffer = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
};

export default exportToExcel;