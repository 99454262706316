import React, { useState } from 'react';
import axios from 'axios';

function UserCreationForm() {
  const [formData, setFormData] = useState({
    username: '',
    password: 'Sonic123', // Default password
    full_name: '',
    usergroup: '',
    company: '',
    store_id: '',
  });
  const [formStatus, setFormStatus] = useState('');
  
const getBaseUrl = () => {
  const hostname = window.location.hostname;

  // This regular expression will match both 'www.prolifi.app' and 'prolifi.app'
  if (hostname === '72.167.34.236') {
    return 'http://72.167.34.236:5000';
  } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
    return 'https://prolifi.app';
  } else {
    return 'http://localhost:5000'; // For local development
  }
};


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormStatus('Submitting...');
    const baseUrl = getBaseUrl();
    
    try {
      const response = await axios.post(`${baseUrl}/api/auth/create-user`, formData);
      console.log('User created:', response.data);
      setFormStatus('User created successfully!');
      setFormData({ username: '', password: 'Sonic123', full_name: '', usergroup: '', company: '', store_id: '' }); // Reset form
    } catch (error) {
      console.error('Error creating user:', error);
      setFormStatus('Error creating user. Please try again.');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="username"
          placeholder="Username"
          value={formData.username}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="full_name"
          placeholder="Full Name"
          value={formData.full_name}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="usergroup"
          placeholder="User Group"
          value={formData.usergroup}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="company"
          placeholder="Company"
          value={formData.company}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="store_id"
          placeholder="Store ID"
          value={formData.store_id}
          onChange={handleChange}
          required
        />
        <button type="submit">Create User</button>
      </form>
      {formStatus && <p>{formStatus}</p>}
    </div>
  );
}

export default UserCreationForm;
