//PopsReportTable.js
import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import gradeKPI from './GradeKPIs'; // Ensure this path matches where your GradeKPIs.js file is located

function PopsReportTable({ data, thresholds }) {
	
	//console.log('Received thresholds in PopsReportTable:', thresholds);

  // Custom style for data cells
  const dataCellStyle = {
    padding: '5px', // Adjust padding as needed
	border: '.5px solid #f0f0f0',
  };

  // Custom style for header cells
  const headerCellStyle = {
    fontWeight: 'bold',
    fontSize: '12px', // Example size, adjust as needed
    backgroundColor: '#f0f0f0', // Light grey background for the header
    color: '#000', // Black text color for the header
    padding: '1px', // Adjust padding as needed
	height: '10px',
	width: '125px',
  };
  
  //console.log("-----POPS TABLE BUILD-----");

  return (
    <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
		    <TableCell style={headerCellStyle}>Rank</TableCell>
            <TableCell style={headerCellStyle}>Store Name</TableCell>
			<TableCell align="center" style={headerCellStyle}>MTD Sales</TableCell>
			<TableCell align="center" style={headerCellStyle}>Sales % Change</TableCell>
			<TableCell align="center" style={headerCellStyle}>Cont. Expenses</TableCell>
			<TableCell align="center" style={headerCellStyle}>Crew Production</TableCell>
            <TableCell align="center" style={headerCellStyle}>Labor %</TableCell>
			<TableCell align="center" style={headerCellStyle}>Food Variance</TableCell>          
            <TableCell align="center" style={headerCellStyle}>On Times %</TableCell>
            <TableCell align="center" style={headerCellStyle}>Average Time</TableCell>
            <TableCell align="center" style={headerCellStyle}>Reply Time</TableCell>
            <TableCell align="center" style={headerCellStyle}>Complaints/10k</TableCell>
            <TableCell align="center" style={headerCellStyle}>Cash</TableCell>
            
			
            
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.storeId}>
			<TableCell align="right" style={dataCellStyle}>{row.rank}</TableCell>
              <TableCell component="th" scope="row" align="right" style={dataCellStyle}>
                {row.storeName}
              </TableCell>
			  <TableCell align="center" style={dataCellStyle}>
                {row.MtdSales}
              </TableCell>
              <TableCell align="center" style={{ ...dataCellStyle, backgroundColor: gradeKPI.SalesChange(row.SalesChange, thresholds).color }}>
                {row.usedAreaAverage ? '-' : row.SalesChange}
              </TableCell>
			  <TableCell align="center" style={{ ...dataCellStyle, backgroundColor: gradeKPI.ContExpenses(row.ContExpenses, thresholds).color }}>
                {row.ContExpenses}
              </TableCell>
              <TableCell align="center" style={{ ...dataCellStyle, backgroundColor: gradeKPI.CrewProduction(row.CrewProduction, thresholds).color }}>
                {row.CrewProduction}
              </TableCell>
              <TableCell align="center" style={{ ...dataCellStyle, backgroundColor: gradeKPI.LaborPercentage(row.LaborPercentage, thresholds).color }}>
                {row.LaborPercentage}
              </TableCell>
              <TableCell align="center" style={{ ...dataCellStyle, backgroundColor: gradeKPI.FoodVarianceMonth(row.FoodVarianceMonth, thresholds).color }}>
                {row.FoodVarianceMonth}
              </TableCell>
              <TableCell align="center" style={{ ...dataCellStyle, backgroundColor: gradeKPI.OnTimesPercentage(row.OnTimesPercentage, thresholds).color }}>
                {row.OnTimesPercentage}
              </TableCell>
              <TableCell align="center" style={{ ...dataCellStyle, backgroundColor: gradeKPI.FormattedAveTime(row.FormattedAveTime, thresholds).color }}>
                {row.FormattedAveTime}
              </TableCell>
              <TableCell align="center" style={{ ...dataCellStyle, backgroundColor: gradeKPI.FormattedReplyTime(row.FormattedReplyTime, thresholds).color }}>
                {row.FormattedReplyTime}
              </TableCell>
              <TableCell align="center" style={{ ...dataCellStyle, backgroundColor: gradeKPI.ComplaintsPer10k(row.ComplaintsPer10k, thresholds).color }}>
                {row.ComplaintsPer10k}
              </TableCell>
              <TableCell align="center" style={{ ...dataCellStyle, backgroundColor: gradeKPI.FormattedCash(row.FormattedCash, thresholds).color }}>
                {row.FormattedCash}
              </TableCell>



            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PopsReportTable;