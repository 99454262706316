import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Typography, Checkbox, TextField, Button, Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import { CheckCircle, RadioButtonUnchecked, Warning, ExpandMore, ArrowRight } from '@mui/icons-material';

const IncompleteItemsModal = ({
  open, 
  handleDialogClose, 
  handleFinalSubmit, 
  handleCheckboxChange, 
  handleReasonChange, 
  incompleteItems, 
  canSubmit 
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (section) => (event, isExpanded) => {
    setExpanded(isExpanded ? section : false);
  };

  return (
    <Dialog open={open} onClose={handleDialogClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Warning color="warning" /> Incomplete Checklist Items
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Some items have not been marked as complete. Would you like to continue?
        </DialogContentText>
        {Object.keys(incompleteItems).map((section, sectionIndex) => (
          <Accordion 
            key={sectionIndex}
            expanded={expanded === section}
            onChange={handleAccordionChange(section)}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#3f51b5' }}>
                {section}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {incompleteItems[section].map((item, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <ArrowRight sx={{ color: '#3f51b5', mr: 1 }} />
                    <Typography variant="subtitle1">{item.task}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', pl: 4 }}> {/* Added padding-left here */}
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                      <Typography variant="body2">Completed:</Typography>
                      <Checkbox
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircle />}
                        checked={item.checked}
                        onChange={() => handleCheckboxChange(section, index)}
                        sx={{ ml: 1 }} // Adjusts the margin between the label and checkbox
                      />
                    </Box>
                    <Typography variant="body2" sx={{ mx: 1 }}>or</Typography>
                    <TextField
                      label="Reason for non-completion"
                      variant="outlined"
                      size="small"
                      fullWidth
                      margin="dense"
                      value={item.reason}
                      onChange={(e) => handleReasonChange(section, index, e.target.value)}
                      sx={{ mt: 0 }} // Removes top margin to reduce space
                    />
                  </Box>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Go Back</Button>
        <Button onClick={handleFinalSubmit} color="primary" variant="contained" disabled={!canSubmit}>
          Final Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IncompleteItemsModal;
