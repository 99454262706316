import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import './CSVConverter.css'; 
import ConverterInstructions from './ConverterInstructions';

const CSVConverter = () => {
  const [file, setFile] = useState(null);
  const [instructionsVisible, setInstructionsVisible] = useState(false); // Ensure this state is initialized
  
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  // Toggle function for showing/hiding the instructions
  const toggleInstructions = () => {
    setInstructionsVisible(!instructionsVisible);
  };

  const getBaseUrl = () => {
    const hostname = window.location.hostname;

    if (hostname === '72.167.34.236') {
      return 'http://72.167.34.236:5000'; // Development server
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return 'https://prolifi.app'; // Production server
    } else {
      console.error('Unknown host');
      return ''; // Default to empty string if the host is unknown
    }
  };

  const handleUpload = async () => {
    if (!file) {
      toast.warn('Please select a file first', { position: 'top-center' });
      return;
    }

    // Check file extension instead of MIME type for validation
    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (fileExtension !== 'csv') {
      toast.warn('Please upload a valid CSV file.', { position: 'top-center' });
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.post(`${baseUrl}/api/truckConverter`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob', // Expecting a binary response (file or error blob)
      });

      // Create a URL for the downloaded file
      const blob = new Blob([response.data]);
      const downloadUrl = window.URL.createObjectURL(blob);

      // Generate dynamic file name
      const now = moment().tz('America/Chicago'); // Central Time zone
      const dateStr = now.format('YYYY-MM-DD');
      const timeStr = now.format('HHmmss'); // Using HH-mm-ss to avoid any file naming issues with colons
      const fileName = `ProlifiTruck_${dateStr}_${timeStr}.csv`;

      // Automatically trigger download
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();

      toast.success('File converted successfully! Download has started.', { position: 'top-center' });

    } catch (error) {
      if (error.response) {
        // Handle HTTP errors
        const statusCode = error.response.status;

        // Handle 4xx errors (client-side)
        if (statusCode >= 400 && statusCode < 500) {
          handleClientError(error);
        }

        // Handle 5xx errors (server-side)
        else if (statusCode >= 500) {
          toast.error('Server error occurred. Please try again later.', { position: 'top-center' });
        }

      } else if (error.request) {
        // Handle network errors (no response)
        if (error.message.includes('Network Error')) {
          toast.error('Network error. Please check your connection.', { position: 'top-center' });
        } else if (error.code === 'ECONNABORTED') {
          // Handle timeout errors
          toast.error('The request timed out. Please try again.', { position: 'top-center' });
        } else {
          // General unknown request error
          toast.error('There was a problem with the request. Please try again.', { position: 'top-center' });
        }
      } else {
        // Unexpected errors
        toast.error('An unexpected error occurred. Please try again.', { position: 'top-center' });
      }

      console.error("Error uploading the file:", error);
    }
  };

  // Helper function to handle 4xx client errors
  const handleClientError = (error) => {
    // Check if error response is a blob
    if (error.response.data instanceof Blob) {
      // Convert the blob to text to extract the error message
      const reader = new FileReader();
      reader.onload = function () {
        const errorMessage = reader.result;

        // Log the error message for debugging
        console.log('Error Message from Blob:', errorMessage);

        // Check for the specific invalid file format message
        if (errorMessage.includes('Invalid file format: Code must be exactly 5 characters in length.')) {
          toast.error(
            <div>
              <strong>Invalid File Format:</strong><br />
              Please check that you are using the correct Inv Xpress file.<br />
              <ul>
                <li>Did you use the 'Export' option in Xpress Order?</li>
                <li>The structure of the file should be Item code in column A, Quantity in column B, and Description in column C.</li>
              </ul>
            </div>,
            {
              position: 'top-center',
              className: 'custom-toast',
              progressClassName: 'custom-toast-progress',
              icon: '🚨',
              autoClose: 5500,
            }
          );
        } else {
          toast.error('There was an error processing the file. Please check the format.', { position: 'top-center' });
        }
      };
      reader.readAsText(error.response.data);
    } else {
      toast.error('Invalid file format. Please check your file.', { position: 'top-center' });
    }
  };
  
  return (
    <div className="csv-converter-page">
      <ToastContainer />
      <div className="csv-converter-container">
        <div className="header-container">
          <h2 className="csv-title">IX to PFG Truck Converter</h2>
          <i 
            className="info-icon" 
            title="Converter Instructions" 
            onClick={toggleInstructions}
          >ℹ️</i>
        </div>
        <div className="converter-section">
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            className="file-input"
          />
          <button
            onClick={handleUpload}
            className={`upload-button ${file ? '' : 'disabled'}`}
            disabled={!file}
          >
            Upload and Convert
          </button>
        </div>
      </div>

      {/* Show Instructions in a separate container next to the converter */}
      {instructionsVisible && (
        <div className="instructions-container">
          <ConverterInstructions />
        </div>
      )}
    </div>
  );
};

export default CSVConverter;
