//TZFileUpload.js
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import ProgressButton from 'react-progress-button';
import 'react-progress-button/react-progress-button.css';
import styles from './TZFileUpload.module.css';

function TZFileUpload() {
  const [file, setFile] = useState(null);
  const [buttonState, setButtonState] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setButtonState('');
  };

  const getBaseUrl = () => {
    if (process.env.NODE_ENV === 'development') {
      return 'http://72.167.34.236:5000';
    }
    return 'https://prolifi.app';
  };

const handleUpload = async () => {
  if (!file) {
    Swal.fire('Oops...', 'Please select a file first', 'error');
    setButtonState('error');
    return;
  }

  setButtonState('loading');
  const formData = new FormData();
  formData.append('excelFile', file);

  try {
    const baseUrl = getBaseUrl();
    const response = await axios.post(`${baseUrl}/api/upload-tz-excel`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true // Include credentials in the request
    });

    // Check if there's a filePath in the response data to trigger the download
    if (response.data.filePath) {
      // Trigger file download
      const downloadUrl = `${baseUrl}${response.data.filePath}`;
	  //console.log(downloadUrl);
      window.location.href = downloadUrl; // This will cause the browser to navigate to the URL, downloading the file
    } else {
      // If no filePath, just show success message
      Swal.fire('Success', response.data.message, 'success');
    }

    setButtonState('success');
  } catch (error) {
    Swal.fire('Error', error.message, 'error');
    setButtonState('error');
  }
};

  return (
    <div className={styles.container}>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} className={styles.fileInput} />
      <ProgressButton onClick={handleUpload} state={buttonState} className={styles.tzUploadButton}>
        Upload
      </ProgressButton>
    </div>
  );
}

export default TZFileUpload;
