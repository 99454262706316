import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import styles from './ResetPassword.module.css';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const resetToken = searchParams.get('token');

  useEffect(() => {
    if (message === 'Password changed successfully') {
      setTimeout(() => {
        navigate('https://prolifi.app'); // Redirect to prolifi.app
      }, 2000); // Redirect after 2 seconds
    }
  }, [message, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post('/api/auth/reset-password', { resetToken, newPassword }, {
        withCredentials: true
      });
      setMessage(response.data.message); // Assuming the backend sends a message property
    } catch (error) {
      // Handle specific error messages here
      if (error.response && error.response.data) {
        setMessage(error.response.data.message); // Display specific error message from the backend
      } else {
        setMessage('Failed to reset password. Please try again.');
      }
    }
  };

return (
  <div className={styles.container}>
    <div className={styles.form}> {/* Added form class */}
      <h1>Reset Password</h1>
      <form onSubmit={handleSubmit}>
        <input
          className={styles.input} // Added input class
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          placeholder="New Password"
        />
        <input
          className={styles.input} // Added input class
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm New Password"
        />
        <button className={styles.button} type="submit">Reset Password</button> {/* Added button class */}
      </form>
      {message && <p className={styles.errorMessage}>{message}</p>} {/* Added errorMessage class */}
    </div>
  </div>
);
}

export default ResetPassword;
