import React, { useState, useEffect } from 'react';
import axios from 'axios';

function SalesForecaster() {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [stations, setStations] = useState([]);
  const [fipsCodes, setFipsCodes] = useState([]); // Holds the list of FIPS codes
  const [stationId, setStationId] = useState('');
  const [fipsCode, setFipsCode] = useState('');
  const [locationType, setLocationType] = useState('station'); // 'station' or 'fips'

  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    return /^(www\.)?prolifi\.app$/.test(hostname) ? 'https://prolifi.app' : 'http://72.167.34.236:5000';
  };

  useEffect(() => {
    if (locationType === 'station') {
      axios.get(`${getBaseUrl()}/api/sales-forecaster/stations-lancaster`)
        .then(response => {
          setStations(response.data);
          if (response.data.length > 0) {
            setStationId(response.data[0].id);
          }
        })
        .catch(error => console.error('Failed to fetch stations:', error));
    } else if (locationType === 'fips') {
      axios.get(`${getBaseUrl()}/api/sales-forecaster/fips-codes`)
        .then(response => {
          setFipsCodes(response.data);
          if (response.data.length > 0) {
            setFipsCode(response.data[0].code);
          }
        })
        .catch(error => console.error('Failed to fetch FIPS codes:', error));
    }
  }, [locationType]); // This useEffect is dependent on locationType

  const fetchHistoricalWeatherByType = () => {
    const baseUrl = getBaseUrl();
    const params = {
      startDate,
      endDate,
      ...(locationType === 'station' ? { stationId } : { fipsCode })
    };

    axios.get(`${baseUrl}/api/sales-forecaster/historical-weather-by-type-or-location`, { params })
      .then(response => {
        console.log('Weather data by type:', response.data);
        alert('Weather data by type fetched successfully!');
      })
      .catch(error => {
        console.error('Error fetching historical weather data by type:', error);
        alert('Failed to fetch historical weather data by type.');
      });
  };

  return (
    <div>
      <h1>Sales Forecaster</h1>
      
      <div>
        <label>Location Type: </label>
        <select value={locationType} onChange={e => setLocationType(e.target.value)}>
          <option value="station">Station ID</option>
          <option value="fips">FIPS Code</option>
        </select>
      </div>
      
      {locationType === 'station' ? (
        <div>
          <label>Station ID: </label>
          <select value={stationId} onChange={e => setStationId(e.target.value)}>
            {stations.map(station => <option key={station.id} value={station.id}>{station.name}</option>)}
          </select>
        </div>
      ) : (
        <div>
          <label>FIPS Code: </label>
          <select value={fipsCode} onChange={e => setFipsCode(e.target.value)}>
            {fipsCodes.map(fips => <option key={fips.code} value={fips.code}>{fips.description}</option>)}
          </select>
        </div>
      )}
      
      <div>
        <label>Start Date: </label>
        <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
      </div>
      
      <div>
        <label>End Date: </label>
        <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
      </div>
      
      <button onClick={fetchHistoricalWeatherByType}>Fetch Weather Data</button>
    </div>
  );
}

export default SalesForecaster;
