import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import styles from './bbUpload.module.css';
import SalesDataChecker from './SalesDataChecker';
import ProgressButton from 'react-progress-button'; // Import ProgressButton

function BbUpload() {
    const [file, setFile] = useState(null);
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [buttonState, setButtonState] = useState(''); // State to control the ProgressButton
    const MySwal = withReactContent(Swal);

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setButtonState(''); // Reset button state when a new file is selected
    };

    const getBaseUrl = () => {
        return process.env.NODE_ENV === 'development'
            ? 'http://72.167.34.236:5000'
            : 'https://prolifi.app';
    };

    const handleUpload = async () => {
        if (!file) {
            MySwal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please select a file first!',
            });
            return;
        }

        setButtonState('loading'); // Set button to loading state
        const formData = new FormData();
        formData.append('file', file);

        try {
            const baseUrl = getBaseUrl();
            const response = await axios.post(`${baseUrl}/api/bbUpload/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Upload successful', response.data);
            MySwal.fire({
                icon: 'success',
                title: 'Success!',
                text: 'Data successfully inserted into database.',
            });
            setButtonState('success'); // Set button to success state
        } catch (error) {
            console.error('Upload failed', error);
            MySwal.fire({
                icon: 'error',
                title: 'Failed!',
                text: 'Failed to upload data. Please try again.',
            });
            setButtonState('error'); // Set button to error state
        }

        setTimeout(() => setButtonState(''), 2000); // Reset button state after a delay
        setFile(null);
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.uploadContainer}>
                <button onClick={toggleFormVisibility} className={styles.toggleBar}>
                    {isFormVisible ? '▲ Hide Form' : '▼ Show Form'}
                </button>

                {isFormVisible && (
                    <>
                        <h2 className={styles.mainTitle}>Blue Book Upload</h2>
                        <input type="file" onChange={handleFileChange} className={styles.input} />
                        <ProgressButton onClick={handleUpload} state={buttonState} className={styles.button}>
                            Upload
                        </ProgressButton>
                    </>
                )}
            </div>
            <SalesDataChecker />
        </div>
    );
}

export default BbUpload;
