// createServiceReportSheet.js

// Helper function to convert seconds to minutes:seconds format
const convertToMinutesSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60).toString().padStart(2, '0');
  return `${minutes}:${secs}`;
};

const createServiceReportSheet = (weeklySalesArray) => {
  // Define the headers for the 'Service' sheet
  const headers = [
    ['Supervisor', 'Store', 'On times', 'Overall', 'Drive Thru', 'Stall', 'Order Ahead', 'Reply Time']
  ];

  // Process the weekly sales data to format the service report
  const serviceDataRows = weeklySalesArray.map(storeData => {
    const onTimes = (parseFloat(storeData.currentWeek.ontimeTickets) / parseInt(storeData.currentWeek.tickets, 10));
    return [
      storeData.supervisorName,
      storeData.storeName,
      onTimes.toFixed(2),
      convertToMinutesSeconds(storeData.service.overallAveTime),
      convertToMinutesSeconds(storeData.service.dtAveTime),
      convertToMinutesSeconds(storeData.service.stallAveTime),
      convertToMinutesSeconds(storeData.service.orderAheadAveTime),
      convertToMinutesSeconds(storeData.service.replyTime)
    ];
  });

  // Combine the headers and service data rows
  const worksheetData = [...headers, ...serviceDataRows];

  return worksheetData;
};

export default createServiceReportSheet;