const rankStoresByKPI = (storesData, kpiKey, isAscending = true) => {
  // Adjust sorting direction based on KPI requirements
  if (['CrewProduction', 'OnTimesPercentage', 'SalesChange'].includes(kpiKey)) {
    isAscending = false; // Higher values are better
  } else if (['FoodVarianceMonth', 'FormattedCash', 'ContExpenses', 'LaborPercentage'].includes(kpiKey)) {
    isAscending = true; // Lower or closer to zero values are better
  }

  const validStores = [];
  const invalidStores = [];

  storesData.forEach(store => {
    let value = store[kpiKey];
    if (['FormattedReplyTime', 'FormattedAveTime'].includes(kpiKey)) {
      // Convert times to seconds
      const parts = value.split(':');
      value = parts.length === 2 ? parseInt(parts[0], 10) * 60 + parseInt(parts[1], 10) : NaN;
    } else if (['FormattedCash', 'FoodVarianceMonth', 'CrewProduction'].includes(kpiKey)) {
      // Remove currency symbols and commas, parse floats, and for certain KPIs, take absolute values
      value = parseFloat(value.replace(/[$,]/g, ''));
      if (['FormattedCash', 'FoodVarianceMonth'].includes(kpiKey)) {
        value = Math.abs(value);
      }
    } else {
      // Strip percentage signs and parse as float for other numerical KPIs
      value = parseFloat(value.replace(/[%]/g, ''));
    }

    if (!isNaN(value)) {
      validStores.push({ storeId: store.storeId, value });
    } else {
      invalidStores.push({ storeId: store.storeId, value: Infinity });
    }
  });

  // Sort stores by the KPI value, considering the desired direction
  validStores.sort((a, b) => isAscending ? a.value - b.value : b.value - a.value);

  // Assign ranks, taking ties into account
  let currentRank = 1;
  validStores.forEach((store, index, array) => {
    if (index > 0 && store.value === array[index - 1].value) {
      store.rank = array[index - 1].rank; // Tie with previous
    } else {
      store.rank = currentRank;
    }
    if (!(index + 1 < array.length && store.value === array[index + 1].value)) {
      currentRank++; // Increment rank only if next is not a tie
    }
  });

  // Handle invalid entries
  const lastRank = validStores.length + 1;
  invalidStores.forEach(store => store.rank = lastRank);

  // Combine valid and invalid store rankings into a single object
  const combinedStores = [...validStores, ...invalidStores];
  const rankingsByStoreId = combinedStores.reduce((acc, { storeId, rank }) => {
    acc[storeId] = rank;
    return acc;
  }, {});

  return rankingsByStoreId;
};

export { rankStoresByKPI };
