import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import Swal from 'sweetalert2';
import ProgressButton from 'react-progress-button';
import 'react-progress-button/react-progress-button.css';
import styles from './EEDataUpload.module.css';

function ExcelProcessor() {
  const [file, setFile] = useState(null);
  const [buttonState, setButtonState] = useState('');

  const getBaseUrl = () => {
    if (process.env.NODE_ENV === 'development') {
      return 'http://72.167.34.236:5000';
    }
    return 'https://prolifi.app';
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setButtonState('');
  };

  const uploadFile = async () => {
    if (!file) {
      Swal.fire('Oops...', 'Please select a file first', 'error');
      setButtonState('error');
      return;
    }

    setButtonState('loading');
    const formData = new FormData();
    formData.append('file', file);

    try {
      const baseUrl = getBaseUrl();
    const response = await axios.post(`${baseUrl}/api/upload-employee-data`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      withCredentials: true // Include credentials in the request
    });

      if (response.status === 200) {
        Swal.fire('Success!', 'File uploaded successfully', 'success');
        setButtonState('success');
      } else {
        Swal.fire('Error', 'Failed to upload file', 'error');
        setButtonState('error');
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      Swal.fire('Error', 'An unexpected error occurred', 'error');
      setButtonState('error');
    }
  };

  return (
<div className={styles.EEDataUpload}>
  <h1>Employee Data Upload</h1>
  <input type="file" onChange={handleFileChange} accept=".xlsx, .xls" />
  <ProgressButton onClick={uploadFile} state={buttonState} className={styles.progressButton}>
    Upload File
  </ProgressButton>
</div>
  );
}

export default ExcelProcessor;
