//calculateTotal
export const calculateTotal = (bills, coins, changersPresent, fullChangerValue, expectedChangers, expectedTotal, additionalCash = 0) => {
  // Static values for demonstration, to be dynamically set later


  // Bill denominations with single bill values
  const billValues = { hundreds: 100, fifties: 50, twenties: 20, tens: 10, fives: 5, ones: 1 };
  // Bundle values
  const bundleValues = { tensBundles: 100, fivesBundles: 100, onesBundles: 25 };

  // Coin denominations with single coin values
  const coinValues = { dollarCoins: 1, quarters: 0.25, dimes: 0.10, nickles: 0.05, pennies: 0.01 };
  // Roll values explicitly defined
  const rollValues = { quartersRolls: 10, dimesRolls: 5, nicklesRolls: 2, penniesRolls: 0.50 };

  // Calculate total for bills, including bundles
  const totalBills = Object.entries(bills).reduce((total, [key, value]) => {
    const multiplier = bundleValues[key] ? bundleValues[key] : billValues[key];
    return total + (multiplier * (value || 0));
  }, 0);

  // Calculate total for coins, including rolls
  const totalCoins = Object.entries(coins).reduce((total, [key, value]) => {
    const valuePerItem = rollValues[key] ? rollValues[key] : coinValues[key];
    return total + (valuePerItem * (value || 0));
  }, 0);

  // Start with the sum of bills and coins
  let total = totalBills + totalCoins;

  // Adjusted to add additionalCash if provided
  if (changersPresent) {
    total += fullChangerValue * expectedChangers;
  } else {
    total += additionalCash;
  }

  return total;
};