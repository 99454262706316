// WeeklyServiceTable.js
import React from 'react';
import styles from './WeeklyServiceTable.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const convertSecondsToMinutes = (secondsStr) => {
  // Convert the string to a number and round it down to remove any decimals
  const totalSeconds = Math.floor(parseFloat(secondsStr));
  if (isNaN(totalSeconds)) {
    return 'N/A';
  }
  const minutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};


export function WeeklyServiceTable({ weeklyServiceData }) {
  if (!weeklyServiceData) {
    return <p>No service data available.</p>;
  }
  
    // Function to calculate on-time percentage
  const calculateOnTimePercentage = (data) => {
    const ontimeTickets = parseFloat(data.currentWeek.ontimeTickets);
    const totalTickets = parseFloat(data.currentWeek.tickets);
    return totalTickets > 0 ? (ontimeTickets / totalTickets) * 100 : 0;
  };

  // Sort the data by on-time percentage, highest to lowest
  const sortedData = Object.entries(weeklyServiceData).sort((a, b) => {
    const onTimeA = calculateOnTimePercentage(a[1]);
    const onTimeB = calculateOnTimePercentage(b[1]);
    return onTimeB - onTimeA;
  });

  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table aria-label="weekly service table" className={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell>Supervisor</TableCell>
            <TableCell>Store</TableCell>
            <TableCell>On Times</TableCell>
            <TableCell>Ave. Time - Overall</TableCell>
            <TableCell>Ave. Time - DT</TableCell>
            <TableCell>Ave. Time - Stall</TableCell>
            <TableCell>Ave. Time - OA</TableCell>
            <TableCell>Reply Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map(([storeId, data], index) => (
            <TableRow key={index}>
              <TableCell>{data.supervisorName}</TableCell>
              <TableCell>{data.storeName}</TableCell>
              <TableCell>{calculateOnTimePercentage(data).toFixed(2)}%</TableCell>
              <TableCell>{convertSecondsToMinutes(data.service.overallAveTime)}</TableCell>
              <TableCell>{convertSecondsToMinutes(data.service.dtAveTime)}</TableCell>
              <TableCell>{convertSecondsToMinutes(data.service.stallAveTime)}</TableCell>
              <TableCell>{convertSecondsToMinutes(data.service.orderAheadAveTime)}</TableCell>
              <TableCell>{convertSecondsToMinutes(data.service.replyTime)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
