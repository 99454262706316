// src/components/SessionChecker.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SessionChecker.css';

const SessionChecker = () => {
  const [sessions, setSessions] = useState({});

  const fetchSessions = async () => {
    try {
      const baseUrl = window.location.hostname === '72.167.34.236'
        ? 'http://72.167.34.236:5000'
        : 'https://prolifi.app';
      const response = await axios.get(`${baseUrl}/api/auth/active-sessions`, { withCredentials: true });
      setSessions(response.data);
    } catch (error) {
      console.error('Error fetching active sessions:', error);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  const handleKillSession = async (sessionId) => {
    try {
      const baseUrl = window.location.hostname === '72.167.34.236'
        ? 'http://72.167.34.236:5000'
        : 'https://prolifi.app';
      await axios.delete(`${baseUrl}/api/auth/active-sessions/${sessionId}`, { withCredentials: true });
      fetchSessions(); // Refresh the sessions after deleting one
    } catch (error) {
      console.error('Error destroying session:', error);
    }
  };

  return (
    <div className="session-checker">
      <h2>Active Sessions</h2>
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Company</th>
            <th>User Group</th>
            <th>User Store</th>
            <th>Last Action</th>
            <th>Expires</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(sessions).map((key) => (
            <tr key={key}>
              <td>{sessions[key].user.username}</td>
              <td>{sessions[key].user.company}</td>
              <td>{sessions[key].user.userGroup}</td>
              <td>{sessions[key].user.userStore}</td>
              <td>{new Date(new Date(sessions[key].cookie.expires).getTime() - 1 * 60 * 60 * 1000).toLocaleString('en-US', { timeZone: 'America/Chicago' })}</td>
              <td>{new Date(sessions[key].cookie.expires).toLocaleString('en-US', { timeZone: 'America/Chicago' })}</td>
              <td>
                <button onClick={() => handleKillSession(key)}>Kill</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SessionChecker;
