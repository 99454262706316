import gradeKPI from './GradeKPIs';
import { rankStoresByKPI } from './RankKPIs';
import { calculateDynamicScores } from './DynamicScoring';


const calculateScores = (storesData, gradingStandards, areaAveragesState) => {
  const kpiKeys = [
    'LaborPercentage', 'CrewProduction', 'OnTimesPercentage',
    'FormattedAveTime', 'FormattedReplyTime', 'SalesChange',
    'ComplaintsPer10k', 'FormattedCash', 'FoodVarianceMonth', 'ContExpenses'
  ];

  // Pre-fetch rankings for each KPI
  const kpiRankings = {};
  kpiKeys.forEach(kpiKey => {
    kpiRankings[kpiKey] = rankStoresByKPI(storesData, kpiKey);
  });

  let firstPlaceCounts = {};

  kpiKeys.forEach(kpiKey => {
    // Assuming rankStoresByKPI returns an object {storeId: rank}
    Object.entries(kpiRankings[kpiKey]).forEach(([storeId, rank]) => {
      if (rank === 1) { // If the rank is 1, increment the store's 1st place count
        if (!firstPlaceCounts[storeId]) {
          firstPlaceCounts[storeId] = 1;
        } else {
          firstPlaceCounts[storeId]++;
        }
      }
    });
  });

  const dynamicScores = calculateDynamicScores(storesData, kpiRankings, storesData.length, areaAveragesState);

  return storesData.map(store => {
    let totalGreen = 0;

    // Extracting and cleaning KPI values
    const laborPercentage = store.LaborPercentage;
    const crewProduction = store.CrewProduction.replace(/[$,]/g, '');
    const onTimesPercentage = store.OnTimesPercentage;
    const averageTime = store.FormattedAveTime;
    const replyTime = store.FormattedReplyTime;
    const salesChange = store.SalesChange;
    const complaintsPer10k = store.ComplaintsPer10k;
    const formattedCash = store.FormattedCash;
    const foodVarianceMonth = store.FoodVarianceMonth;
    const contExpenses = store.ContExpenses.replace('%', '');
    const firstPlaces = firstPlaceCounts[store.storeId] || 0;
    // Function to check color and increment totalGreen if green
    const checkAndCountGreen = (kpiResult) => {
      if (kpiResult.color === 'green') {
        totalGreen += 1;
      }
      return kpiResult.points;
    };

	

    // Calculating scores for each KPI and checking for green grades
  const scores = {
    laborScore: checkAndCountGreen(gradeKPI.LaborPercentage(laborPercentage, gradingStandards)),
    crewProductionScore: checkAndCountGreen(gradeKPI.CrewProduction(crewProduction, gradingStandards)),
    onTimesScore: checkAndCountGreen(gradeKPI.OnTimesPercentage(onTimesPercentage, gradingStandards)),
    aveTimeScore: checkAndCountGreen(gradeKPI.FormattedAveTime(averageTime, gradingStandards)),
    replyTimeScore: checkAndCountGreen(gradeKPI.FormattedReplyTime(replyTime, gradingStandards)),
    salesChangeScore: checkAndCountGreen(gradeKPI.SalesChange(salesChange, gradingStandards)),
    complaintsPer10kScore: checkAndCountGreen(gradeKPI.ComplaintsPer10k(complaintsPer10k, gradingStandards)),
    cashScore: checkAndCountGreen(gradeKPI.FormattedCash(formattedCash, gradingStandards)),
    foodVarianceScore: checkAndCountGreen(gradeKPI.FoodVarianceMonth(foodVarianceMonth, gradingStandards)),
    contExpensesScore: checkAndCountGreen(gradeKPI.ContExpenses(contExpenses, gradingStandards)),
  };

    // Retrieve rankings for each KPI
    const rankings = {
      laborRank: kpiRankings['LaborPercentage'][store.storeId],
      crewProductionRank: kpiRankings['CrewProduction'][store.storeId],
      onTimesRank: kpiRankings['OnTimesPercentage'][store.storeId],
      aveTimeRank: kpiRankings['FormattedAveTime'][store.storeId],
      replyTimeRank: kpiRankings['FormattedReplyTime'][store.storeId],
      salesChangeRank: kpiRankings['SalesChange'][store.storeId],
      complaintsPer10kRank: kpiRankings['ComplaintsPer10k'][store.storeId],
      cashRank: kpiRankings['FormattedCash'][store.storeId],
      foodVarianceRank: kpiRankings['FoodVarianceMonth'][store.storeId],
      contExpensesRank: kpiRankings['ContExpenses'][store.storeId],
    };

    const dynamicScore = dynamicScores.find(s => s.storeId === store.storeId)?.dynamicScore || 0;
    // Sum the scores to get a total score for the store
    const totalScore = Object.values(scores).reduce((acc, curr) => acc + curr, 0);


	// Convert each component to a number ensuring undefined or non-numeric values turn into 0
	const numericTotalScore = Number(totalScore);
	const numericDynamicScore = Number(dynamicScore);
	const numericFirstPlaces = Number(firstPlaces);

	// Calculate the overallScore safely
	const overallScore = numericTotalScore + numericDynamicScore + (numericFirstPlaces * 0);
/*
	console.log("---kpiRankings---");
	console.log(kpiRankings);
	console.log("---kpiRankings---");
*/

/*	
	console.log("---calculateScores---");
	console.log(calculateScores);
	console.log("---calculateScores END---");	
*/

/*
	console.log("---firstPlaceCounts---");
	console.log(firstPlaceCounts);
	console.log("---firstPlaceCounts END---");
*/

/*	
	console.log("---dynamicScores---");
	console.log(dynamicScores);
	console.log("---dynamicScoresEND---");
*/

/*	
	console.log("---totalScore---");
	console.log(totalScore);
	console.log("---totalScore END---");
*/

/*	
	console.log("---overallScore---");
	console.log(overallScore);
    console.log("---overallScore END---");
*/


    // Return the total score, total green count, and rankings for each store
    return {
      storeId: store.storeId,
      totalScore,
      totalGreen,
      rankings, // Includes all rankings
      dynamicScore,
      firstPlaces,
      overallScore
    };
  });
};

export default calculateScores;
