import React, { useEffect, useState } from 'react';
import { Card, Table, Alert } from 'react-bootstrap';
import axios from 'axios';

const CorrectiveActionData = ({ employeeName, jobTitle, offenseType, submitterStoreID }) => {
  const [correctiveActions, setCorrectiveActions] = useState([]);
  const [daysActive, setDaysActive] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchDaysActive = async () => {
      try {
        const baseUrl = window.location.hostname === '72.167.34.236'
          ? 'http://72.167.34.236:5000'
          : /^(www\.)?prolifi\.app$/.test(window.location.hostname)
            ? 'https://prolifi.app'
            : 'http://localhost:5000'; // For local development

        const response = await axios.get(`${baseUrl}/api/offense-types`, {
          params: { offenseType, storeID: submitterStoreID }
        });

        if (response.data && response.data.daysActive) {
          setDaysActive(response.data.daysActive);
        }
      } catch (error) {
        console.error('Error fetching days active:', error);
        setError('Error fetching days active');
      }
    };

    const fetchCorrectiveActions = async () => {
      try {
        const baseUrl = window.location.hostname === '72.167.34.236'
          ? 'http://72.167.34.236:5000'
          : /^(www\.)?prolifi\.app$/.test(window.location.hostname)
            ? 'https://prolifi.app'
            : 'http://localhost:5000'; // For local development

        const response = await axios.get(`${baseUrl}/api/corrective-actions`, {
          params: { employeeName, offenseType, submitterStoreID }
        });

        const sortedActions = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setCorrectiveActions(sortedActions);
      } catch (error) {
        console.error('Error fetching corrective actions:', error);
        setError('Error fetching corrective actions');
      }
    };

    if (employeeName && offenseType && submitterStoreID) {
      fetchDaysActive();
      fetchCorrectiveActions();
    }
  }, [employeeName, offenseType, submitterStoreID]);

  const calculateDaysOld = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - createdDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const isActionActive = (createdAt) => {
    return calculateDaysOld(createdAt) <= daysActive;
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>Corrective Actions Data</Card.Title>
        {error && <Alert variant="danger">{error}</Alert>}
        {correctiveActions.length > 0 ? (
          <Table striped bordered hover className="mt-3">
            <thead>
              <tr>
                <th>Warning Type</th>
                <th>Days Old</th>
                <th>Submitted by</th>
              </tr>
            </thead>
            <tbody>
              {correctiveActions.filter(action => isActionActive(action.createdAt)).map((action, index) => (
                <tr key={index}>
                  <td>{action.warningType}</td>
                  <td>{calculateDaysOld(action.createdAt)}</td>
                  <td>{action.submitterName}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>No active corrective actions found for this employee and offense type.</p>
        )}
      </Card.Body>
    </Card>
  );
};

export default CorrectiveActionData;
