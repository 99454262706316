import React from 'react';
import { Typography, Button, Box } from '@mui/material';

const getBaseUrl = () => {
  // Check the current hostname
  const hostname = window.location.hostname;
  
  // If the site is being accessed through prolifi.app, use the secure URL
  if (hostname === 'prolifi.app') {
    return 'https://prolifi.app';
  }
  
  // For development or any other hostname, use the unsecure IP address
  return 'http://72.167.34.236:5000';
};


function HeroSection() {
  return (
    <Box
      className="hero-section"
      sx={{
        backgroundImage: `url("https://prolifi.team/images/pApp/heroBG.png")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: '#fff',
        padding: '2rem 0',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        boxSizing: 'border-box',
        position: 'relative',  // Set position to relative to contain the pseudo-element
        '&:before': {  // Pseudo-element for overlay
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.38)',  // Adjust the opacity as needed
          zIndex: 1,  // Ensure overlay is above the background image but below the text
        },
      }}
    >
      {/* Add an additional Box to act as a wrapper for content, setting its position to relative to be above the overlay */}
      <Box sx={{ position: 'relative', zIndex: 2, maxWidth: '1140px', padding: '0 15px', textAlign: 'center' }}>
        <Typography
          className="hero-title"
          variant="h2"
          component="h1"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: '#fff',
            fontSize: {
              xs: '1.5rem',
              sm: '1.75rem',
              md: '2rem',
            },
            margin: '0 auto 1rem',
          }}
        >
          Empower Your Business With Our Innovative Solutions
        </Typography>
        <Typography
          className="hero-subtitle"
          variant="h5"
          gutterBottom
          sx={{
            color: '#fff',
            fontSize: '1.2rem',
            maxWidth: '600px',
            margin: '0 auto 1.5rem',
          }}
        >
          Discover how we can help you achieve technological excellence
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{
            backgroundColor: '#007bff',
            '&:hover': { backgroundColor: '#0056b3' },
            padding: '10px 30px',
            borderRadius: '5px',
            textTransform: 'uppercase',
            fontSize: '1rem',
            transition: 'background-color 0.3s',
          }}
          href="#services"
        >
          Explore Our Services
        </Button>
      </Box>
    </Box>
  );
}

export default HeroSection;