//W-Manual.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './WeeklyDataEntryForm.module.css';
import { format, startOfISOWeek, endOfISOWeek, getISOWeek, subDays, getMonth, getYear } from 'date-fns';

function WeeklyDataEntryForm() {
	
	const [isFormVisible, setIsFormVisible] = useState(true);
	const [isUserAuthorizedForPoPs, setIsUserAuthorizedForPoPs] = useState(false);

    // Toggle function for form visibility
    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };
	
    const [formData, setFormData] = useState({
        storeSelection: '-1',
        hoursEndingMonth: '',
        hoursBeginningMonth: '',
        foodVarianceWeek: '',
        foodVarianceMonth: '',
        entryDate: '',  // Assuming you'll calculate this on the client side
    });

    const [dateRanges, setDateRanges] = useState({
        hoursEndMonthRange: '',
        hoursStartNextMonthRange: '',
        currentMonth: '',
    });
	
		const [popsFormData, setPopsFormData] = useState({
			mysteryShopScore: '0',  // Set default value to 0
			syncReports: '',
			actualFoodAndPaperCost: ''
		});


    const [supervisedStores, setSupervisedStores] = useState([]);

const getBaseUrl = () => {
  const hostname = window.location.hostname;

  // This regular expression will match both 'www.prolifi.app' and 'prolifi.app'
  if (hostname === '72.167.34.236') {
    return 'http://72.167.34.236:5000';
  } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
    return 'https://prolifi.app';
  } else {
    return 'http://localhost:5000'; // For local development
  }
};


    useEffect(() => {
        fetchSupervisedStores();
        calculateDateRangesForISOWeek();
		checkUserAuthorization();
    }, []); // Empty dependency array to run only on component mount
	
const checkUserAuthorization = async () => {
  const baseUrl = getBaseUrl();

  try {
    // Step 1: Get the current user's session data to retrieve the userId
    // Ensure credentials are included with the request
    const sessionResponse = await axios.get(`${baseUrl}/api/session-data`, { withCredentials: true });
    const userId = sessionResponse.data.data.id; // Correctly access the 'id' as the userId

    // Step 2: Use the retrieved userId to check for authorization
    // Ensure credentials are included with this request as well
    if (userId) {
      const authorizationResponse = await axios.get(`${baseUrl}/api/check-user-authorization`, {
        params: {
          userId: userId, // Use the correct userId extracted from the session data
        },
        withCredentials: true // Include credentials with the request
      });

      // Update the component state based on the authorization check
      if (authorizationResponse.data.isAuthorized) {
        setIsUserAuthorizedForPoPs(true);
      } else {
        setIsUserAuthorizedForPoPs(false);
      }
    } else {
      // Handle case where the userId is not found in the session data
      console.error('User ID not found in session data');
      setIsUserAuthorizedForPoPs(false);
    }
  } catch (error) {
    console.error('Error checking user authorization:', error);
    setIsUserAuthorizedForPoPs(false); // Ensure state is updated appropriately in case of error
  }
};


useEffect(() => {
    if (formData.storeSelection !== '-1') {
        // Reset the form data to initial state
        setFormData({
            storeSelection: formData.storeSelection,
            hoursEndingMonth: '',
            hoursBeginningMonth: '',
            foodVarianceWeek: '',
            foodVarianceMonth: '',
            entryDate: '', // Assuming this will be recalculated
        });

        // Then fetch the new data
        fetchExistingData();
    }
}, [formData.storeSelection]); 

const fetchExistingData = async () => {
    const baseUrl = getBaseUrl();
    
    const today = new Date();
    const isMondayTuesdayOrWednesday = today.getDay() === 1 || today.getDay() === 2 || today.getDay() === 3;
    const entryDate = isMondayTuesdayOrWednesday ? endOfISOWeek(subDays(today, 7)) : endOfISOWeek(today);
    const weekNumber = `${entryDate.getFullYear()}${getISOWeek(entryDate).toString().padStart(2, '0')}`;

    try {
        const response = await axios.get(`${baseUrl}/api/weekly-data-entry/fetch-data`, {
            params: {
                storeId: formData.storeSelection,
                weekNumber: weekNumber,
            }
        });

        if (response.data) {
            const fetchedData = response.data;

            // Determine if the week is split between two months
            const isSplitWeek = fetchedData.hours_ending_month !== null && fetchedData.hours_beginning_month !== null;

            // Update formData state
            setFormData(prevFormData => ({
                ...prevFormData,
                storeSelection: fetchedData.store_id.toString(),
                hoursEndingMonth: isSplitWeek ? fetchedData.hours_ending_month : fetchedData.hours_full_week || "",
                hoursBeginningMonth: isSplitWeek ? fetchedData.hours_beginning_month : "",
                foodVarianceWeek: fetchedData.food_variance_week,
                foodVarianceMonth: fetchedData.food_variance_month,
                // Add other fields that you want to update from fetched data
            }));
        }
    } catch (error) {
        console.error('Error fetching existing data:', error);
        // Handle error appropriately
    }
	
	    try {
        const popsResponse = await axios.get(`${baseUrl}/api/weekly-data-entry/fetch-pops-data`, {
            params: {
                storeId: formData.storeSelection,
            }
        });

		if (popsResponse.data) {
			const popsData = popsResponse.data;
			setPopsFormData({
				mysteryShopScore: popsData.mystery_shop_score || '',
				syncReports: popsData.mtd_sync_reports || '',
				actualFoodAndPaperCost: popsData.actual_fandp_cost || ''
			});
		}

    } catch (error) {
        console.error('Error fetching PoPs data:', error);
        // Handle error appropriately
    }
	
};



    const fetchSupervisedStores = async () => {
        const baseUrl = getBaseUrl();
        try {
            const response = await axios.get(`${baseUrl}/api/supervised-stores`, { withCredentials: true });
            setSupervisedStores(response.data);
        } catch (error) {
            console.error('Error fetching supervised stores:', error);
            // Handle error appropriately
        }
    };

const calculateDateRangesForISOWeek = () => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay(); // Sunday - 0, Monday - 1, etc.
    let weekStart, weekEnd;

    if (dayOfWeek === 1) { // If it's Monday, use the previous week
        const lastMonday = subDays(currentDate, 7); // Go back to last Monday
        weekStart = startOfISOWeek(lastMonday);
        weekEnd = endOfISOWeek(lastMonday);
    } else { // For any other day, use the current week
        weekStart = startOfISOWeek(currentDate);
        weekEnd = endOfISOWeek(currentDate);
    }

    const currentMonthFormatted = format(currentDate, 'MMMM');
    const isSplitWeek = getMonth(weekStart) !== getMonth(weekEnd);

    let hoursEndMonthRangeFormatted, hoursStartNextMonthRangeFormatted = '';

    if (isSplitWeek) {
        // Find the last day of the start month
        const lastDayOfStartMonth = new Date(getYear(weekStart), getMonth(weekStart) + 1, 0);
        hoursEndMonthRangeFormatted = `${format(weekStart, 'MM/dd')} - ${format(lastDayOfStartMonth, 'MM/dd')}`;

        // The start of the next month is simply the day after the last day of the start month
        const firstDayOfEndMonth = new Date(getYear(weekEnd), getMonth(weekEnd), 1);
        hoursStartNextMonthRangeFormatted = `${format(firstDayOfEndMonth, 'MM/dd')} - ${format(weekEnd, 'MM/dd')}`;
    } else {
        // If the week is not split, only set the full week range
        hoursEndMonthRangeFormatted = `${format(weekStart, 'MM/dd')} - ${format(weekEnd, 'MM/dd')}`;
    }

    setDateRanges({
        currentMonth: currentMonthFormatted,
        hoursEndMonthRange: hoursEndMonthRangeFormatted,
        hoursStartNextMonthRange: hoursStartNextMonthRangeFormatted,
    });
};


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
	
	// Handle changes for PoPs Data form fields
	const handlePopsChange = (e) => {
	  const { name, value } = e.target;
	  setPopsFormData(prevFormData => ({
		...prevFormData,
		[name]: value
	  }));
	};

const handleSubmit = async (e) => {
  e.preventDefault();
  
  if (formData.storeSelection === '-1') {
    alert('Please select a store before submitting');
    return;
  }

  // Determine if the week is split between two months
  const isSplitWeek = dateRanges.hoursStartNextMonthRange !== '';
  console.log(isSplitWeek);

  // Exclude hoursBeginningMonth from formData spread
  const { hoursBeginningMonth, ...restOfFormData } = formData;

  // Prepare the form data, conditionally including hoursBeginningMonth based on isSplitWeek
  const combinedFormData = {
    ...restOfFormData,
    isUserAuthorizedForPoPs, // Ensure this is explicitly included
    ...(isSplitWeek ? { hoursBeginningMonth } : {}), // Conditionally add hoursBeginningMonth
    ...(isUserAuthorizedForPoPs ? popsFormData : {}),
  };

  console.log("Final form data being sent:", combinedFormData); // Debug log

  const baseUrl = getBaseUrl();
  
  try {
    // Make the POST request with combined form data
    const response = await axios.post(`${baseUrl}/api/weekly-data-entry`, combinedFormData, { withCredentials: true });
    console.log("Response from server:", response.data); // Debug log
    alert('Data submitted successfully');
  } catch (error) {
    console.error('Submission error:', error);
    alert('Error submitting data');
  }
};


return (
  <div className={styles.mainContainer}>
    <div className={styles.formContainer}>
      <button onClick={toggleFormVisibility} className={styles.toggleBar}>
        {isFormVisible ? '▲ Hide Form' : '▼ Show Form'}
      </button>

      <div className={isFormVisible ? '' : styles.hiddenForm}>
        {isFormVisible && (
          <form onSubmit={handleSubmit} className={styles.form}>
            <h2 className={styles.mainTitle}>Weekly Data Entry</h2>
            <p className={styles.secondaryTitle}>Please fill in the form below</p>

            <h3 className={styles.secondaryTitle}>Select Store</h3>
            <select name="storeSelection" onChange={handleChange} value={formData.storeSelection} className={styles.input} required>
              <option value="-1">Please select a store</option>
              {supervisedStores.map(store => (
                <option key={store} value={store}>Store #{store}</option>
              ))}
            </select>

            {formData.storeSelection !== '-1' && (
              <>
                {dateRanges.hoursStartNextMonthRange ? (
                  <>
                    <h3 className={styles.secondaryTitle}>Hours Worked (Ending Month)</h3>
                    <input
                      type="number"
                      name="hoursEndingMonth"
                      placeholder="Hours Worked (Ending Month)"
                      value={formData.hoursEndingMonth}
                      onChange={handleChange}
                      className={styles.input}
                      required
                    />
                    <label htmlFor="hoursEndingMonth" className={styles.smallLabel}>
                      Hours worked {dateRanges.hoursEndMonthRange}
                    </label>

                    <h3 className={styles.secondaryTitle}>Hours Worked (Beginning Next Month)</h3>
                    <input
                      type="number"
                      name="hoursBeginningMonth"
                      placeholder="Hours Worked (Beginning Next Month)"
                      value={formData.hoursBeginningMonth}
                      onChange={handleChange}
                      className={styles.input}
                      required
                    />
                    <label htmlFor="hoursBeginningMonth" className={styles.smallLabel}>
                      Hours worked {dateRanges.hoursStartNextMonthRange}
                    </label>
                  </>
                ) : (
                  <>
                    <h3 className={styles.secondaryTitle}>Hours for the Week</h3>
                    <input
                      type="number"
                      name="hoursEndingMonth"
                      placeholder="Hours for the Week"
                      value={formData.hoursEndingMonth}
                      onChange={handleChange}
                      className={styles.input}
                      required
                    />
                    <label htmlFor="hoursEndingMonth" className={styles.smallLabel}>
                      Hours worked {dateRanges.hoursEndMonthRange}
                    </label>
                  </>
                )}

                <h3 className={styles.secondaryTitle}>Food Variance</h3>
                <input
                  type="number"
                  name="foodVarianceWeek"
                  placeholder="Food Variance (Week)"
                  value={formData.foodVarianceWeek}
                  onChange={handleChange}
                  className={styles.input}
                  required
                />
                <label htmlFor="foodVarianceWeek" className={styles.smallLabel}>Week</label>

                <input
                  type="number"
                  name="foodVarianceMonth"
                  placeholder="Food Variance (Month)"
                  value={formData.foodVarianceMonth}
                  onChange={handleChange}
                  className={styles.input}
                  required
                />
                <label htmlFor="foodVarianceMonth" className={styles.smallLabel}>
                  Month to Date ({dateRanges.currentMonth})
                </label>

                {/* PoPs Data Entry Form, conditional on store selection and user authorization */}
                {isUserAuthorizedForPoPs && formData.storeSelection !== '-1' && (
                  <>
                    <h3 className={styles.secondaryTitle}>PoPs Data</h3>
                    <div className={styles.formGroup}>
                      <label className={styles.label}></label>
                      <input
                        type="hidden"
                        name="mysteryShopScore"
						placeholder="Mystery Shop Score"
                        value={popsFormData.mysteryShopScore}
                        onChange={handlePopsChange}
                        className={styles.popinput}
                      />
                    </div>
                    <div className={styles.formGroup}>
                      <label className={styles.label}></label>
                      <input
                        type="number"
                        name="syncReports"
						placeholder="Sync Reports"
                        value={popsFormData.syncReports}
                        onChange={handlePopsChange}
                        className={styles.popinput}
                      />
					  <label htmlFor="syncReports" className={styles.popsmallLabel}>Sync Reports MTD</label>
                    </div>
                    <div className={styles.formGroup}>
                      <label className={styles.label}></label>
                      <input
                        type="number"
                        name="actualFoodAndPaperCost"
						placeholder="Actual F&P (Entered as: 28.77)"
                        value={popsFormData.actualFoodAndPaperCost}
                        onChange={handlePopsChange}
                        className={styles.popinput}
                      />
					  <label htmlFor="actualFoodAndPaperCost" className={styles.popsmallLabel}>Actual F&P MTD</label>
                    </div>
                  </>
                )}
              </>
            )}

            <button type="submit" className={styles.button}>Submit</button>
          </form>
        )}
      </div>
    </div>
  </div>
);
}

export default WeeklyDataEntryForm;