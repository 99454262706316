// utils/webSocketUrl.js
export const getWebSocketUrl = () => {
  const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const hostname = window.location.hostname;
  const port = window.location.port ? `:${window.location.port}` : '';

  console.log('WebSocket URL construction details:');
  console.log('Protocol:', protocol);
  console.log('Hostname:', hostname);
  console.log('Port:', port);

  const webSocketUrl = `${protocol}://${hostname}${port}/ws`;

  console.log('Final WebSocket URL:', webSocketUrl);

  return webSocketUrl;
};
