// src/components/ClosingCheckListBuilder.js
import React, { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button, TextField, Container, Typography, Paper, IconButton, Box } from '@mui/material';
import { Add as AddIcon, DragIndicator as DragIndicatorIcon } from '@mui/icons-material';
import styles from './ClosingCheckListBuilder.module.css';

const ItemType = {
  ITEM: 'ITEM',
  SECTION: 'SECTION',
};

function DraggableItem({ item, index, sectionIndex, moveItem, handleItemChange }) {
  const [, ref] = useDrag({
    type: ItemType.ITEM,
    item: { index, sectionIndex },
  });

  const [, drop] = useDrop({
    accept: ItemType.ITEM,
    hover(draggedItem) {
      if (draggedItem.index !== index || draggedItem.sectionIndex !== sectionIndex) {
        moveItem(draggedItem.sectionIndex, draggedItem.index, sectionIndex, index);
        draggedItem.index = index;
        draggedItem.sectionIndex = sectionIndex;
      }
    },
  });

  return (
    <div ref={(node) => ref(drop(node))} className={styles.draggableItem}>
      <Box display="flex" alignItems="center">
        <DragIndicatorIcon className={styles.dragIcon} />
        <TextField
          value={item}
          onChange={(e) => handleItemChange(sectionIndex, index, e)}
          variant="outlined"
          fullWidth
          placeholder="Item"
          className={styles.input}
        />
      </Box>
    </div>
  );
}

function DraggableSection({ section, index, moveSection, children }) {
  const [, ref] = useDrag({
    type: ItemType.SECTION,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType.SECTION,
    hover(draggedSection) {
      if (draggedSection.index !== index) {
        moveSection(draggedSection.index, index);
        draggedSection.index = index;
      }
    },
  });

  return (
    <div ref={(node) => ref(drop(node))} className={styles.draggableSection}>
      <Box display="flex" alignItems="center" className={styles.sectionHeader}>
        <DragIndicatorIcon className={styles.dragIcon} />
        <Typography variant="h6" gutterBottom>
          {section.name}
        </Typography>
      </Box>
      {children}
    </div>
  );
}

function ClosingCheckListBuilder() {
  const [sections, setSections] = useState([]);
  const [newSectionName, setNewSectionName] = useState('');

  const handleNewSectionChange = (event) => {
    setNewSectionName(event.target.value);
  };

  const addSection = () => {
    if (newSectionName.trim()) {
      setSections([...sections, { name: newSectionName, items: [''] }]);
      setNewSectionName(''); // Clear the input after adding the section
    }
  };

  const handleItemChange = (sectionIndex, itemIndex, event) => {
    const newSections = [...sections];
    newSections[sectionIndex].items[itemIndex] = event.target.value;
    setSections(newSections);
  };

  const addItem = (sectionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].items.push('');
    setSections(newSections);
  };

  const moveItem = (fromSectionIndex, fromIndex, toSectionIndex, toIndex) => {
    const newSections = [...sections];
    const [movedItem] = newSections[fromSectionIndex].items.splice(fromIndex, 1);
    newSections[toSectionIndex].items.splice(toIndex, 0, movedItem);
    setSections(newSections);
  };

  const moveSection = (fromIndex, toIndex) => {
    const newSections = [...sections];
    const [movedSection] = newSections.splice(fromIndex, 1);
    newSections.splice(toIndex, 0, movedSection);
    setSections(newSections);
  };

  const handleSubmit = () => {
    console.log('Checklist structure:', sections);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container className={styles.container} maxWidth="md">
        <Typography variant="h4" gutterBottom>
          Closing Checklist Builder
        </Typography>

        <Box className={styles.newSection} display="flex" alignItems="center">
          <TextField
            label="New Section Name"
            value={newSectionName}
            onChange={handleNewSectionChange}
            variant="outlined"
            fullWidth
          />
          <IconButton onClick={addSection} color="primary" aria-label="add section">
            <AddIcon />
          </IconButton>
        </Box>

        {sections.map((section, sectionIndex) => (
          <DraggableSection
            key={sectionIndex}
            section={section}
            index={sectionIndex}
            moveSection={moveSection}
          >
            <Paper className={styles.section} elevation={3}>
              {section.items.map((item, itemIndex) => (
                <DraggableItem
                  key={itemIndex}
                  item={item}
                  index={itemIndex}
                  sectionIndex={sectionIndex}
                  moveItem={moveItem}
                  handleItemChange={handleItemChange}
                />
              ))}
            </Paper>
            <Button
              onClick={() => addItem(sectionIndex)}
              startIcon={<AddIcon />}
              variant="contained"
              color="secondary"
              className={styles.addButton}
            >
              Add Item
            </Button>
          </DraggableSection>
        ))}

        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          className={styles.submitButton}
          size="large"
        >
          Save Checklist
        </Button>
      </Container>
    </DndProvider>
  );
}

export default ClosingCheckListBuilder;
