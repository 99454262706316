//MonthlyManual.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './MonthlyManualForm.module.css'; // Ensure you have corresponding CSS
import ConfirmationModal from './ConfirmationModal'; 
import DownloadExcel from './DownloadExcel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStore, faDownload, faPaperPlane } from '@fortawesome/free-solid-svg-icons';


function MonthlyManual() {
  const [formData, setFormData] = useState({
    storeSelection: '-1',
    combinedFoodAndPaperCost: '',
    idealFoodAndPaper: '',
    totalComplaints: '',
    productComplaints: '',
    foodSafety: '',
    hoursEndingMonth: '',	
  });
  
const getCurrentMonthYear = () => {
  const today = new Date();
  let month = today.getMonth(); // Current month (0-based, January = 0)
  let year = today.getFullYear(); // Current year

  // Get the last day of the current month
  const lastDayOfMonth = new Date(year, month + 1, 0).getDate();

  // Check if today is within the last 4 days of the current month
  if (lastDayOfMonth - today.getDate() >= 4) {
    // If not within the last 4 days, return the previous month
    month = month === 0 ? 11 : month - 1; // If January, switch to December of the previous year
    year = month === 11 ? year - 1 : year;
  }

  // Return the month and year in a "MM/YYYY" format
  return `${month + 1}/${year}`;
};


  const [supervisedStores, setSupervisedStores] = useState([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState(getCurrentMonthYear());
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [onModalConfirm, setOnModalConfirm] = useState(() => () => {});
  const [currentDate, setCurrentDate] = useState(new Date());
  const [hoursEndingPlaceholder, setHoursEndingPlaceholder] = useState('');
  const [mtdPlaceholder, setMtdPlaceholder] = useState('');
  const [formattedMonthYear, setFormattedMonthYear] = useState('');
  const [downloadData, setDownloadData] = useState(null);
  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const [allStoresData, setAllStoresData] = useState([]);
  const [triggerDownload, setTriggerDownload] = useState(false);
  const [kpiGoals, setKPIGoals] = useState([]);
  const [isDownloadEnabled, setIsDownloadEnabled] = useState(false);
  const [missingStores, setMissingStores] = useState([]);

  
  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === '72.167.34.236') {
      return 'http://72.167.34.236:5000';
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return 'https://prolifi.app';
    } else {
      return 'http://localhost:5000'; // For local development
    }
  };
  
  function fetchKPIGoals(baseUrl) {
  return axios.get(`${baseUrl}/api/monthly/kpi-grading-standards`, { withCredentials: true })
    .then((response) => {
      console.log('KPI Goals:', response.data);
      return response.data;
    })
    .catch((error) => {
      console.error('Error fetching KPI goals:', error);
      return [];
    });
}

  useEffect(() => {
    fetchSupervisedStores();
    calculateDates();
    determineHoursEndingPlaceholder();
  }, []);
  
    useEffect(() => {
    const baseUrl = getBaseUrl(); // Retrieve base URL dynamically
    fetchKPIGoals(baseUrl).then(setKPIGoals); // Fetch KPI goals using the URL
  }, []);
  
  useEffect(() => {
  // Log the fetched KPI goals once they've been set in the state
  if (kpiGoals.length > 0) {
    console.log('KPI Goals after setting:', kpiGoals);
  }
}, [kpiGoals]);
  
  useEffect(() => {
    if (formData.storeSelection !== '-1') {
      // Reset form data before fetching new store data
      setFormData({
        storeSelection: formData.storeSelection,
        combinedFoodAndPaperCost: '',
        idealFoodAndPaper: '',
        totalComplaints: '',
        productComplaints: '',
        foodSafety: '',
        hoursEndingMonth: '',  
      });
      fetchStoreData(formData.storeSelection, selectedMonthYear);
    }
  }, [formData.storeSelection, selectedMonthYear]);
  
useEffect(() => {
  let timer;
  if (downloadData) {
    timer = setTimeout(() => {
      setDownloadData(null);  // Reset downloadData to unmount DownloadExcel component
    }, 500); // Adjust this timeout as needed

    // This timeout ensures the DownloadExcel component has time to process the data and trigger the download
  }

  return () => clearTimeout(timer);
}, [downloadData]);
  
const determineHoursEndingPlaceholder = () => {
  const today = new Date();
  const dayOfMonth = today.getDate();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  // Determine whether to use current or previous month based on the day of the month
  const isEndOfMonth = lastDayOfMonth - dayOfMonth < 4;
  let relevantMonth = currentMonth;
  let relevantYear = currentYear;

  if (!isEndOfMonth) {
    // If not in the last four days, calculate dates for the previous month
    relevantMonth = currentMonth === 0 ? 11 : currentMonth - 1; // Handle January case
    relevantYear = currentMonth === 0 ? currentYear - 1 : currentYear;
  }

  // Get the last day of the relevant month
  const lastDayOfRelevantMonth = new Date(relevantYear, relevantMonth + 1, 0);

  // Find the last Monday of the relevant month
  let lastMonday = new Date(lastDayOfRelevantMonth);
  while (lastMonday.getDay() !== 1) {  // Loop until we find Monday (day 1)
    lastMonday.setDate(lastMonday.getDate() - 1);
  }

  // Formatting the dates for the placeholder
  const options = { month: 'short', day: 'numeric', year: 'numeric' };
  const start = lastMonday.toLocaleDateString(undefined, options);
  const end = lastDayOfRelevantMonth.toLocaleDateString(undefined, options);
  setHoursEndingPlaceholder(`${start} - ${end}`);
};


  const fetchSupervisedStores = async () => {
    const baseUrl = getBaseUrl();
    try {
      const response = await axios.get(`${baseUrl}/api/supervised-stores`, { withCredentials: true });
      setSupervisedStores(response.data);
    } catch (error) {
      console.error('Error fetching supervised stores:', error);
    }
  };
  
  const checkStoreEntries = async () => {
  const baseUrl = getBaseUrl();
  const missingEntries = [];

  try {
    const [month, year] = selectedMonthYear.split('/');
    
    for (const storeId of supervisedStores) {
      const response = await axios.get(`${baseUrl}/api/monthly/store-data/${storeId}/${month}/${year}`, {
        withCredentials: true,
      });

      if (!response.data || response.data.length === 0) {
        missingEntries.push(storeId);
      }
    }

    if (missingEntries.length === 0) {
      setIsDownloadEnabled(true);
    } else {
      setIsDownloadEnabled(false);
    }
    setMissingStores(missingEntries);
  } catch (error) {
    console.error('Error checking store entries:', error);
    setIsDownloadEnabled(false);
    setMissingStores(supervisedStores); // Assuming all stores are missing if an error occurs
  }
};

useEffect(() => {
  if (supervisedStores.length > 0) {
    checkStoreEntries();
  }
}, [supervisedStores, formData, selectedMonthYear]);

  
const fetchStoreData = async (storeId, monthYear) => {
  const baseUrl = getBaseUrl();
  try {
    const [month, year] = monthYear.split('/'); // Split monthYear to separate variables
    const response = await axios.get(`${baseUrl}/api/monthly/store-data/${storeId}/${month}/${year}`, { withCredentials: true });

    if (response.data && response.data.length > 0) {
      // Assume that the first item is the relevant submission data
      const submissionData = response.data[0];

      setFormData({
        storeSelection: storeId,
        combinedFoodAndPaperCost: submissionData.actualFnP || '',
        idealFoodAndPaper: submissionData.idealFoodAndPaper || '',
        totalComplaints: submissionData.totalComplaints || '',
        productComplaints: submissionData.productComplaints || '',
        foodSafety: submissionData.foodSafety || '',
        hoursEndingMonth: submissionData.hoursMonthEnd || '',
      });
    } else {
      // Clear the form data if no existing data is found
      setFormData({
        storeSelection: storeId,
        combinedFoodAndPaperCost: '',
        idealFoodAndPaper: '',
        totalComplaints: '',
        productComplaints: '',
        foodSafety: '',
        hoursEndingMonth: '',
      });
    }
  } catch (error) {
    console.error('Error fetching store data:', error);
  }
};

// fetchAllStoresData function inside MonthlyManual component
const fetchAllStoresData = async () => {
  const baseUrl = getBaseUrl();
  const storeDataList = [];
  let kpiGradingStandards = [];

  try {
    const [month, year] = selectedMonthYear.split('/'); // Use selectedMonthYear from state

    // Fetch KPI grading standards
    const kpiResponse = await axios.get(`${baseUrl}/api/monthly/kpi-grading-standards`, {
      withCredentials: true,
    });
    if (kpiResponse.data) {
      kpiGradingStandards = kpiResponse.data;
      console.log('KPI Grading Standards:', kpiGradingStandards); // Log the KPI grading standards
    }

    // Iterate over supervised store IDs to fetch store data
    for (const storeId of supervisedStores) {
      const response = await axios.get(`${baseUrl}/api/monthly/store-data/${storeId}/${month}/${year}`, {
        withCredentials: true,
      });
      if (response.data && response.data.length > 0) {
        response.data.forEach(item => {
          // Convert specified fields to numbers
          item.storeSelection = parseInt(item.storeSelection, 10);
          item.actualFnP = parseFloat(item.actualFnP);
          item.idealFoodAndPaper = parseFloat(item.idealFoodAndPaper);
          item.totalComplaints = parseInt(item.totalComplaints, 10);
          item.productComplaints = parseInt(item.productComplaints, 10);
          item.mysteryShop = item.mysteryShop !== null ? parseFloat(item.mysteryShop) : null;
          item.foodSafety = parseFloat(item.foodSafety);
          item.hoursMonthEnd = parseFloat(item.hoursMonthEnd);
          item.submissionMonth = parseInt(item.submissionMonth, 10);
          item.submissionYear = parseInt(item.submissionYear, 10);
          item.totalTickets = parseInt(item.totalTickets, 10);
          item.totalSales = parseFloat(item.totalSales);
          item.weightedReplyTime = parseFloat(item.weightedReplyTime);
          item.weightedAveTime = parseFloat(item.weightedAveTime);
          item.onTimePercentage = parseFloat(item.onTimePercentage);
          item.totalHours = parseFloat(item.totalHours);

          // Push the modified item into the storeDataList
          storeDataList.push(item);
        });
      }
    }
    return { storeDataList, kpiGradingStandards }; // Return the data and grading standards
  } catch (error) {
    console.error('Error fetching data for all stores:', error);
    return { storeDataList: [], kpiGradingStandards: [] }; // Return empty arrays in case of error
  }
};







	   const handleChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	  };
	  
				const handleModalClose = () => {
			setShowConfirmationModal(false);
		  };

		  const confirmSubmission = () => {
			setShowConfirmationModal(false);
			onModalConfirm();
		  };

const handleSubmit = async (e) => {
  e.preventDefault();

  if (formData.storeSelection === '-1') {
    alert('Please select a store');
    return;
  }

  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
  const currentYear = currentDate.getFullYear();

  // Function to show confirmation modal before submitting
const showConfirmationBeforeSubmit = () => {
  const { submissionMonth, submissionYear } = calculateSubmissionDate();

  // Convert the submissionMonth (which is a number) back to a month name
  const submissionMonthName = new Date(submissionYear, submissionMonth - 1).toLocaleString('default', { month: 'long' });

  setModalTitle(`Confirm Submission`);
  setModalContent(`Submitting data for ${submissionMonthName} ${submissionYear}. Do you want to continue?`);
  setOnModalConfirm(() => submitData); // Set this function to actually submit the data
  setShowConfirmationModal(true);
};


const calculateSubmissionDate = () => {
  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth(); // Note: January is 0, February is 1, and so on.
  const currentYear = today.getFullYear();

  // Get the total number of days in the current month
  const daysInCurrentMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  if (daysInCurrentMonth - currentDay < 4) {
    // We are in the last 4 days of the month, submit for the current month and year
    return { submissionMonth: currentMonth + 1, submissionYear: currentYear };
  } else {
    // Not within the last 4 days, submit for the previous month
    // When currentMonth is January (0), the previous month is December of the previous year
    const previousMonth = currentMonth === 0 ? 12 : currentMonth;
    const submissionYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    return { submissionMonth: previousMonth, submissionYear };
  }
};



// Function to actually submit the data
const submitData = async () => {
  setShowConfirmationModal(false); // Close the modal first

const { submissionMonth, submissionYear } = calculateSubmissionDate();
console.log(`Submitting for month: ${submissionMonth}, year: ${submissionYear}`);

  // Since setState actions are asynchronous and we need the updated state immediately for submission,
  // we'll prepare the updated form data but not set it yet. We'll only reset the form after submission.
  const updatedFormData = {
    ...formData,
    submissionMonth,
    submissionYear,
	mysteryShop: null
    // You can include other fields that need to be updated or sent with the submission
  };

  const baseUrl = getBaseUrl(); // This should return your API's base URL
  const submitUrl = `${baseUrl}/api/monthly/monthly-manual-data-entry`;

  try {
    // Use updatedFormData directly for submission
    const response = await axios.post(submitUrl, updatedFormData, { withCredentials: true });
    alert('Data submitted successfully');
    console.log("Response from server:", response.data);

	setDownloadData(updatedFormData);
	 await checkStoreEntries();
	 
    // After successful submission, reset the form state to initial values
    setFormData({
      storeSelection: '-1',
	  combinedFoodAndPaperCost: '',
      idealFoodAndPaper: '',
      totalComplaints: '',
      productComplaints: '',
      foodSafety: '',
	  hoursEndingMonth: '',
      // Ensure to remove or reset the dynamic fields as well if necessary
    });
  } catch (error) {
    if (error.response?.status === 409 && error.response.data.message === "Duplicate entry") {
      const id = error.response.data.id; // Capture the ID from the response
      handleDuplicateEntry(id); // Pass it to handleDuplicateEntry for further processing
    } else {
      console.error('Error submitting data:', error);
      alert('Error submitting data');
    }
  }
};


// Function to handle a duplicate entry scenario
const handleDuplicateEntry = (id) => {
  // Instead of relying on formData state update, directly pass the id to updateData.
  setModalTitle("Duplicate Entry Detected");
  setModalContent(`There is already a database entry for store ${formData.storeSelection}. Do you want to update the entry or cancel submission?`);
  setOnModalConfirm(() => () => updateData(id)); // Pass ID directly
  setShowConfirmationModal(true);
};

const updateData = async (id) => { // Accept ID as a parameter
  console.log("Updating data...");
  const { submissionMonth, submissionYear } = calculateSubmissionDate();
    const updatedFormData = {
    ...formData,
    submissionMonth,
    submissionYear,
	mysteryShop: null
    // You can include other fields that need to be updated or sent with the submission
  };

  const baseUrl = getBaseUrl();
  const updateUrl = `${baseUrl}/api/monthly/monthly-manual-data-entry/${id}`; // Use the passed ID

  try {
    const response = await axios.put(updateUrl, formData, { withCredentials: true });
    alert('Entry updated successfully');
	console.log("Response from server:", response.data);
	setDownloadData(updatedFormData);
	 await checkStoreEntries();
    // Consider resetting the formData here, especially clearing the id to prepare for new submissions.
    setShowConfirmationModal(false); // Ensure modal is closed in any case
  } catch (error) {
    console.error('Error updating form:', error);
    alert('Error updating data');
  }
};

  // Open the confirmation modal
  showConfirmationBeforeSubmit();
};

 // const formattedMonthYear = currentDate.toLocaleString('default', { month: 'long', year: 'numeric' });
  const calculateFormattedMonthYear = () => {
    const today = new Date();
    const dayOfMonth = today.getDate();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

    if (lastDayOfMonth - dayOfMonth < 4) {
      // If within the last four days of the month, show current month and year
      setFormattedMonthYear(today.toLocaleString('default', { month: 'long', year: 'numeric' }));
    } else {
      // Otherwise, show the previous month and adjust year if needed
      const previousMonthDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      setFormattedMonthYear(previousMonthDate.toLocaleString('default', { month: 'long', year: 'numeric' }));
    }
  };
  
    const calculateDates = () => {
    const today = new Date();
    const dayOfMonth = today.getDate();
    const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();

    if (lastDayOfMonth - dayOfMonth < 4) {
      // If within the last four days of the month, use the current month and year
      const currentMonthYear = today.toLocaleString('default', { month: 'long', year: 'numeric' });
      setFormattedMonthYear(currentMonthYear);
      setMtdPlaceholder(`MTD: ${currentMonthYear}`);
    } else {
      // Otherwise, use the previous month and adjust year if needed
      const previousMonthDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const previousMonthYear = previousMonthDate.toLocaleString('default', { month: 'long', year: 'numeric' });
      setFormattedMonthYear(previousMonthYear);
      setMtdPlaceholder(`MTD: ${previousMonthYear}`);
    }
  };
  
  const handleDownloadClick = async () => {
    await fetchAllStoresData(); // Ensure all stores data is fetched before downloading
    setTriggerDownload(true);
    setTimeout(() => setTriggerDownload(false), 500); // Reset trigger
  };
  
  const resetDownload = () => {
  setIsDownloadReady(false); // Reset the trigger for subsequent downloads
};

const handleDownloadBaseFile = async () => {
  const baseUrl = getBaseUrl(); // Retrieve the base URL

  try {
    // Fetch all stores data and KPI grading standards
    const { storeDataList, kpiGradingStandards } = await fetchAllStoresData(); 

    // Ensure that all data is populated before proceeding
    console.log("All Stores Data: ", storeDataList);
    console.log("KPI Grading Standards: ", kpiGradingStandards);

    // Combine the data into a single object to send to the backend
    const requestData = {
      allStoresData: storeDataList,
      kpiGradingStandards
    };

    // Now send the data to the backend and download the file
    const response = await axios.post(`${baseUrl}/api/monthly/download-base-file`, requestData, {
      responseType: 'blob',
      withCredentials: true,
    });

    // Get the current date in "MM-DD-YYYY" format
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric'
    }).replace(/\//g, '-'); // Replace slashes with dashes for file name compatibility

    // Set the file name
    const fileName = `PL-Review ${formattedDate}.xlsx`;

    // Create a download link for the Excel file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // Specify the file name dynamically
    document.body.appendChild(link);
    link.click(); // Trigger the download
    link.parentNode.removeChild(link); // Clean up the link element
  } catch (error) {
    console.error('Error downloading the base file:', error);
    alert('Failed to download the base file');
  }
};



  return (
    <div className={styles.mainContainer}>
      <h2 className={styles.title}>Monthly Manual Data Entry ({formattedMonthYear})</h2>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="storeSelection" className={styles.label}>
            <FontAwesomeIcon icon={faStore} /> Select Store:
          </label>
          <select
            name="storeSelection"
            onChange={handleChange}
            value={formData.storeSelection}
            className={styles.input}
            required
          >
            <option value="-1">Please select a store</option>
            {supervisedStores.map((store, index) => (
              <option key={index} value={store}>{store}</option>
            ))}
          </select>
        </div>

        {formData.storeSelection !== '-1' && (
          <>
            <div className={styles.sectionsContainer}>
              <div className={styles.sectionContainer}>
                <h3 className={styles.sectionHeader}>Inventory Data</h3>
                <div className={styles.formGroup}>
                  <label htmlFor="combinedFoodAndPaperCost" className={styles.label}>
                    Actual Food & Paper Cost:
                  </label>
                  <input
                    type="number"
                    name="combinedFoodAndPaperCost"
                    placeholder={mtdPlaceholder}
                    onChange={handleChange}
                    value={formData.combinedFoodAndPaperCost}
                    className={styles.input}
                    required
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="idealFoodAndPaper" className={styles.label}>
                    Ideal Food & Paper:
                  </label>
                  <input
                    type="number"
                    name="idealFoodAndPaper"
                    placeholder={mtdPlaceholder}
                    onChange={handleChange}
                    value={formData.idealFoodAndPaper}
                    className={styles.input}
                    required
                  />
                </div>
              </div>
              <div className={styles.sectionContainer}>
                <h3 className={styles.sectionHeader}>Customer Complaints</h3>
                <div className={styles.formGroup}>
                  <label htmlFor="totalComplaints" className={styles.label}>
                    Total Complaints:
                  </label>
                  <input
                    type="number"
                    name="totalComplaints"
                    placeholder={mtdPlaceholder}
                    onChange={handleChange}
                    value={formData.totalComplaints}
                    className={styles.input}
                    required
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="productComplaints" className={styles.label}>
                    Product Complaints:
                  </label>
                  <input
                    type="number"
                    name="productComplaints"
                    placeholder={mtdPlaceholder}
                    onChange={handleChange}
                    value={formData.productComplaints}
                    className={styles.input}
                    required
                  />
                </div>
              </div>
              <div className={styles.sectionContainer}>
                <h3 className={styles.sectionHeader}>Additional Data</h3>
                <div className={styles.formGroup}>
                  <label htmlFor="foodSafety" className={styles.label}>
                    Food Safety:
                  </label>
                  <input
                    type="number"
                    name="foodSafety"
                    onChange={handleChange}
                    value={formData.foodSafety}
                    className={styles.input}
                    required
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor="hoursEndingMonth" className={styles.label}>
                    Hours Ending Month:
                  </label>
                  <input
                    type="number"
                    name="hoursEndingMonth"
                    placeholder={hoursEndingPlaceholder}
                    onChange={handleChange}
                    value={formData.hoursEndingMonth}
                    className={styles.input}
                    required
                  />
                </div>
              </div>
            </div>
            <ConfirmationModal
              open={showConfirmationModal}
              onClose={handleModalClose}
              onConfirm={confirmSubmission}
              title={modalTitle}
            >
              {modalContent}
            </ConfirmationModal>
            <button type="submit" className={styles.submitButton}>
              <FontAwesomeIcon icon={faPaperPlane} /> Submit
            </button>
          </>
        )}
      </form>
		<button
		  onClick={handleDownloadBaseFile}
		  className={styles.downloadButton}
		  disabled={!isDownloadEnabled}
		>
		  <FontAwesomeIcon icon={faDownload} /> {isDownloadEnabled ? 'Download P&L Review Document' : 'Download Excel'}
		</button>

      <DownloadExcel data={allStoresData} kpiGoals={kpiGoals} triggerDownload={triggerDownload} />
	  
			  {!isDownloadEnabled && missingStores.length > 0 && (
		  <div className={styles.warning}>
			<p>The following stores still need data submitted for {selectedMonthYear}:</p>
			<ul>
			  {missingStores.map((store, index) => (
				<li key={index}>{store}</li>
			  ))}
			</ul>
		  </div>
		)}

    </div>
  );
}

export default MonthlyManual;