import React, { useState } from 'react';
import axios from 'axios';

const getBaseUrl = () => {
  const hostname = window.location.hostname;

  if (hostname === '72.167.34.236') {
    return 'http://72.167.34.236:5000';
  } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
    return 'https://prolifi.app';
  } else {
    console.error('Unknown host');
    return ''; // Return an empty string or a default URL if preferred
  }
};

const ExcelUploader = () => {
  const [message, setMessage] = useState('Connected to component successfully');
  const [employeesOnShiftFile, setEmployeesOnShiftFile] = useState(null);
  const [daypartKPIsFile, setDaypartKPIsFile] = useState(null);

  const handleFileChange = (event, setFile) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!employeesOnShiftFile || !daypartKPIsFile) {
      setMessage('Please upload both files.');
      return;
    }

    const formData = new FormData();
    formData.append('employeesOnShift', employeesOnShiftFile);
    formData.append('daypartKPIs', daypartKPIsFile);

    try {
      const baseUrl = getBaseUrl();
      const response = await axios.post(`${baseUrl}/api/upload-excel-files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setMessage(response.data.message || 'Files uploaded successfully!');
    } catch (error) {
      console.error('Error uploading files:', error);
      setMessage('Error uploading files.');
    }
  };

  const handleFetchEmails = async () => {
    try {
      const baseUrl = getBaseUrl();
      const response = await axios.get(`${baseUrl}/api/fetch-exp`);
      setMessage(response.data.message || 'Fetched emails and processed files successfully!');
    } catch (error) {
      console.error('Error fetching emails:', error);
      setMessage('Error fetching emails.');
    }
  };

  return (
    <div>
      <h2>{message}</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Employees on Shift:</label>
          <input
            type="file"
            accept=".csv"
            onChange={(e) => handleFileChange(e, setEmployeesOnShiftFile)}
          />
        </div>
        <div>
          <label>Daypart KPIs:</label>
          <input
            type="file"
            accept=".csv"
            onChange={(e) => handleFileChange(e, setDaypartKPIsFile)}
          />
        </div>
        <button type="submit">Upload Files</button>
      </form>
      <button onClick={handleFetchEmails}>Fetch and Process Emails</button>
    </div>
  );
};

export default ExcelUploader;
