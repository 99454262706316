import React, { useEffect, useState } from 'react';
import './GradingStandardsEditor.css';
import Switch from 'react-switch';
import axios from 'axios';

function GradingStandardsEditor() {
  const [initialStandards, setInitialStandards] = useState({});
  const [gradingStandards, setGradingStandards] = useState({});
  const [currentGroup, setCurrentGroup] = useState('Profitability');
  const [userData, setUserData] = useState({});
  const [selectedArea, setSelectedArea] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [changesToConfirm, setChangesToConfirm] = useState([]);

  const kpiGroups = {
    Profitability: ['SalesChange', 'ContExpenses', 'CrewProduction', 'LaborPercentage', 'FoodVarianceMonth', 'FormattedCash'],
    Service: ['ComplaintsPer10k', 'OnTimesPercentage', 'FormattedAveTime', 'FormattedReplyTime']
  };
  
  const labelMappings = {
  SalesChange: {
    yellowThreshold: 'Red Threshold'  // Changes the label for yellowThreshold to 'Red Threshold'
  }
  // Add more KPIs and label modifications as needed
};

  const handleInputChange = (kpiName, thresholdType, value) => {
    setGradingStandards(prev => ({
      ...prev,
      [kpiName]: {
        ...prev[kpiName],
        [thresholdType]: value
      }
    }));
  };

  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    return hostname.includes('prolifi.app') ? 'https://prolifi.app' : 'http://72.167.34.236:5000';
  };

  useEffect(() => {
    const baseUrl = getBaseUrl();
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/egrading-standards/user-session-data`, { withCredentials: true });
        if (response.data.success) {
          setUserData(response.data.data);
          setSelectedArea(response.data.data.areas[0] || '');
        } else {
          setError('Failed to fetch user data');
        }
      } catch (error) {
        setError('Error fetching user data');
      }
      setLoading(false);
    };
    fetchUserData();
  }, []);

useEffect(() => {
  if (selectedArea) {
    const baseUrl = getBaseUrl();
    const fetchGradingStandards = async () => {
      try {
        const response = await axios.get(`${baseUrl}/api/egrading-standards/grading-standards/${selectedArea}`, { withCredentials: true });
        if (response.data.success) {
          const standards = {};
          response.data.data.forEach(item => {
            standards[item.kpi_name] = {
              greenThreshold: item.green_threshold,
              yellowThreshold: item.yellow_threshold,
              redThreshold: item.red_threshold
            };
          });
          setGradingStandards(standards);
          setInitialStandards(standards); // Set initial standards to the newly fetched standards
        } else {
          setError('No grading standards found for the selected area.');
          setGradingStandards({});
          setInitialStandards({});
        }
      } catch (error) {
        setError('Failed to fetch grading standards');
      }
    };
    fetchGradingStandards();
  }
}, [selectedArea]); 

const resetToInitialStandards = () => {
  setGradingStandards({...initialStandards});
  setShowConfirmation(false); // Also hide the confirmation dialog
};

const prepareSubmission = () => {
  const changes = [];
  const updatedStandards = {};

  Object.keys(gradingStandards).forEach(kpiName => {
    const current = gradingStandards[kpiName];
    const initial = initialStandards[kpiName] || {};
    let hasChanges = false;
    const kpiChanges = {};

    ['greenThreshold', 'yellowThreshold', 'redThreshold'].forEach(threshold => {
      if (current[threshold] !== initial[threshold]) {
        kpiChanges[threshold] = current[threshold];
        changes.push(`${kpiName} - ${threshold}: from ${initial[threshold] || 'none'} to ${current[threshold]}`);
        hasChanges = true;
      }
    });

    if (hasChanges) {
      updatedStandards[kpiName] = kpiChanges;
    }
  });

  if (Object.keys(updatedStandards).length) {
    setChangesToConfirm(changes);
    setShowConfirmation(true);
    // Store the updated standards to be submitted
    setGradingStandards(updatedStandards);
  } else {
    alert('No changes to submit.');
  }
};

const handleSubmit = async () => {
  if (!showConfirmation) {
    prepareSubmission();
    return;
  }

  const baseUrl = getBaseUrl();
  try {
    const response = await axios.post(`${baseUrl}/api/egrading-standards/update-grading-standards`, {
      area: selectedArea,
      gradingStandards  // Send the modified standards only
    });
    console.log(response.data);
    alert('Grading standards updated successfully!');
    setShowConfirmation(false);
    fetchGradingStandards(selectedArea); // Refresh the form data after a successful update
  } catch (error) {
    console.error('Failed to update grading standards:', error);
    alert('Failed to update grading standards.');
  }
};

const fetchGradingStandards = async (area) => {
  const baseUrl = getBaseUrl();
  try {
    const response = await axios.get(`${baseUrl}/api/egrading-standards/grading-standards/${area}`, { withCredentials: true });
    if (response.data.success) {
      const standards = {};
      response.data.data.forEach(item => {
        standards[item.kpi_name] = {
          greenThreshold: item.green_threshold,
          yellowThreshold: item.yellow_threshold,
          redThreshold: item.red_threshold
        };
      });
      setGradingStandards(standards);
      setInitialStandards(standards); // Update the initial standards to reflect the latest state
    } else {
      setError('No grading standards found for the selected area.');
      setGradingStandards({});
      setInitialStandards({});
    }
  } catch (error) {
    setError('Failed to fetch grading standards');
    console.error('Error fetching grading standards:', error);
  }
};





  if (loading) return <div>Loading...</div>;

  return (
    <div className="editor-container">
      <h1>Edit Grading Standards</h1>
      <div className="selection-container">
        <label htmlFor="area-select">Select Area:</label>
        <select id="area-select" value={selectedArea} onChange={(e) => setSelectedArea(e.target.value)} disabled={userData.areas?.length <= 1}>
          {userData.areas?.map(area => (
            <option key={area} value={area}>{area}</option>
          ))}
        </select>
      </div>
      <div className="toggle-container">
        <Switch checked={currentGroup === 'Profitability'} onChange={checked => setCurrentGroup(checked ? 'Profitability' : 'Service')} onColor="#00BFFF" onHandleColor="#FFFFFF" handleDiameter={30} uncheckedIcon={false} checkedIcon={false} boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)" activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)" height={20} width={48} className="react-switch" id="material-switch" />
        <label htmlFor="material-switch" style={{ marginLeft: 10, fontWeight: 'bold' }}>{currentGroup}</label>
      </div>
      <form>
        <div className="kpi-sections-container">
		{kpiGroups[currentGroup].map(kpiName => {
		  const thresholds = gradingStandards[kpiName] || { greenThreshold: '', yellowThreshold: '', redThreshold: '' };
		  const customLabels = labelMappings[kpiName] || {};
		  
            return (
              <div key={kpiName} className="kpi-section">
                <h2>{kpiName}</h2>
                <div className="input-group">
                  <label>Green Threshold:</label>
                  <input type="number" value={thresholds.greenThreshold} onChange={e => handleInputChange(kpiName, 'greenThreshold', e.target.value)} />
                </div>
                {kpiName === 'SalesChange' ? (
                  <div className="input-group">
                    <label>{labelMappings.SalesChange.yellowThreshold}:</label>
                    <input type="number" value={thresholds.yellowThreshold} onChange={e => handleInputChange(kpiName, 'yellowThreshold', e.target.value)} />
                  </div>
                ) : (
                  thresholds.yellowThreshold !== null && (
                    <div className="input-group">
                      <label>Yellow Threshold:</label>
                      <input type="number" value={thresholds.yellowThreshold} onChange={e => handleInputChange(kpiName, 'yellowThreshold', e.target.value)} />
                    </div>
                  )
                )}
                {kpiName !== 'SalesChange' && thresholds.redThreshold !== null && (
                  <div className="input-group">
                    <label>Red Threshold:</label>
                    <input type="number" value={thresholds.redThreshold} onChange={e => handleInputChange(kpiName, 'redThreshold', e.target.value)} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <button className="save-button" type="button" onClick={handleSubmit}>Save Changes</button>
      </form>
		{showConfirmation && (
		  <div className="confirmation-dialog">
			<h3>You are submitting the following changes to {selectedArea} Standards:</h3>
			<ul>
			  {changesToConfirm.map(change => (
				<li key={change}>{change}</li>
			  ))}
			</ul>
			<div className="button-container">
			  <button onClick={handleSubmit}>Confirm</button>
			  <button onClick={resetToInitialStandards}>Cancel</button>
			</div>
		  </div>
		)}
  </div>
);
}

export default GradingStandardsEditor;
