import React from 'react';
import NavBar from './NavBar'; // Adjust the import path as necessary

function BlogPage() {
  return (
    <>
      <NavBar />
      <div>
        <h1>Blog Title</h1>
        <p>This is the blog content...</p>
        {/* More blog content here */}
      </div>
    </>
  );
}

export default BlogPage;
