// WeeklySalesTable.js
import React from 'react';
import styles from './WeeklySalesTable.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// ... (Any additional imports or code that might exist here)

const daysPassedInWeek = (date) => {
  const currentDayOfWeek = date.getDay(); // Sunday - 0, Monday - 1, ... Saturday - 6
  return currentDayOfWeek === 0 ? 7 : currentDayOfWeek; // Assume Sunday is the end of the week
};

function calculatePercentDifference(current, previous) {
  if (previous === 0) return 'N/A'; // Avoid division by zero
  return (((current - previous) / previous) * 100).toFixed(2) + '%';
}

function calculateTicketAverage(netSales, tickets) {
  if (tickets === 0) return 'N/A';
  return (netSales / tickets).toFixed(2);
}

function formatCurrency(number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
}

function formatNumber(number) {
  return new Intl.NumberFormat().format(number);
}

function calculateLaborPercentage(labor, netSales) {
  if (netSales === 0) return 'N/A'; // Avoid division by zero
  return (labor / netSales * 100).toFixed(2); // This will give a ratio; multiply by 100 if you want a percentage
}

function getWeekNumber(date) {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - startOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
}

export function WeeklySalesTable({ weeklySalesData }) {
  if (!weeklySalesData) {
    console.log('No weekly sales data provided.');
    return <p>No sales data available.</p>;
  }

  const headers = [
    "#",
    "Supervisor Name",
    "Store Name",
    "Net Sales This Year",
    "Net Sales Last Year",
    "Net Sales % +/-",
    "MGR Labor",
    "Crew Labor",
    "Total Labor",
    "Tickets This Year",
    "Tickets Last Year",
    "Tickets % +/-",
    "Ticket Average",
    "On time%" // Add more headers if needed
  ];

  // Sort weeklySalesData by net sales for this year in descending order
  const sortedWeeklySalesData = Object.values(weeklySalesData).sort((a, b) => {
    return b.currentWeek.netSales - a.currentWeek.netSales;
  });

  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table aria-label="weekly sales table" className={styles.table}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedWeeklySalesData.map((data, index) => {
            // Calculate values as before
            const netSalesPercentDiff = calculatePercentDifference(
              parseFloat(data.currentWeek.netSales),
              parseFloat(data.lastYearSameWeek.netSales)
            );

            const ticketsPercentDiff = calculatePercentDifference(
              parseInt(data.currentWeek.tickets, 10),
              parseInt(data.lastYearSameWeek.tickets, 10)
            );

            const ticketAverage = calculateTicketAverage(
              parseFloat(data.currentWeek.netSales),
              parseInt(data.currentWeek.tickets, 10)
            );

						// Calculate days passed in the week including today
						// Get the current date and its week number
			const today = new Date();
			const currentWeekNumber = getWeekNumber(today);

			// Get the week number of the data being processed
			const dataWeekNumber = getWeekNumber(new Date(data.currentWeek.date)); // Assuming 'date' is available in your data structure

			let proratedManagerLabor;

			// Check if the week of the data is the current week
			if (currentWeekNumber === dataWeekNumber) {
			  // If it is the current week, calculate days passed and prorate the manager's labor
			  const daysPassed = daysPassedInWeek(today) - 1;
			  proratedManagerLabor = (913 / 7) * daysPassed;
			} else {
			  // If it is a past week, use the full week's labor value
			  proratedManagerLabor = 913; // Full week's labor
			}

			// Calculate manager labor percentage with prorated labor
			const managerLaborPercent = calculateLaborPercentage(
			  proratedManagerLabor,
			  parseFloat(data.currentWeek.netSales)
			);


            const crewLaborPercent = calculateLaborPercentage(
              parseFloat(data.currentWeek.labor),
              parseFloat(data.currentWeek.netSales)
            );
            const totalLabor = parseFloat(managerLaborPercent) + parseFloat(crewLaborPercent);
            
            // Calculate On-Time Percentage
            const onTimePercentage = data.currentWeek.ontimeTickets && data.currentWeek.tickets > 0 
              ? ((data.currentWeek.ontimeTickets / data.currentWeek.tickets) * 100).toFixed(2) + '%'
              : 'N/A';

            return (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{data.supervisorName}</TableCell>
                <TableCell>{data.storeName}</TableCell>
                <TableCell>{formatCurrency(data.currentWeek.netSales)}</TableCell>
                <TableCell>{formatCurrency(data.lastYearSameWeek.netSales)}</TableCell>
                <TableCell>{netSalesPercentDiff}</TableCell>
                <TableCell>{managerLaborPercent}</TableCell>
                <TableCell>{crewLaborPercent}</TableCell>
                <TableCell>{(totalLabor).toFixed(2)}</TableCell>
                <TableCell>{formatNumber(data.currentWeek.tickets)}</TableCell>
                <TableCell>{formatNumber(data.lastYearSameWeek.tickets)}</TableCell>
                <TableCell>{ticketsPercentDiff}</TableCell>
                <TableCell>{ticketAverage}</TableCell>
                <TableCell>{onTimePercentage}</TableCell>
                {/* More cells if necessary */}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}