// formatSheet.js
import ExcelJS from 'exceljs';

const applyFormatting = (worksheet, lastRow) => {
  // Set widths for columns A, B, and C
  worksheet.getColumn('A').width = 2.71;
  worksheet.getColumn('B').width = 19;
  worksheet.getColumn('C').width = 23.38;
  // Add other column widths if needed

  // Merge and style Region: B1:N1 (Header 1)
  worksheet.mergeCells('B1:N1');
  const header1 = worksheet.getCell('B1');
  header1.value = 'Weekly Sales Report';  // Placeholder value
  header1.font = { size: 14, bold: true };
  header1.alignment = { horizontal: 'center', vertical: 'middle' };
  
  // Merge and style Region: B2:N2 (Header 2)
  worksheet.mergeCells('B2:N2');
  const header2 = worksheet.getCell('B2');
  header2.font = { size: 11 };
  header2.alignment = { horizontal: 'center', vertical: 'middle' };
  
  // Merge and style Region: D4:F4
  worksheet.mergeCells('D4:F4');
  const header3 = worksheet.getCell('D4');
  header3.font = { size: 13, bold: true };
  header3.alignment = { horizontal: 'center', vertical: 'middle' };
  
    // Merge and style Region: D4:F4
  worksheet.mergeCells('G4:I4');
  const header4 = worksheet.getCell('G4');
  header4.font = { size: 13, bold: true };
  header4.alignment = { horizontal: 'center', vertical: 'middle' };
  
    // Merge and style Region: D4:F4
  worksheet.mergeCells('J4:L4');
  const header5 = worksheet.getCell('J4');
  header5.font = { size: 13, bold: true };
  header5.alignment = { horizontal: 'center', vertical: 'middle' };
  
    const header6 = worksheet.getCell('M4');
  header6.font = { size: 13, bold: true };
  header6.alignment = { horizontal: 'center', vertical: 'middle' };
  
    const header7 = worksheet.getCell('N4');
  header7.font = { size: 13, bold: true };
  header7.alignment = { horizontal: 'center', vertical: 'middle' };

  // Repeat the process for other merged regions as needed

  // Style header row (D5:N5)
  for (let i = 4; i <= 14; ++i) { // Column letters D to N are indices 4 to 14
    const headerCell = worksheet.getCell(5, i); // 5th row, ith column
    headerCell.font = { bold: true, size: 11 };
    headerCell.alignment = { horizontal: 'center', vertical: 'middle' };
  }

worksheet.eachRow((row, rowNumber) => {
  if (rowNumber > 5) { // Assuming headers are in the first five rows
    row.eachCell((cell, colNumber) => {
      const columnLetter = cell._address.match(/[A-Z]+/)[0]; // Get column letter from address
      if (['D', 'E', 'J', 'K'].includes(columnLetter)) {
        // Convert string to number if it contains a numeric value
        if (cell.value !== null && !isNaN(parseFloat(cell.value))) {
          cell.value = parseFloat(cell.value);
          cell.numFmt = '#,##0'; // Number format for 1,234
        }
      } else if (['F', 'L'].includes(columnLetter)) {
        if (cell.value !== null && typeof cell.value === 'string') {
          cell.value = cell.value + '%'; // Append a percentage symbol
        }
      }

      // Align all cells from columns D to N to the right
      if ('D' <= columnLetter && columnLetter <= 'N') {
        cell.alignment = { horizontal: 'right' };
      }
    });
  }
});

   //Format 'Same Store Results' row numbers if needed
  worksheet.eachRow((row, rowNumber) => {
    const cell = row.getCell(3); // 3rd column (Column C)
    if (cell.value === 'Same Store Results') {
      cell.font = { bold: true };
      cell.alignment = { horizontal: 'center', vertical: 'middle' };
      return;
    }
  });
  
  // Loop through each row starting from row 6
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber >= 6) { // Start conversion from the 6th row
      row.eachCell((cell, colNumber) => {
        // Check if the cell is in columns D to N
        if (colNumber >= 4 && colNumber <= 14) { // Columns D to N are indices 4 to 14
          let cellValue = cell.value;
          // Check if the cell value is a string that can be converted to a number
          if (typeof cellValue === 'string') {
            // Remove any non-numeric characters except the minus sign and decimal point
            cellValue = cellValue.replace(/[^\d.-]/g, '');
            if (!isNaN(cellValue)) {
              cell.value = Number(cellValue); // Convert the string to a number
              // If it's a percentage column, apply the percentage format
              if (['F', 'L'].includes(cell._address.match(/[A-Z]+/)[0])) {
                cell.value /= 100; // Convert percentage to decimal
                cell.numFmt = '0.00%'; // Apply Excel's percentage format
              } else {
                cell.numFmt = '0.00'; // Apply number format with two decimal places
              }
            }
          }
        }
      });
    }
  });
  
    worksheet.autoFilter = {
    from: {
      row: 5,
      column: 2 // Column B
    },
    to: {
      row: lastRow,
      column: 14 // Column N
    }
  };
  
  worksheet.getColumn('D').width = 13;
  worksheet.getColumn('E').width = 13;
  worksheet.getColumn('F').width = 13;
  worksheet.getColumn('G').width = 13;
  worksheet.getColumn('H').width = 13;
  worksheet.getColumn('I').width = 13;
  worksheet.getColumn('J').width = 13;
  worksheet.getColumn('K').width = 13;
  worksheet.getColumn('L').width = 13;
  worksheet.getColumn('M').width = 13;
  worksheet.getColumn('N').width = 13;
  
  
  
};

export { applyFormatting };

const applyProfitabilityFormatting = (worksheet) => {
  // Merge and style Region: B1:J2 (Header) for the main title
  worksheet.mergeCells('B1:J2');
  const header = worksheet.getCell('B1');
  header.value = 'Weekly Profitability Report'; // Set the main title value
  header.font = { size: 14, bold: true };
  header.alignment = { horizontal: 'center', vertical: 'middle' };

  // Merge and Center C3:D3, E3:F3, G3:H3, and I3:J3 with Bold textsize 14
  const mergeRanges = ['C3:D3', 'E3:F3', 'G3:H3', 'I3:J3'];
  mergeRanges.forEach(range => {
    worksheet.mergeCells(range);
    const mergedCell = worksheet.getCell(range.split(':')[0]);
    mergedCell.font = { size: 14, bold: true };
    mergedCell.alignment = { horizontal: 'center', vertical: 'middle' };
  });

  // Style Cells A4:M4 with Bold text size 12
  for (let i = 1; i <= 13; ++i) { // Columns A to M are indices 1 to 13
    const cell = worksheet.getCell(4, i);
    cell.font = { bold: true, size: 12 };
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
  }

  // Set column widths and vertical alignment for column A and B - Assuming autosize
  worksheet.getColumn('A').width = 19; // Temporary width
  worksheet.getColumn('B').width = 19; // Temporary width
  worksheet.getColumn('A').alignment = { vertical: 'middle' };
  worksheet.getColumn('B').alignment = { vertical: 'middle' };

  // Set column widths for columns C to M
  for (let i = 3; i <= 13; ++i) { // Columns C to M are indices 3 to 13
    worksheet.getColumn(i).width = 16.29;
  }

  // Autosize columns A and B - This will simulate autosize formatting since ExcelJS doesn't support true autosizing
  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 4) { // Skipping headers
      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (colNumber === 1 || colNumber === 2) { // Auto size columns A and B only
          let currentWidth = worksheet.getColumn(colNumber).width;
          const cellValue = cell.value ? cell.value.toString() : "";
          const cellWidth = cellValue.length;
          if (cellWidth > currentWidth) {
            worksheet.getColumn(colNumber).width = cellWidth;
          }
        }
      });
    }
  });
  
    // Define the ending row for our data
  const lastDataRow = getLastDataRow(worksheet);

  // Apply thin borders to sections starting after headers (3 & 4)
  applyMediumBorders(worksheet, 5, lastDataRow, 3, 4); // Crew Production
  applyMediumBorders(worksheet, 5, lastDataRow, 5, 6); // Crew Labor
  applyMediumBorders(worksheet, 5, lastDataRow, 7, 8); // Variance
  applyMediumBorders(worksheet, 5, lastDataRow, 9, 10); // Cash +/-

  // Apply thick borders around the outside of each section
  applyMediumBorders(worksheet, 3, lastDataRow, 3, 4); // Crew Production
  applyMediumBorders(worksheet, 3, lastDataRow, 5, 6); // Crew Labor
  applyMediumBorders(worksheet, 3, lastDataRow, 7, 8); // Variance
  applyMediumBorders(worksheet, 3, lastDataRow, 9, 10); // Cash +/-
  
  // Apply two decimal places to all number values and align them to the right in the range C5 to J{end of data}
  for (let row = 5; row <= lastDataRow; row++) {
    for (let col = 3; col <= 10; col++) { // Columns C to J are indices 3 to 10
      const cell = worksheet.getCell(row, col);
      if (cell.value !== null && !isNaN(cell.value)) {
        const numericValue = parseFloat(cell.value);
        cell.value = numericValue.toFixed(2); // Force two decimal places
        cell.numFmt = '0.00'; // Set number format to include two decimal places
      }
      cell.alignment = { horizontal: 'right' };
    }
  }
  
  header.alignment = { horizontal: 'center', vertical: 'middle' };
  
    // Convert all string values to numbers in the range C5 to J{end of data}
  for (let row = 5; row <= lastDataRow; row++) {
    for (let col = 3; col <= 10; col++) { // Columns C to J are indices 3 to 10
      const cell = worksheet.getCell(row, col);
      if (cell.value !== null && !isNaN(cell.value)) {
        cell.value = Number(cell.value); // Convert the value to a Number
        cell.alignment = { horizontal: 'right' }; // Ensure it's right-aligned
      }
    }
  }
  
    // Set fill for cells from A1 to M1000 with a white background
  for (let rowNum = 1; rowNum <= 1000; rowNum++) {
    for (let colNum = 1; colNum <= 13; colNum++) { // Columns A to M are indices 1 to 13
      const cell = worksheet.getCell(rowNum, colNum);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF' } // White color
      };
    }
  }

// Hide all columns from J onwards, up to column XFD (the last possible column in Excel)
for (let colIndex = 12; colIndex <= 16384; colIndex++) {
  worksheet.getColumn(colIndex).hidden = true;
}

  
  
    // Define the range for the filters, A4 to J4
  worksheet.autoFilter = {
    from: {
      row: 4,
      column: 1
    },
    to: {
      row: 4,
      column: 10 // Column J is the 10th column
    }
  };
  
  
  
};

// Helper function to apply thin borders inside a section
const applyThinBorders = (worksheet, startRow, endRow, startCol, endCol) => {
  for (let row = startRow; row <= endRow; row++) {
    for (let col = startCol; col <= endCol; col++) {
      const cell = worksheet.getCell(row, col);
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    }
  }
};

// Helper function to apply thick borders around a section
const applyMediumBorders = (worksheet, startRow, endRow, startCol, endCol) => {
  // Top and bottom borders
  for (let col = startCol; col <= endCol; col++) {
    const topCell = worksheet.getCell(startRow, col);
    const bottomCell = worksheet.getCell(endRow, col);

    topCell.border = { ...topCell.border, top: { style: 'medium' } };
    bottomCell.border = { ...bottomCell.border, bottom: { style: 'medium' } };
  }
  // Left and right borders
  for (let row = startRow; row <= endRow; row++) {
    const leftCell = worksheet.getCell(row, startCol);
    const rightCell = worksheet.getCell(row, endCol);

    leftCell.border = { ...leftCell.border, left: { style: 'medium' } };
    rightCell.border = { ...rightCell.border, right: { style: 'medium' } };
  }
};

// Helper function to find the last row with data
const getLastDataRow = (worksheet) => {
  let lastRow = worksheet.lastRow.number;
  while (lastRow > 0) {
    const cellValue = worksheet.getCell(`B${lastRow}`).value;
    if (cellValue !== null && cellValue !== '') {
      break;
    }
    lastRow--;
  }
  return lastRow;
};

export { applyProfitabilityFormatting };

const applyServiceFormatting = (worksheet) => {
  // Merge and center C1 to J2, bold text size 14 for the main title
  worksheet.mergeCells('C1:H2');
  const titleCell = worksheet.getCell('C1');
  titleCell.value = 'Weekly Service Numbers';  // Placeholder value for the title
  titleCell.font = { size: 14, bold: true };
  titleCell.alignment = { horizontal: 'center', vertical: 'middle' };

  // Merge and center E3 to H3, bold and text size 11 for the subtitle
  worksheet.mergeCells('D3:G3');
  const subtitleCell = worksheet.getCell('D3');
  subtitleCell.value = 'Ave Times';  // Placeholder value for the subtitle
  subtitleCell.font = { size: 11, bold: true };
  subtitleCell.alignment = { horizontal: 'center', vertical: 'middle' };

  // Style headers in row 4 from A to J: bold, centered, and text size 11
  for (let i = 1; i <= 10; ++i) { // Columns A to J are indices 1 to 10
    const headerCell = worksheet.getCell(4, i); // 4th row, ith column
    headerCell.font = { bold: true, size: 11 };
    headerCell.alignment = { horizontal: 'center', vertical: 'middle' };
  }

  // Set the column widths as needed for the Service worksheet
  // ... (set column widths here if required)
  
// Set fixed column widths for columns A and B, and continue setting widths for C to H
worksheet.getColumn('A').width = 17; // Set width for column A to 17
worksheet.getColumn('B').width = 20; // Set width for column B to 20
for (let colNum = 3; colNum <= 8; colNum++) { // Set width for columns C to H to 15.43
  worksheet.getColumn(colNum).width = 15.43;
}

  // Apply thin borders around all of our data (starting at C5 to H{end of data})
  const lastRow = worksheet.lastRow.number;
  for (let rowNum = 5; rowNum <= lastRow; rowNum++) {
    for (let colNum = 3; colNum <= 8; colNum++) { // Columns C to H are indices 3 to 8
      const cell = worksheet.getCell(rowNum, colNum);
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };
    }
  }

// Format all number cells from columns C to H and convert column C values to numbers
worksheet.eachRow((row, rowNumber) => {
  if (rowNumber >= 5) { // Start after header rows
    row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
      // For column C, remove any "%" symbol, convert to number and align to the right
      if (colNumber === 3 && cell.value !== null) {
        // Ensure the value is a number (excludes any appended characters like '%' or text)
        let numericValue = parseFloat(cell.value);
        if (!isNaN(numericValue)) {
          cell.value = numericValue; // Set the value as a number
          cell.numFmt = '0.00%'; // Apply percentage formatting with two decimal places
        }
        cell.alignment = { horizontal: 'right' };
      }
      // Format and align other columns as numbers if needed
      else if (colNumber >= 4 && colNumber <= 8 && cell.value !== null) {
        if (!isNaN(cell.value)) {
          cell.value = parseFloat(cell.value); // Convert text to number
          cell.numFmt = '0.00'; // Apply number formatting if it's a number
          cell.alignment = { horizontal: 'right' };
        }
      }
      // Apply other formatting as needed for other columns
      // ...
    });
  }
});

  // Set fill for cells from A1 to M1000 with a white background
  for (let rowNum = 1; rowNum <= 1000; rowNum++) {
    for (let colNum = 1; colNum <= 13; colNum++) { // Columns A to M are indices 1 to 13
      const cell = worksheet.getCell(rowNum, colNum);
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF' } // White color
      };
    }
  }

// Hide all columns from J onwards, up to column XFD (the last possible column in Excel)
for (let colIndex = 10; colIndex <= 16384; colIndex++) {
  worksheet.getColumn(colIndex).hidden = true;
}

    // Define the range for the filters, A4 to J4
  worksheet.autoFilter = {
    from: {
      row: 4,
      column: 1
    },
    to: {
      row: 4,
      column: 8 // Column J is the 10th column
    }
  };

  // Apply other formatting as needed, such as number formats for cells
  // ... (other formatting options)

};

export { applyServiceFormatting };