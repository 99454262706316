import React, { useEffect } from 'react';
import ExcelJS from 'exceljs';
import { formatWorksheet } from './workbookFormatter';

function DownloadExcel({ data, kpiGoals, triggerDownload }) {
  // Function to format time in "minutes:seconds"
  const formatTime = (seconds) => {
    if (!seconds || isNaN(seconds)) return '';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Function to calculate complaints per 10,000 tickets
  const calculateComplaintsPerTenThousand = (totalComplaints, totalTickets) => {
    if (!totalComplaints || !totalTickets || isNaN(totalComplaints) || isNaN(totalTickets)) return '';
    const ratio = (totalComplaints / totalTickets) * 10000;
    return ratio.toFixed(2);
  };

  // Function to calculate crew production
  const calculateCrewProduction = (totalSales, totalHours) => {
    if (!totalSales || !totalHours || isNaN(totalSales) || isNaN(totalHours) || totalHours === 0) return '';
    return (totalSales / totalHours).toFixed(2);
  };

  // Map KPI names to the correct title formats
  const mapKpiName = (kpiName) => {
    switch (kpiName) {
      case 'CrewProduction':
        return 'Crew Production*';
      case 'OnTimesPercentage':
        return 'On Time %*';
      case 'FormattedAveTime':
        return 'Ave Time*';
      case 'FormattedReplyTime':
        return 'Reply Time*';
      case 'SalesChange':
        return 'Year over Year Sales';
      case 'ComplaintsPer10k':
        return 'Complaints/10,000*';
      case 'FormattedCash':
        return 'Cash +/-';
      case 'FoodVarianceMonth':
        return 'Ideal F&P*';
      case 'FSA':
        return 'Food Safety Audit*';
      default:
        return kpiName;
    }
  };

  // Extract thresholds for a specific KPI
  const getThresholdDescription = (goal) => {
    if (!goal) return { green: '', yellow: '', red: '' };

    let green, yellow, red;
    switch (goal.kpi_name) {
      case 'FSA': // Food Safety Audit
        green = `>${goal.green_threshold}%`;
        red = `<${goal.red_threshold}%`;
        yellow = `${goal.green_threshold}% - ${goal.red_threshold}%`;
        break;
      case 'SalesChange': // Sales Change
        green = `>${goal.green_threshold}%`;
        red = `<${goal.yellow_threshold}%`;
        yellow = `${goal.green_threshold}% - ${goal.yellow_threshold}%`;
        break;
      case 'FormattedReplyTime': // Reply Time
        green = `<${goal.green_threshold - 1} Sec`;
        red = `>${goal.red_threshold} Sec`;
        yellow = `${goal.green_threshold} Sec - ${goal.red_threshold - 1} Sec`;
        break;
      case 'FormattedAveTime': // Average Time
        green = `<${formatTime(goal.green_threshold)}`;
        red = `>${formatTime(goal.red_threshold)}`;
        yellow = `${formatTime(goal.green_threshold)} - ${formatTime(goal.red_threshold - 1)}`;
        break;
      case 'OnTimesPercentage': // On Time Percentage
        green = `>${goal.green_threshold}%`;
        red = `<${goal.red_threshold}%`;
        yellow = `${goal.green_threshold}% - ${goal.red_threshold}%`;
        break;
      case 'ComplaintsPer10k': // Complaints Per 10k
        green = `<${goal.green_threshold}%`;
        red = `>${goal.red_threshold}%`;
        yellow = `${goal.green_threshold}% - ${goal.red_threshold}%`;
        break;
      case 'FormattedCash': // Cash +/-
        const absGreenCash = Math.abs(goal.green_threshold);
        const absRedCash = Math.abs(goal.red_threshold);
        green = `>${absGreenCash}`;
        red = `<${absRedCash}`;
        yellow = `${absGreenCash} - ${absRedCash}`;
        break;
      case 'CrewProduction': // Crew Production
        green = `>${goal.green_threshold}`;
        red = `<${goal.red_threshold}`;
        yellow = `${goal.green_threshold} - ${goal.red_threshold}`;
        break;
      case 'FoodVarianceMonth': // Ideal F&P
        const absGreen = Math.abs(goal.green_threshold);
        const absRed = Math.abs(goal.red_threshold);
        green = `<${absGreen}%`;
        red = `>${absRed}%`;
        yellow = `${absGreen}% - ${absRed}%`;
        break;
      default:
        green = goal.green_threshold || '';
        yellow = goal.yellow_threshold || '';
        red = goal.red_threshold || '';
    }

    return { green, yellow, red };
  };

  // Function to generate and download an Excel file
  const downloadFile = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Results');

    // Prepare the KPI Standards layout
    const headerData = [
      ['KPI Standards'],           // A1
      [],                          // A2 (blank row)
    ];

    // Filter out "LaborPercentage" and "ContExpenses"
    const filteredKpiGoals = kpiGoals.filter((goal) => goal.kpi_name !== 'LaborPercentage' && goal.kpi_name !== 'ContExpenses');

    // Add each KPI goal in its respective row with thresholds in columns D, E, and F
    filteredKpiGoals.forEach((goal) => {
      const { green, yellow, red } = getThresholdDescription(goal);
      headerData.push([
        mapKpiName(goal.kpi_name),
        '', // Leave B empty
        '', // Leave C empty
        green, // Green Goal (D)
        yellow, // Yellow Goal (E)
        red // Red Goal (F)
      ]);
    });

    headerData.push([]); // Empty row before adding other headers

    // Prepare new headers for store data starting below the custom layout
    const storeHeader = [
      'Store-',
      'IFC Variance',
      'Crew Production',
      'Food Safety',
      'Reply Time',
      'Ave Time',
      'Complaints',
      'On Time %'
    ];

    headerData.push(storeHeader);

    // Insert store data starting from the row after the store header
    const storeRows = data.map((store, index) => {
      // Calculate IFC Variance
      const ifcVariance = store.idealFoodAndPaper && store.actualFnP
        ? (parseFloat(store.idealFoodAndPaper) - parseFloat(store.actualFnP)).toFixed(2)
        : '';

      // Format Reply Time and Ave Time to "minutes:seconds"
      const formattedReplyTime = formatTime(store.weightedReplyTime);
      const formattedAveTime = formatTime(store.weightedAveTime);

      // Calculate complaints per 10,000 tickets
      const complaintsPerTenThousand = calculateComplaintsPerTenThousand(
        store.totalComplaints,
        store.totalTickets
      );

      // Calculate crew production
      const crewProduction = calculateCrewProduction(store.totalSales, store.totalHours);

      return [
        store.storeSelection || `Store ${index + 1}`, // Store identifier
        ifcVariance,                                 // IFC Variance
        crewProduction,                              // Crew Production
        store.foodSafety || '',                      // Food Safety
        formattedReplyTime,                          // Reply Time
        formattedAveTime,                            // Ave Time
        complaintsPerTenThousand,                    // Complaints
        store.onTimePercentage ? (store.onTimePercentage * 100).toFixed(2) + '%' : '' // On Time %
      ];
    });

    // Append rows to the worksheet
    worksheet.addRows(headerData);
    worksheet.addRows(storeRows);

    // Set the value directly for cell 'G3'
    worksheet.getCell('G3').value = '* Denotes Earned Schedule KPI';

    // Apply additional formatting using the external function
    formatWorksheet(worksheet, kpiGoals);

    const fileName = 'DataReport.xlsx';
    console.log('Whole worksheet before writing:', worksheet);

    // Write the workbook to a buffer and create a download link
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName;
    anchor.click();
    window.URL.revokeObjectURL(url);
  };

  // Listen for triggerDownload changes to invoke download
  useEffect(() => {
    if (triggerDownload && data) {
      downloadFile();
    }
  }, [triggerDownload, data]);

  return null; // Does not render any content
}

export default DownloadExcel;
