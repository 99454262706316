import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Box, Button, Slide, useScrollTrigger } from '@mui/material';
import { styled } from '@mui/system';

const StyledButton = styled(Button)(({ theme }) => ({
  color: '#333',
  fontWeight: 'bold',
  textTransform: 'none',
  fontSize: '16px',
  marginRight: '20px',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  '&:hover': {
    color: '#007bff',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    marginRight: '10px',
  },
}));

const LogoImage = styled('img')(({ theme }) => ({
  maxHeight: '50px',
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    maxHeight: '30px',
  },
}));

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function NavBar() {
  const navigate = useNavigate();
  const location = useLocation(); // To determine current route

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const isBlogPage = location.pathname === '/blog'; // Check if the current page is the blog

  return (
    <HideOnScroll>
      <AppBar position="fixed" sx={{ background: '#F4F4F4', boxShadow: 'none' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <LogoImage 
              src="https://prolifi.team/images/pApp/Logo.png" 
              alt="Company Logo"
              onClick={() => navigate(isBlogPage ? '/business' : '/')} // Navigate to business page if on blog
            />
          </Box>
          {!isBlogPage && (
            <>
              <StyledButton onClick={() => scrollTo('services')}>Services</StyledButton>
              <StyledButton onClick={() => scrollTo('about')}>About</StyledButton>
            </>
          )}
          {isBlogPage ? (
            <StyledButton onClick={() => navigate('/business')}>Home</StyledButton>
          ) : (
            <StyledButton onClick={() => navigate('/blog')}>Blog</StyledButton>
          )}
          <StyledButton onClick={() => navigate('/')}>ProlifiApp</StyledButton>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}

export default NavBar;