import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Modal, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import SignaturePad from 'react-signature-canvas';
import CorrectiveActionData from './CorrectiveActionData';
import styles from './CorrectionActionForm.module.css';

const reverseString = (str) => str.split('').reverse().join('');

const CorrectionActionForm = () => {
  const initialFormData = {
    employeeName: '',
    jobTitle: '',
    warningType: '',
    offenseType: '',
    description: '',
    improvementPlan: '',
    consequences: '',
    managerSignature: '',
    witnessSignature: '',
    witnessName: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [employeeNames, setEmployeeNames] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showWitnessFields, setShowWitnessFields] = useState(false);
  const [employeeRefused, setEmployeeRefused] = useState(false);
  const [showSecurityModal, setShowSecurityModal] = useState(true);
  const [securityCode, setSecurityCode] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [correctiveActionsPermissions, setCorrectiveActionsPermissions] = useState('000000');
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [storeID, setStoreID] = useState(''); // State variable to store storeID

  const managerSignaturePad = useRef(null);
  const employeeSignaturePad = useRef(null);
  const witnessSignaturePad = useRef(null);

  const fetchEmployeeNames = async (storeID) => {
    const baseUrl = getBaseUrl();
    try {
      const response = await axios.get(`${baseUrl}/api/employee-names`, {
        params: { storeID },
        withCredentials: true
      });
      setEmployeeNames(response.data);
    } catch (error) {
      console.error('Error fetching employee names:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'offenseType' && value === 'Cash Shortage') {
      setFormData({
        ...formData,
        [name]: value,
        description: 'Employee was short $'
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const getBaseUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === '72.167.34.236') {
      return 'http://72.167.34.236:5000';
    } else if (/^(www\.)?prolifi\.app$/.test(hostname)) {
      return 'https://prolifi.app';
    } else {
      return 'http://localhost:5000'; // For local development
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.warningType) {
      alert('Please select a Type of Warning.');
      return;
    }

    if (!managerSignaturePad.current || managerSignaturePad.current.isEmpty()) {
      alert('Manager signature is required');
      return;
    }

    if (!employeeRefused && (!employeeSignaturePad.current || employeeSignaturePad.current.isEmpty())) {
      setShowModal(true);
      return;
    }

    handleSecuritySubmit();
  };

  const handleSecuritySubmit = async () => {
    const managerSignature = managerSignaturePad.current.toDataURL();
    const employeeSignature = employeeRefused ? '' : employeeSignaturePad.current.toDataURL();
    const witnessSignature = witnessSignaturePad.current ? witnessSignaturePad.current.toDataURL() : '';

    const formDataWithSignatures = {
      ...formData,
      managerSignature,
      employeeSignature,
      witnessSignature,
      securityCode
    };

    const baseUrl = getBaseUrl();

    try {
      const response = await axios.post(`${baseUrl}/api/correction-action`, formDataWithSignatures, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'CorrectionActionForm.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      alert('Data submitted successfully');
      resetFormAfterSubmission();
    } catch (error) {
      console.error('Error submitting data:', error);
      setErrorMessage('Error submitting data');
    }
  };

  const handleModalClose = () => setShowModal(false);

  const handleRefusedToSign = () => {
    setEmployeeRefused(true);
    setShowWitnessFields(true);
    setShowModal(false);
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setEmployeeRefused(false);
    setShowWitnessFields(false);
    setSecurityCode('');
    setIsAuthenticated(false);
    if (timeoutId) clearTimeout(timeoutId);
    if (managerSignaturePad.current) managerSignaturePad.current.clear();
    if (employeeSignaturePad.current) employeeSignaturePad.current.clear();
    if (witnessSignaturePad.current) witnessSignaturePad.current.clear();
  };

  const resetFormAfterSubmission = () => {
    fetchEmployeeNames(storeID); // Use storeID here
    setFormData(initialFormData);
    setEmployeeRefused(false);
    setShowWitnessFields(false);
    if (managerSignaturePad.current) managerSignaturePad.current.clear();
    if (employeeSignaturePad.current) employeeSignaturePad.current.clear();
    if (witnessSignaturePad.current) witnessSignaturePad.current.clear();
  };

  const handleTimeout = () => {
    resetForm();
    setShowTimeoutModal(true);
  };

  const handleInitialSecuritySubmit = async () => {
    const baseUrl = getBaseUrl();

    try {
      const response = await axios.post(`${baseUrl}/api/store/verify-security-code`, { securityCode });

      if (response.data.success) {
        const storeID = response.data.userData.StoreID; // Extract the storeID from the response
        setStoreID(storeID); // Set storeID in state
        setFormData({ ...formData, ...response.data.userData });
        const permissions = response.data.correctiveActionsPermissions.toString(2).padEnd(6, '0');
        const reversedPermissions = reverseString(permissions);
        setCorrectiveActionsPermissions(reversedPermissions);

        if (reversedPermissions[1] !== '1') {
          setPermissionDenied(true);
          setIsAuthenticated(false);
          setShowSecurityModal(false);
        } else {
          setIsAuthenticated(true);
          setShowSecurityModal(false);
          // Fetch employee names after successful authentication
          fetchEmployeeNames(storeID);
          // Set a timeout to reset the form after 5 minutes (300000 ms)
          const id = setTimeout(handleTimeout, 900000);
          setTimeoutId(id);
        }
      } else {
        setErrorMessage('Invalid security code');
      }
    } catch (error) {
      console.error('Error verifying security code:', error);
      setErrorMessage('Error verifying security code');
    }
  };

  useEffect(() => {
    setShowSecurityModal(true);
  }, []);

  const handleTimeoutModalClose = () => {
    setShowTimeoutModal(false);
    setShowSecurityModal(true);
  };

  return (
    <Container className={styles.formContainer}>
      {permissionDenied && (
        <Alert variant="danger" onClose={() => setPermissionDenied(false)} dismissible>
          You do not have the necessary permissions to access the Corrective Actions System.
        </Alert>
      )}

      {isAuthenticated && (
        <Row>
          <Col md={8}>
            <Form onSubmit={handleSubmit} className="p-4 shadow-sm rounded bg-light">
              <h3 className="mb-4">Correction Action Form</h3>
              <Row className={styles.inlineFields}>
                <Col md={6}>
                  <Form.Group controlId="employeeName" className={styles.formGroup}>
                    <Form.Label>Employee Name</Form.Label>
                    <Typeahead
                      id="employeeName"
                      onChange={(selected) => {
                        if (selected.length > 0) {
                          setFormData({ ...formData, employeeName: selected[0] });
                        }
                      }}
                      options={employeeNames}
                      selected={formData.employeeName ? [formData.employeeName] : []}
                      placeholder="Start typing an employee's name..."
                      onInputChange={(text) => setFormData({ ...formData, employeeName: text })}
                      minLength={1}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="jobTitle" className={styles.formGroup}>
                    <Form.Label>Job Title</Form.Label>
                    <Form.Control as="select" name="jobTitle" value={formData.jobTitle} onChange={handleChange} required>
                      <option value="">Select a job title</option>
                      {(correctiveActionsPermissions[1] === '1' || correctiveActionsPermissions[2] === '1' || correctiveActionsPermissions[3] === '1' || correctiveActionsPermissions[4] === '1') && (
                        <>
                          {correctiveActionsPermissions[1] === '1' && (
                            <>
                              <option value="Crew">Crew</option>
                              <option value="Crew Leader">Crew Leader</option>
                            </>
                          )}
                          {correctiveActionsPermissions[2] === '1' && <option value="Assistant Manager">Assistant Manager</option>}
                          {correctiveActionsPermissions[3] === '1' && <option value="MIT">MIT</option>}
                          {correctiveActionsPermissions[4] === '1' && <option value="General Manager">General Manager</option>}
                        </>
                      )}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="offenseType" className={styles.formGroup}>
                <Form.Label>Type of Offense</Form.Label>
                <Form.Control as="select" name="offenseType" value={formData.offenseType} onChange={handleChange} required>
                  <option value="">Select an offense</option>
                  <option value="Attendance">Attendance</option>
                  <option value="Substandard Work">Substandard Work</option>
                  <option value="Rudeness">Rudeness</option>
                  <option value="Violation of Company Policies">Violation of Company Policies</option>
                  <option value="Violation of Safety Rules">Violation of Safety Rules</option>
                  <option value="Cash Shortage">Cash Shortage</option>
                  <option value="Correct Uniform Warning">Correct Uniform Warning</option>
                  <option value="Other">Other</option>
                </Form.Control>
              </Form.Group>
              {formData.offenseType && (
                <Form.Group className={styles.formGroup}>
                  <Form.Label style={{ display: 'block', textAlign: 'center' }}>Type of Warning</Form.Label>
                  <div className={styles.warningGroup}>
                    <input type="radio" id="documentation" name="warningType" value="Documentation" className={styles.customRadio} onChange={handleChange} required />
                    <label htmlFor="documentation">Documentation</label>

                    <input type="radio" id="firstWarning" name="warningType" value="First" className={styles.customRadio} onChange={handleChange} />
                    <label htmlFor="firstWarning">First</label>

                    <input type="radio" id="secondWarning" name="warningType" value="Second" className={styles.customRadio} onChange={handleChange} />
                    <label htmlFor="secondWarning">Second</label>

                    <input type="radio" id="finalWarning" name="warningType" value="Final" className={styles.customRadio} onChange={handleChange} />
                    <label htmlFor="finalWarning">Final</label>

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        correctiveActionsPermissions[5] !== '1' ? (
                          <Tooltip id="tooltip-termination">
                            You do not have access to submit termination
                          </Tooltip>
                        ) : <span></span>
                      }
                    >
                      <div className={correctiveActionsPermissions[5] !== '1' ? styles.disabledRadioWrapper : ''}>
                        <input
                          type="radio"
                          id="termination"
                          name="warningType"
                          value="Termination"
                          className={styles.customRadio}
                          onChange={handleChange}
                          disabled={correctiveActionsPermissions[5] !== '1'}
                        />
                        <label htmlFor="termination">Termination</label>
                      </div>
                    </OverlayTrigger>
                  </div>
                </Form.Group>
              )}
              {formData.warningType && (
                <>
                  <Form.Group controlId="description" className={styles.formGroup}>
                    <Form.Label>Description of Infraction</Form.Label>
                    <Form.Control as="textarea" name="description" value={formData.description} onChange={handleChange} rows={3} required />
                  </Form.Group>
                  <Form.Group controlId="improvementPlan" className={styles.formGroup}>
                    <Form.Label>Plan for Improvement</Form.Label>
                    <Form.Control as="textarea" name="improvementPlan" value={formData.improvementPlan} onChange={handleChange} rows={3} required />
                  </Form.Group>
                  <Form.Group controlId="consequences" className={styles.formGroup}>
                    <Form.Label>Consequences for Further Infraction</Form.Label>
                    <Form.Control as="textarea" name="consequences" value={formData.consequences} onChange={handleChange} rows={3} required />
                  </Form.Group>
                  <Form.Group controlId="managerSignature" className={styles.signatureContainer}>
                    <div style={{ position: 'relative' }}>
                      <SignaturePad
                        ref={managerSignaturePad}
                        canvasProps={{
                          className: styles.signaturePad,
                          style: {
                            border: '2px solid #ccc', // Light gray border
                            borderRadius: '4px',
                            width: '100%',
                            maxWidth: '550px',
                            height: '200px',
                            backgroundColor: '#fff',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          }
                        }}
                      />
                      <Form.Label className={styles.signatureLabel} style={{ position: 'absolute', bottom: '2px', left: '4px', backgroundColor: '#ffffff00', padding: '0 1px' }}>Manager Signature</Form.Label>
                    </div>
                  </Form.Group>
                  {!employeeRefused && (
                    <Form.Group controlId="employeeSignature" className={styles.signatureContainer}>
                      <div style={{ position: 'relative' }}>
                        <SignaturePad
                          ref={employeeSignaturePad}
                          canvasProps={{
                            className: styles.signaturePad,
                            style: {
                              border: '2px solid #ccc', // Light gray border
                              borderRadius: '4px',
                              width: '100%',
                              maxWidth: '550px',
                              height: '200px',
                              backgroundColor: '#fff',
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }
                          }}
                        />
                        <Form.Label className={styles.signatureLabel} style={{ position: 'absolute', bottom: '2px', left: '4px', backgroundColor: '#ffffff00', padding: '0 1px' }}>Employee Signature</Form.Label>
                      </div>
                    </Form.Group>
                  )}
                </>
              )}
              {showWitnessFields && (
                <>
                  <Form.Group controlId="witnessSignature" className={styles.signatureContainer}>
                    <div style={{ position: 'relative' }}>
                      <SignaturePad
                        ref={witnessSignaturePad}
                        canvasProps={{
                          className: styles.signaturePad,
                          style: {
                            border: '2px solid #ccc', // Light gray border
                            borderRadius: '4px',
                            width: '100%',
                            maxWidth: '550px',
                            height: '200px',
                            backgroundColor: '#fff',
                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          }
                        }}
                      />
                      <Form.Label className={styles.signatureLabel} style={{ position: 'absolute', bottom: '2px', left: '4px', backgroundColor: '#ffffff00', padding: '0 1px' }}>Witness Signature</Form.Label>
                    </div>
                  </Form.Group>
                  <Form.Group controlId="witnessName" className={styles.formGroup}>
                    <Form.Label>Witness Name</Form.Label>
                    <Form.Control type="text" name="witnessName" value={formData.witnessName} onChange={handleChange} required />
                  </Form.Group>
                </>
              )}
              <Button variant="primary" type="submit">Submit</Button>
              <Button variant="secondary" onClick={resetForm} className="ml-2">Reset</Button>
            </Form>
          </Col>
          <Col md={4}>
            {/* Display CorrectiveActionData component if employeeName, jobTitle, and offenseType are selected */}
            {formData.employeeName && formData.jobTitle && formData.offenseType && (
              <div className="p-4 shadow-sm rounded bg-light">
                <CorrectiveActionData 
                  employeeName={formData.employeeName} 
                  jobTitle={formData.jobTitle} 
                  offenseType={formData.offenseType} 
                  submitterStoreID={storeID} // Pass the storeID to the component
                />
              </div>
            )}
          </Col>
        </Row>
      )}

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Employee Signature Missing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Did the employee refuse to sign?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>No</Button>
          <Button variant="primary" onClick={handleRefusedToSign}>Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSecurityModal} onHide={() => setShowSecurityModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Security Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && (
            <Alert variant="danger" onClose={() => setErrorMessage('')} dismissible>
              {errorMessage}
            </Alert>
          )}
          <Form.Group controlId="securityCode" className={styles.formGroup}>
            <Form.Label>Security Code</Form.Label>
            <Form.Control
              type="text"
              name="securityCode"
              value={securityCode}
              onChange={(e) => setSecurityCode(e.target.value)}
              required
              pattern="\d{4}"
              maxLength="4"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSecurityModal(false)}>Cancel</Button>
          <Button variant="primary" onClick={handleInitialSecuritySubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showTimeoutModal} onHide={handleTimeoutModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Authentication Timed Out</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your session has timed out. Please re-enter your security code.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleTimeoutModalClose}>OK</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default CorrectionActionForm;
