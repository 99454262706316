// MonthlyProcessing.js
import React, { useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns'; // Import the format function
import styles from './MonthlyProcessing.module.css'; // Import CSS module

function CustomInput({ value, onClick }) {
  // Function to format date from "MM/yyyy" to "Month Year"
  const formatDateDisplay = (value) => {
    // Parse the value into a Date object if it's a valid date string
    const dateParts = value.split('/');
    if (dateParts.length === 2) {
      const [month, year] = dateParts;
      const date = new Date(year, month - 1);
      return format(date, "MMMM yyyy"); // Format the date to "Month Year"
    }
    return value;
  };

  return (
    <button className={styles.customDatePickerInput} onClick={onClick}>
      {formatDateDisplay(value)}
    </button>
  );
}

function MonthlyProcessing() {
  const [selectedDate, setSelectedDate] = useState(new Date());

const handleMonthlyProcessing = async () => {
    const baseUrl = window.location.hostname === '72.167.34.236'
      ? 'http://72.167.34.236:5000'
      : 'https://prolifi.app';
      
    const month = selectedDate.getMonth() + 1; // JavaScript months are 0-indexed
    const year = selectedDate.getFullYear();

    const monthlyProcessingUrl = `${baseUrl}/api/monthly/monthly-processing`;

    try {
        const response = await axios.post(monthlyProcessingUrl, { month, year }, { withCredentials: true });
		
		if (response.data.success && response.data.excelFilename) {
			const downloadUrl = `${baseUrl}/api/monthly/download/${response.data.excelFilename}`;
			window.location.href = downloadUrl; // This should trigger the browser to download the file		
		} else {
			alert('Monthly processing completed, but no file was generated.');
		}

    } catch (error) {
        console.error('Error during Monthly Processing:', error);
        alert('Error during Monthly Processing. Check the console for more details.');
    }
};




  return (
    <div className={styles.container}>
      <div className={styles.datePickerWrapper}>
        <label htmlFor="monthPicker" className={styles.label}>Select Month and Year: </label>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          showFullMonthYearPicker
          customInput={<CustomInput />} // Use the updated custom input component
        />
      </div>
      <button onClick={handleMonthlyProcessing} className={styles.button}>Run Monthly Processing</button>
    </div>
  );
}

export default MonthlyProcessing;
