import ExcelJS from 'exceljs';
import gradeKPI from './GradeKPIs';

export const formatExcelReport = (worksheet, reportData, selectedMonth, selectedArea, gradingStandards, areaAveragesState) => {
  // Temporary modification for testing purposes
  const modifiedAreaAveragesState = areaAveragesState.map(area => 
    area.area === 'notINuse' ? { ...area, averageSalesChange: '8.55%' } : area
  );

  // Add headers

  worksheet.mergeCells('A2:M2');
  worksheet.getCell('A2').value = `${selectedMonth}`;
  worksheet.getCell('A2').font = { size: 14, bold: true };
  worksheet.getCell('A2').alignment = { horizontal: 'center' };
  worksheet.getCell('A2').fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFFFFFFF' } // White color
};

  // Define columns and set headers in row 3
  worksheet.columns = [
    { header: 'Rank', key: 'rank', width: 5 },
    { header: 'Store Name', key: 'storeName', width: 15 },
    { header: 'MTD Sales', key: 'MtdSales', width: 15 },
    { header: 'Sales % Change', key: 'SalesChange', width: 15 },
    { header: 'Controllables', key: 'ContExpenses', width: 15 },
    { header: 'Production', key: 'CrewProduction', width: 12 },
    { header: 'Labor %', key: 'LaborPercentage', width: 10 },
    { header: 'Variance', key: 'FoodVarianceMonth', width: 10 },
    { header: 'On Times', key: 'OnTimesPercentage', width: 10 },
    { header: 'Ave. Time', key: 'FormattedAveTime', width: 12 },
    { header: 'Reply Time', key: 'FormattedReplyTime', width: 12 },
    { header: 'Sync', key: 'ComplaintsPer10k', width: 10 },
    { header: 'Cash', key: 'FormattedCash', width: 15 },
  ];

  // Manually add the headers to row 3
  const headerRow = worksheet.getRow(3);
  worksheet.columns.forEach((col, index) => {
    const cell = headerRow.getCell(index + 1);
    cell.value = col.header;
    cell.font = { bold: true };
    cell.alignment = { horizontal: 'center' };
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
  });
  headerRow.commit();

  worksheet.mergeCells('A1:M1');
  worksheet.getCell('A1').value = `${selectedArea} Pops Report`;
  worksheet.getCell('A1').font = { size: 16, bold: true };
  worksheet.getCell('A1').alignment = { horizontal: 'center' };
  worksheet.getCell('A1').fill = {
  type: 'pattern',
  pattern: 'solid',
  fgColor: { argb: 'FFFFFFFF' } // White color
};
  
  // Add data rows starting from row 4
  reportData.forEach((row, rowIndex) => {
    const dataRow = worksheet.addRow({
      rank: row.rank,
      storeName: row.storeName,
      MtdSales: row.MtdSales,
      SalesChange: row.usedAreaAverage ? '-' : row.SalesChange,
      ContExpenses: row.ContExpenses,
      CrewProduction: row.CrewProduction,
      LaborPercentage: row.LaborPercentage,
      FoodVarianceMonth: row.FoodVarianceMonth,
      OnTimesPercentage: row.OnTimesPercentage,
      FormattedAveTime: row.FormattedAveTime,
      FormattedReplyTime: row.FormattedReplyTime,
      ComplaintsPer10k: row.ComplaintsPer10k,
      FormattedCash: row.FormattedCash,
    });

    // Apply background color based on grading
    const kpiValues = [
      { key: 'SalesChange', value: row.SalesChange },
      { key: 'ContExpenses', value: row.ContExpenses },
      { key: 'CrewProduction', value: row.CrewProduction },
      { key: 'LaborPercentage', value: row.LaborPercentage },
      { key: 'FoodVarianceMonth', value: row.FoodVarianceMonth },
      { key: 'OnTimesPercentage', value: row.OnTimesPercentage },
      { key: 'FormattedAveTime', value: row.FormattedAveTime },
      { key: 'FormattedReplyTime', value: row.FormattedReplyTime },
      { key: 'ComplaintsPer10k', value: row.ComplaintsPer10k },
      { key: 'FormattedCash', value: row.FormattedCash },
    ];

    kpiValues.forEach(({ key, value }, index) => {
      if (key === 'SalesChange' && row.usedAreaAverage) {
        const storeArea = row.storeArea;
        const areaAverage = modifiedAreaAveragesState.find(area => area.area === storeArea);
        if (areaAverage) {
          value = areaAverage.averageSalesChange;
        }
      }

      const kpiResult = gradeKPI[key](value, gradingStandards);
      const cell = dataRow.getCell(index + 4); // Adjust the index based on column positions
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: kpiResult.color === 'green' ? 'FF00FF00' : kpiResult.color === 'red' ? 'FFFF0000' : 'FFFFFF00' }
      };
    });

    // Adjust values and apply formatting after grading
    dataRow.getCell('MtdSales').value = parseFloat(row.MtdSales?.replace(/[$,]/g, ''));
    dataRow.getCell('MtdSales').numFmt = '$#,##0.00';

    if (!row.usedAreaAverage) {
      dataRow.getCell('SalesChange').value = parseFloat(row.SalesChange?.replace('%', '')) / 100;
      dataRow.getCell('SalesChange').numFmt = '0.00%';
    } else {
      dataRow.getCell('SalesChange').value = '-';
    }

    dataRow.getCell('ContExpenses').value = parseFloat(row.ContExpenses?.replace('%', '')) / 100;
    dataRow.getCell('ContExpenses').numFmt = '0.00%';

    dataRow.getCell('CrewProduction').value = parseFloat(row.CrewProduction?.replace(/[$,]/g, ''));
    dataRow.getCell('CrewProduction').numFmt = '$#,##0.00';

    dataRow.getCell('LaborPercentage').value = parseFloat(row.LaborPercentage?.replace('%', '')) / 100;
    dataRow.getCell('LaborPercentage').numFmt = '0.00%';

    dataRow.getCell('FoodVarianceMonth').value = parseFloat(row.FoodVarianceMonth);
    dataRow.getCell('FoodVarianceMonth').numFmt = '0.00';

    dataRow.getCell('OnTimesPercentage').value = parseFloat(row.OnTimesPercentage?.replace('%', '')) / 100;
    dataRow.getCell('OnTimesPercentage').numFmt = '0.00%';

    dataRow.getCell('ComplaintsPer10k').value = parseFloat(row.ComplaintsPer10k);
    dataRow.getCell('ComplaintsPer10k').numFmt = '0.00';

    dataRow.getCell('FormattedCash').value = parseFloat(row.FormattedCash?.replace(/[\($,\)]/g, '')) * (row.FormattedCash?.startsWith('(') ? -1 : 1);
    dataRow.getCell('FormattedCash').numFmt = '[$$-409]#,##0.00;[$$-409](#,##0.00)';

    // Align cells
    dataRow.getCell('storeName').alignment = { horizontal: 'right' };

    ['rank', 'MtdSales', 'SalesChange', 'ContExpenses', 'CrewProduction', 'LaborPercentage', 'FoodVarianceMonth', 'OnTimesPercentage', 'FormattedAveTime', 'FormattedReplyTime', 'ComplaintsPer10k', 'FormattedCash'].forEach(key => {
      dataRow.getCell(key).alignment = { horizontal: 'center' };
    });

    // Apply thin border to each cell in the row
    dataRow.eachCell(cell => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
  });

  // Apply medium border to the entire report
  const lastRow = worksheet.lastRow.number;

  for (let rowNum = 1; rowNum <= lastRow; rowNum++) {
    const row = worksheet.getRow(rowNum);
    if (rowNum === 1 || rowNum === lastRow) {
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          ...cell.border,
          top: rowNum === 1 ? { style: 'medium' } : undefined,
          bottom: rowNum === lastRow ? { style: 'medium' } : undefined,
        };
      });
    }
    row.getCell(1).border = {
      ...row.getCell(1).border,
      left: { style: 'medium' },
    };
    row.getCell(worksheet.columns.length).border = {
      ...row.getCell(worksheet.columns.length).border,
      right: { style: 'medium' },
    };
  }
};
