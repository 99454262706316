  // SalesDataChecker.js
import React, { useEffect, useState } from 'react';
import { format, startOfISOWeek, endOfISOWeek, getISODay } from 'date-fns';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, Box, Tooltip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import styles from './SalesDataChecker.module.css';
import { Divider } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';


const SalesDataChecker = () => {
    const [dateRange, setDateRange] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [supervisedStores, setSupervisedStores] = useState([]);
    const [storeSalesData, setStoreSalesData] = useState({});
    const [expandedStores, setExpandedStores] = useState(new Set());

    useEffect(() => {
        let today = new Date();
		console.log(today);
        // Adjusting the logic to account for showing the previous ISO week if today is Monday
        if (getISODay(today) === 1) {
            // Calculate the start of the previous ISO week
            today.setDate(today.getDate() - 7);
        }
        
        let start = startOfISOWeek(today);
        let end = endOfISOWeek(today);

        const formattedRange = `${format(start, 'MMM dd')} - ${format(end, 'MMM dd, yyyy')}`;
        const formattedStart = format(start, 'yyyy-MM-dd');
        const formattedEnd = format(end, 'yyyy-MM-dd');

        setDateRange(formattedRange);
        setStartDate(formattedStart);
        setEndDate(formattedEnd);

        fetchSupervisedStores();
    }, []);
	
	    useEffect(() => {
        // Ensure both startDate and endDate are set before fetching sales data
        if (startDate && endDate && supervisedStores.length > 0) {
            fetchSalesData(supervisedStores);
        }
    }, [startDate, endDate, supervisedStores]); 

    const getBaseUrl = () => {
        return process.env.NODE_ENV === 'development'
            ? 'http://72.167.34.236:5000'
            : 'https://prolifi.app';
    };

    const fetchSupervisedStores = async () => {
        try {
            const baseUrl = getBaseUrl();
            const response = await axios.get(`${baseUrl}/api/supervised-stores`, {
                withCredentials: true
            });
            setSupervisedStores(response.data);
            //fetchSalesData(response.data);
        } catch (error) {
            //console.error('Error fetching supervised stores:', error);
        }
    };

const fetchSalesData = async (stores) => {
  const baseUrl = getBaseUrl();
  const data = {};

  for (let store of stores) {
    try {
      const url = `${baseUrl}/api/sales-data/${store}/${startDate}/${endDate}`;
      const response = await axios.get(url, {
        withCredentials: true
      });

      // Check if the response data contains valid sales data or a specific error message
      if (response.data && 
          response.data.dailyData && 
          response.data.dailyData.length > 0 && 
          !response.data.error) {
        data[store] = response.data;
      }
    } catch (error) {
      // Errors are logged and stores with errors are not added.
      console.error(`Error fetching data for store ${store}:`, error);
    }
  }

  setStoreSalesData(data);
  console.log(data);
};


    const toggleStore = (storeId) => {
        const newSet = new Set(expandedStores);
        if (newSet.has(storeId)) {
            newSet.delete(storeId);
        } else {
            newSet.add(storeId);
        }
        setExpandedStores(newSet);
    };
	
	  // Helper function to get an array of dates (in 'yyyy-MM-dd' format) between two dates
const getDatesInRange = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const dates = [];
  for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
    dates.push(date.toISOString().split('T')[0]);
  }

  //console.log(dates);
  return dates;
};

const format = (date) => {
  return date.toISOString().split('T')[0];
};

const findMissingDates = (dailyData, startDate, endDate) => {
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0); // Set today's date to the start of the day
  //console.log('Today\'s Date (Start of Day):', format(todayDate));
 

  // Adjust endDate if it's today or in the future
  let adjustedEndDate = new Date(endDate);
  if (adjustedEndDate > todayDate) {
    adjustedEndDate = new Date(todayDate);
    adjustedEndDate.setDate(adjustedEndDate.getDate() - 1); // Set to the day before today
  }
  //console.log('Adjusted End Date:', format(adjustedEndDate));

  const allDates = getDatesInRange(startDate, format(adjustedEndDate)).filter(date => {
    // Convert the string date to a Date object and set the time to the start of the day for comparison
    const dateToCompare = new Date(date);
    dateToCompare.setHours(0, 0, 0, 0);

    // Include only past dates, strictly excluding today (even at the start of the day) and future dates
    return dateToCompare < todayDate;
  });

  //console.log('All Dates in Range (Excluding Today and Future):', allDates);

  const storeDates = dailyData.map(data => format(new Date(data.date)));
  //console.log('Store Dates from Daily Data:', storeDates);

  const missingDates = allDates.filter(date => !storeDates.includes(date));
  //console.log('Missing Dates:', missingDates);

  return missingDates;
};

// Helper function to format the date range or single date
const formatRange = (start, end) => {
  const formatStr = 'MMM d';
  if (start.toDateString() === end.toDateString()) {
    // Single date
    return format(start, formatStr);
  } else {
    // Date range
    return `${format(start, formatStr)}-${format(end, formatStr)}`;
  }
};

// Function to group consecutive dates into ranges
const groupConsecutiveDates = (dates) => {
  if (!dates.length) return [];

  // Make sure dates are sorted
  const sortedDates = dates.sort((a, b) => new Date(a) - new Date(b));

  let ranges = [];
  let rangeStart = new Date(sortedDates[0]);
  let rangeEnd = new Date(sortedDates[0]);

  for (let i = 1; i < sortedDates.length; i++) {
    const currentDate = new Date(sortedDates[i]);
    const nextDay = new Date(rangeEnd);
    nextDay.setDate(nextDay.getDate() + 1);

    if (+nextDay === +currentDate) {
      rangeEnd = currentDate; // Extend the range
    } else {
      // Push the current range or single date, then reset the range
      ranges.push(formatRange(rangeStart, rangeEnd));
      rangeStart = currentDate;
      rangeEnd = currentDate;
    }
  }
  
  // Push the last range or single date
  ranges.push(formatRange(rangeStart, rangeEnd));
  
  return ranges;
};

const formatSecondsToMinSec = (seconds) => {
  if (isNaN(seconds) || seconds == null) {
    return 'N/A';
  }
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
};

return (
  <Box className={styles.rootBox}>
		<Box className={styles.titleBox}>
		  <Typography variant="h4" className={styles.mainTitle}>ProlifiData</Typography>
		  <Typography variant="subtitle1" className={styles.secondaryTitle}>{dateRange}</Typography>
		</Box>
    {supervisedStores.length > 0 ? supervisedStores.map(storeId => {
        const storeData = storeSalesData[storeId] ?? { dailyData: [] };
		
				  if (storeData.error) {
			return (
			  <div key={storeId} className={styles.error}>
			  </div>
			);
		  }
        
             const missingDates = findMissingDates(storeData.dailyData, startDate, endDate);
			 const missingDateRanges = groupConsecutiveDates(missingDates.map(date => new Date(date)));
		

		
      if (!storeData) {
        return <div key={storeId}>Loading data for store #{storeId}...</div>;
      }
	  
	         // Define aggregatedData here from storeData
        const aggregatedData = storeData.aggregatedData ?? {};

      // Ensure that aggregatedData contains numerical values
      const aggregateSales = Number(aggregatedData.totalNetsales) || 0;
      const aggregateOntime = Number((aggregatedData.totOntime/aggregatedData.totalTickets) * 100) || 0;
      const aggregateTickets = Number(aggregatedData.totalTickets) || 0;



      return (
        <Accordion key={storeId} expanded={expandedStores.has(storeId)} onChange={() => toggleStore(storeId)}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`panel-header-${storeId}`} aria-controls={`panel-content-${storeId}`}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              <Box>
                <Typography variant="subtitle1" className={styles.storeName} style={{ fontWeight: 'bold', textAlign: 'left' }}>Store #{storeId}</Typography>
                {/* Now we check if the values are numbers before calling toFixed */}
				<Box className={styles.aggregatedDataContainer}>
				  <TrendingUpIcon className={styles.aggregateIcon} />
				  <Typography variant="body2" className={styles.aggregatedDataItem}>
					<span className="boldLabel">Net Sales:</span> 
					${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(aggregateSales)}
				  </Typography>
				  <Typography variant="body2" className={styles.aggregatedDataItem}>
					<span className="boldLabel">On Time:</span> {aggregateOntime.toFixed(2)}
				  </Typography>
				  <Typography variant="body2" className={styles.aggregatedDataItem}>
					<span className="boldLabel">Tickets:</span> {aggregateTickets.toFixed(0)}
				  </Typography>
				</Box>
              </Box>
				{missingDateRanges.length > 0 && (
				  <Tooltip title={`Sales data is missing for the following dates: ${missingDateRanges.join(', ')}`} arrow>
					<ReportProblemIcon sx={{ color: 'error.main' }} />
				  </Tooltip>
				)}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
		 <TableContainer component={Paper} className={styles.scrollableTableContainer}>
		  <Table sx={{ minWidth: 650 }} aria-label="sales data table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Net Sales</TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Tickets</TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Average Time ALL</TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Average Time STALL</TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Average Time DT</TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Average Time OA</TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Average Reply Time</TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>On Time</TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Labor</TableCell>
                    <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Over/Short</TableCell>
                    {/* ... other headers ... */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
					<TableCell align="right">
					  {storeData.aggregatedData && !isNaN(storeData.aggregatedData.totalNetsales)
						? `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(storeData.aggregatedData.totalNetsales)}`
						: 'N/A'}
					</TableCell>
                    <TableCell align="right">
                      {storeData.aggregatedData && !isNaN(storeData.aggregatedData.totalTickets)
                      ? (+storeData.aggregatedData.totalTickets).toFixed(2) : 'N/A'}
                    </TableCell>
					<TableCell align="center">
					  {storeData.aggregatedData ? formatSecondsToMinSec(storeData.aggregatedData.weightedAve_timeALL) : 'N/A'}
					</TableCell>
					<TableCell align="right">
					  {storeData.aggregatedData ? formatSecondsToMinSec(storeData.aggregatedData.weightedAve_timeSTALL) : 'N/A'}
					</TableCell>
					<TableCell align="right">
					  {storeData.aggregatedData ? formatSecondsToMinSec(storeData.aggregatedData.weightedAve_timeDT) : 'N/A'}
					</TableCell>
					<TableCell align="right">
					  {storeData.aggregatedData ? formatSecondsToMinSec(storeData.aggregatedData.weightedAve_timeOA) : 'N/A'}
					</TableCell>
					<TableCell align="right">
					  {storeData.aggregatedData ? formatSecondsToMinSec(storeData.aggregatedData.weightedAve_timeReplyTime) : 'N/A'}
					</TableCell>
					<TableCell align="right">
					  {aggregateOntime.toFixed(2)}
					</TableCell>
					<TableCell align="right">
					  {storeData.aggregatedData &&
					  !isNaN(storeData.aggregatedData.totalLabor) &&
					  !isNaN(storeData.aggregatedData.totalNetsales) &&
					  +storeData.aggregatedData.totalNetsales !== 0
						? (
						  (storeData.aggregatedData.totalLabor / storeData.aggregatedData.totalNetsales * 100).toFixed(2)
						) : 'N/A'}
					</TableCell>
                    <TableCell align="right">
                      {storeData.aggregatedData && !isNaN(storeData.aggregatedData.totalOvershort)
                      ? (+storeData.aggregatedData.totalOvershort).toFixed(2) : 'N/A'}
                    </TableCell>
                    {/* ... other data cells ... */}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
			<Divider className={styles.sectionDivider} />
				{expandedStores.has(storeId) && storeData.dailyData && (
				  <TableContainer component={Paper} className={styles.scrollableTableContainer}>
					<Table aria-label="daily sales data table">
					  <TableHead>
						<TableRow>
						  <TableCell> </TableCell>
						{storeData.dailyData.map(dayData => (
						  <TableCell key={dayData.date} align="right" style={{ fontWeight: 'bold' }}>
							{new Date(dayData.date).toISOString().split('T')[0]}
						  </TableCell>
						))}
						</TableRow>
					  </TableHead>
					  <TableBody>
						<TableRow>
						  <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Net Sales</TableCell>
						  {storeData.dailyData.map(dayData => (
							<TableCell key={dayData.date} align="right">{dayData.netsales}</TableCell>
						  ))}
						</TableRow>
						<TableRow>
						  <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Average Time(ALL)</TableCell>
						  {storeData.dailyData.map(dayData => (
							<TableCell key={dayData.date} align="right">{formatSecondsToMinSec(dayData.ave_timeALL)}</TableCell>
						  ))}
						</TableRow>
						{/* ... additional rows for other metrics ... */}
						<TableRow>
						  <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>On Time (%)</TableCell>
						  {storeData.dailyData.map(dayData => (
							<TableCell key={dayData.date} align="right">
							{
							  !isNaN(dayData.ontime) && !isNaN(dayData.tickets) && +dayData.tickets !== 0 
								? ((dayData.ontime / dayData.tickets) * 100).toFixed(2)
									: 'N/A'
							}</TableCell>
						  ))}
						</TableRow>
						<TableRow>
						  <TableCell style={{ fontWeight: 'bold', textAlign: 'right' }}>Labor (%)</TableCell>
						  {storeData.dailyData.map(dayData => (
							<TableCell key={dayData.date} align="right">
							  {
								!isNaN(dayData.labor) && !isNaN(dayData.netsales) && +dayData.netsales !== 0
								  ? ((dayData.labor / dayData.netsales) * 100).toFixed(2)
								  : 'N/A'
							  }
							</TableCell>
						  ))}
						</TableRow>
						{/* ... additional rows for other metrics ... */}
					  </TableBody>
					</Table>
				  </TableContainer>
				)}
          </AccordionDetails>
        </Accordion>
      );
    }) : (
      <Typography>No supervised stores found.</Typography>
    )}
  </Box>
);
};

export default SalesDataChecker;