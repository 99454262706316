import React from 'react';
import { Box, Typography, Container, Grid, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {
  return (
    <Box component="footer" sx={{ bgcolor: 'primary.main', py: 6, color: 'white' }}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Quick Links */}
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', padding: 0, margin: 0 }}>
              <li><Link href="/about" color="inherit">About Us</Link></li>
              <li><Link href="/services" color="inherit">Services</Link></li>
              <li><Link href="/blog" color="inherit">Blog</Link></li>
            </Box>
          </Grid>

          {/* Social Media */}
          <Grid item xs={6} sm={3}>
            <Typography variant="h6" gutterBottom>
              Connect With Us
            </Typography>
            <Box>
              <IconButton aria-label="Facebook" color="inherit" href="https://www.facebook.com/prolifitech">
                <FacebookIcon />
              </IconButton>
              <IconButton aria-label="Twitter" color="inherit" href="https://twitter.com/prolifitech">
                <TwitterIcon />
              </IconButton>
              <IconButton aria-label="Instagram" color="inherit" href="https://www.instagram.com/prolifitech">
                <InstagramIcon />
              </IconButton>
              <IconButton aria-label="LinkedIn" color="inherit" href="https://www.linkedin.com/company/prolifitech">
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Grid>

          {/* About the Company */}
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" align="left" gutterBottom>
              ProlifiTech Incorporated
            </Typography>
            <Typography
              variant="subtitle1"
              align="left"
              color="inherit"
              component="p"
            >
              Making digital transformation accessible and affordable for businesses of all sizes.
            </Typography>
          </Grid>
        </Grid>

        <Typography variant="body2" color="inherit" align="center" sx={{ mt: 4 }}>
          {'© '}
          <Link color="inherit" href="https://prolifi.tech/">
            Prolifi.Tech
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;