//Login.js
import React, { useState } from 'react';
import logo from './images/ptechlogo.png';
import styles from './Login.module.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function Login({ onLoginSuccess }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

const getBaseUrl = () => {
  // Check if the hostname includes the development server IP
  if (window.location.hostname.includes('72.167.34.236')) {
    return 'http://72.167.34.236:5000'; // Development server
  }
  // Use the production server URL for both 'prolifi.app' and 'www.prolifi.app'
  if (window.location.hostname.includes('prolifi.app')) {
    return 'https://prolifi.app'; // Production server
  }
  // Default value or error handling if the hostname is neither
  // You might want to handle this scenario depending on your setup
  console.error('Unknown hostname:', window.location.hostname);
  return null; // or a default URL, or throw an error
};

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    try {
      const baseUrl = getBaseUrl();
      const response = await axios.post(
        `${baseUrl}/api/auth/login`,
        { username, password },
        { withCredentials: true }
      );

      if (response.data.firstLogin) {
        Swal.fire({
          title: 'Complete Your Profile',
          html:
            '<input id="swal-input1" type="password" class="swal2-input" placeholder="Enter your new password">' +
            '<input id="swal-input2" type="password" class="swal2-input" placeholder="Confirm new password">' +
            '<input id="swal-input3" type="email" class="swal2-input" placeholder="Enter your email address">',
          showCancelButton: true,
          confirmButtonText: 'Submit',
          focusConfirm: false,
          preConfirm: () => {
            const password1 = document.getElementById('swal-input1').value;
            const password2 = document.getElementById('swal-input2').value;
            const email = document.getElementById('swal-input3').value;

            if (!password1 || !password2 || !email) {
              Swal.showValidationMessage('All fields are required');
            } else if (password1 !== password2) {
              Swal.showValidationMessage('Passwords do not match');
            } else {
              return { newPassword: password1, email: email };
            }
          }
        }).then((result) => {
          if (result.isConfirmed) {
            handleChangePassword(result.value.newPassword, result.value.email).then(() => {
              onLoginSuccess();
              navigate('/');
            }).catch(error => {
              Swal.showValidationMessage(`Profile update failed: ${error.message}`);
            });
          }
        });
      } else {
		sessionStorage.setItem('isAuthenticated', 'true');
        sessionStorage.setItem('username', username);
        onLoginSuccess();
        navigate('/');
      }
  } catch (error) {
    console.error('Login error:', error);

    // More detailed error message based on different scenarios
    let errorDetail = 'Login failed: ';

    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Data:', error.response.data);
      console.error('Status:', error.response.status);
      console.error('Headers:', error.response.headers);

      errorDetail += `Server responded with status code ${error.response.status}. `;

      if (error.response.data && error.response.data.message) {
        errorDetail += `Message: ${error.response.data.message}`;
      } else {
        errorDetail += 'No additional error message available.';
      }

    } else if (error.request) {
      // The request was made but no response was received
      console.error('Error request:', error.request);
      errorDetail += 'No response was received from the server.';

    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message:', error.message);
      errorDetail += `Error setting up the request: ${error.message}`;
    }

    // Optionally, include additional info if available
    if (error.config) {
      console.error('Error config:', error.config);
      // Add additional details from error.config if necessary
    }

    setErrorMessage(errorDetail);
  }
};

  const handleChangePassword = async (newPassword, email) => {
    const baseUrl = getBaseUrl();
    try {
      await axios.post(`${baseUrl}/api/auth/change-profile`, {
        username,
        newPassword,
        email
      }, { withCredentials: true });
    } catch (error) {
      throw new Error('Failed to update profile');
    }
  };

const handleForgotPassword = (e) => {
  e.preventDefault(); // Prevent the default form submission
  Swal.fire({
      title: 'Reset Your Password',
      input: 'email',
      inputPlaceholder: 'Enter your email address',
      inputAttributes: {
        autocapitalize: 'off',
        autocorrect: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Reset Password',
      showLoaderOnConfirm: true,
      preConfirm: (email) => {
        return axios.post(`${getBaseUrl()}/api/auth/forgot-password`, { email }, { withCredentials: true })
          .then(response => response.data)
          .catch(error => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Email Sent!',
          html: `A password reset link has been sent to your e-mail. It will be valid for 5 minutes.`,
          icon: 'success',
        });
      }
    });
  };

return (
  <div className={styles.container}>
    <form onSubmit={handleLogin} className={styles.form}>
      <img src={logo} alt="Logo" className={styles.logo} />
      <input 
        type="text" 
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
        className={styles.input}
      />
      <input 
        type="password" 
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        className={styles.input}
      />
      <button type="submit" className={styles.button}>Login</button>
      <div className={styles.forgotPasswordContainer}>
        <button onClick={handleForgotPassword} className={styles.forgotPasswordButton}>
          Forgot Password?
        </button>
      </div>
    </form>
    {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
  </div>
);

}

export default Login;
