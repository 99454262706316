//FraudPrevention.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './FraudPrevention.module.css';
import LoadingIndicator from '../LoadingIndicator';
import * as XLSX from 'xlsx';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import the CSS

function FraudPrevention() {
    const [files, setFiles] = useState({ voidsFile: null, discountsFile: null, oaVoidsFile: null });
    const [isLoading, setIsLoading] = useState(false);

const [fileStatus, setFileStatus] = useState({
    voidsFile: 'pending', // 'pending', 'success', or 'error'
    discountsFile: 'pending',
    oaVoidsFile: 'pending',
});


useEffect(() => {
    console.log(files); // Check if files are correctly set
}, [files]);


const handleFileChange = (e) => {
    const files = e.target.files;
    const readers = [];

    // Create a reader for each file
    Array.from(files).forEach((file) => {
        readers.push(readFile(file));
    });

    // Wait for all files to be read
    Promise.all(readers).then((results) => {
        const processedFiles = categorizeFiles(results);
        // 'processedFiles' will be an object with properties for each file type
        setFiles(processedFiles);
    });
};

const readFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            resolve({ file, workbook });
        };
        reader.onerror = reject;
        reader.readAsBinaryString(file);
    });
};

const categorizeFiles = (filesData) => {
    const fileTypes = { voidsFile: null, discountsFile: null, oaVoidsFile: null };
	let tempFileStatus = { voidsFile: 'error', discountsFile: 'error', oaVoidsFile: 'error' };
	
    filesData.forEach(({ file, workbook }) => {
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Check cell A3 for 'voidsFile' and 'discountsFile'
        const cellValueA3 = worksheet['A3'] ? worksheet['A3'].v : null;
        // Check cell A1 specifically for 'oaVoidsFile'
        const cellValueA1 = worksheet['A1'] ? worksheet['A1'].v : null;

        if (cellValueA3 === 'Net Sales and Voids') {
            fileTypes.voidsFile = file;
			tempFileStatus.voidsFile = 'success';
        } else if (cellValueA3 === 'Manual Discounts') {
            fileTypes.discountsFile = file;
			tempFileStatus.discountsFile = 'success';
        } else if (cellValueA1 === 'Employee Control Report') {
            fileTypes.oaVoidsFile = file;
			tempFileStatus.oaVoidsFile = 'success';
        }
    });

	setFileStatus(tempFileStatus);
    return fileTypes;
};



    // Function to dynamically determine the base URL
    const getBaseUrl = () => {
        if (process.env.NODE_ENV === 'development') {
            return 'http://72.167.34.236:5000';
        }
        return 'https://prolifi.app';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const formData = new FormData();
			formData.append('voidsFile', files.voidsFile);
			formData.append('discountsFile', files.discountsFile);
			formData.append('oaVoidsFile', files.oaVoidsFile);


        try {
        const baseUrl = getBaseUrl();
		const response = await axios.post(`${baseUrl}/api/fraud-prevention`, formData, {
			responseType: 'blob', // Important for handling the binary data
			withCredentials: true, // Include credentials in the request
		});


        // Generate the filename based on the current date
        const date = new Date();
        const formattedDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
        const filename = `FPR_${formattedDate}.xlsx`;

        // Create a URL for the file
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', filename); // Use the dynamically generated filename
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
		
		        // Reset files and fileStatus after successful submission
        setFiles({ voidsFile: null, discountsFile: null, oaVoidsFile: null });
        setFileStatus({ voidsFile: 'pending', discountsFile: 'pending', oaVoidsFile: 'pending' });
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Error data:', error.response.data);
                console.error('Error status:', error.response.status);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('No response received:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error:', error.message);
            }
        } finally {
            setIsLoading(false);
        }
    };

return (
    <div className={styles.container}>
        {isLoading ? <LoadingIndicator /> : (
            <form onSubmit={handleSubmit} className={styles.form}>
                <label className={styles.label}>Upload Files:
                    <input 
                        type="file" 
                        onChange={handleFileChange} 
                        accept=".xlsx, .xls" 
                        className={styles.input} 
                        multiple
                    />
                </label>
                <div className={styles.fileStatus}>
                    <i className={`${styles.statusIcon} ${fileStatus.voidsFile === 'success' ? 'fas fa-check-circle ' + styles.successIcon : 'fas fa-times-circle ' + styles.errorIcon}`} title="Voids File"></i>
                    <i className={`${styles.statusIcon} ${fileStatus.discountsFile === 'success' ? 'fas fa-check-circle ' + styles.successIcon : 'fas fa-times-circle ' + styles.errorIcon}`} title="Discounts File"></i>
                    <i className={`${styles.statusIcon} ${fileStatus.oaVoidsFile === 'success' ? 'fas fa-check-circle ' + styles.successIcon : 'fas fa-times-circle ' + styles.errorIcon}`} title="OA Voids File"></i>
                </div>
				<button 
					type="submit" 
					disabled={
						isLoading || 
						fileStatus.voidsFile !== 'success' || 
						fileStatus.discountsFile !== 'success' || 
						fileStatus.oaVoidsFile !== 'success'
					} 
					className={styles.submitButton}>
					Process Fraud Prevention Files
				</button>

            </form>
        )}
    </div>
);


}

export default FraudPrevention;
