// src/MainContent.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PrFileUpload from './prFileUpload';
import SScheduleAnalyzer from './SScheduleAnalyzer';
import BbUpload from './bbUpload';
import PdfReview from './PdfReview';
import UserCreationForm from './UserCreationForm';
import styles from './MainContent.module.css';
import WManual from './W-Manual';
import ResetPassword from './ResetPassword';
import WeeklySalesReport from './WeeklySalesReport';
import PopsReport from './PopsReport';
import ExcelProcessor from './EEDataUpload';
import TZFileUpload from './TZFileUpload';
import FraudPrevention from './FraudPrevention';
import MonthlyProcessing from './Monthly/MonthlyProcessing';
import MonthlyManual from './Monthly/MonthlyManual';
import GradingStandardsEditor from './GradingStandardsEditor';
import StickerComponent from './StickerComponent';
import ForecastDisplay from './ForecastDisplay';
import StoreUserManagement from './StoreUserManagement';
import VisionDental from './VisionDental'; // Import the new component
import SessionChecker from './SessionChecker'; // Import the SessionChecker component
import PLReview from './PLReview'; // Import the new P&L Review component
import ExcelUploader from './ExcelUploader'; 
import EmployeeAnalyzer from './EmployeeAnalyzer';
import EmployeeDataViewer from './EeAffinity.js';
import CSVConverter from './TruckConverter';

function MainContent() {
  return (
    <div className={styles.mainContent}>
      <Routes>
        <Route path="/" element={<div></div>} /> {/* Default route */}
        <Route path="/prFileUpload" element={<PrFileUpload />} />
        <Route path="/sScheduleAnalyzer" element={<SScheduleAnalyzer />} />
        <Route path="/bbUpload" element={<BbUpload />} />
        <Route path="/pdfReview" element={<PdfReview />} />
        <Route path="/create-user" element={<UserCreationForm />} />
        <Route path="/w-manual" element={<WManual />} />
        <Route path="/weekly-sales-report" element={<WeeklySalesReport />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/pops-report" element={<PopsReport />} />
        <Route path="/ee-data-upload" element={<ExcelProcessor />} />
        <Route path="/tz-file-upload" element={<TZFileUpload />} />
        <Route path="/fraud-prevention" element={<FraudPrevention />} />
        <Route path="/monthly-processing" element={<MonthlyProcessing />} />
        <Route path="/monthly-manual" element={<MonthlyManual />} />
        <Route path="/grading-standards" element={<GradingStandardsEditor />} />
        <Route path="/sticker-component" element={<StickerComponent />} />
        <Route path="/forecast-display" element={<ForecastDisplay />} />
        <Route path="/store-user-management" element={<StoreUserManagement />} />
        <Route path="/vision-dental" element={<VisionDental />} /> {/* Add this line */}
        <Route path="/session-checker" element={<SessionChecker />} /> {/* Add this line */}
        <Route path="/pl-review" element={<PLReview />} /> {/* Add this line */}
		<Route path="/shift-analyze" element={<ExcelUploader />} />
		<Route path="employee-analyzer" element={<EmployeeAnalyzer />} />
		<Route path="employee-data-viewer" element={<EmployeeDataViewer />} />
		<Route path="/csv-converter" element={<CSVConverter />} />
      </Routes>
    </div>
  );
}

export default MainContent;
